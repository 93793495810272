import styled from 'styled-components';

interface ImageProps {
  fullWidth: boolean;
}

interface ItemColumnProps {
  hasImage: boolean;
}

export const Container = styled.div`
  padding: 64px 32px;
  max-width: 1120px;
  margin: auto;
  flex-direction: column;
  display: flex;
  width: auto;
  @media (min-width: 1240px) {
    flex-direction: column;
  }
`;

export const Title = styled.h2`
  color: #002b3a;
  padding: 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.03em;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 48px;
  @media (min-width: 1240px) {
    font-size: 36px;
    text-align: left;
  }
`;

export const ItemColumn = styled.div`
  display: flex;
  flex-direction: column;
  @media screen and (min-width: 1240px) {
    display: ${({ hasImage }: ItemColumnProps) => (hasImage ? 'flex' : 'grid')};
    grid-template-columns: 1fr 1fr;
    gap: 16px;
    flex-direction: column;
  }
`;

export const ListIcon = styled.img`
  padding-right: 16px;
  height: 20px;
`;

export const ListItem = styled.div`
  display: flex;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0.012em;
  padding-bottom: 16px;
  color: rgba(112, 112, 112, 1);
  @media (min-width: 1240px) {
    font-size: 20px;
    padding-bottom: 0px;
  }
`;

export const BenefitImage = styled.img`
  max-width: 272px;
  align-self: center;
  padding: ${({ fullWidth }: ImageProps) => (fullWidth ? '20px 0px 40px' : '0')};
  max-width: ${({ fullWidth }: ImageProps) => (fullWidth ? 'calc(100% + 52px)' : 'auto')};
  @media (min-width: 1240px) {
    max-width: 360px;
    padding-left: 64px;
    padding: ${({ fullWidth }: ImageProps) => (fullWidth ? '20px 24px 40px' : '0')};
    max-width: ${({ fullWidth }: ImageProps) => (fullWidth ? '360px' : 'auto')};
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 1240px) {
    display: flex;
    flex-direction: row;
  }
`;

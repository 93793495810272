import classNames from 'classnames';
import { useRouter } from 'next/router';
import SbEditable from 'storyblok-react';
import { brandColor } from '../../config';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import DynamicComponent from '../DynamicComponent';
import { Section } from './Confirmation.styled';
import { StepBlok } from './Step';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface ConfirmationProps {
  blok: BaseBlok & {
    login_title: string;
    login_header: string;
    login_subtitle: string;
    login_subheader: string;
    login_header_photo: Asset;
    registration_steps: StepBlok[];
    registration_title: string;
    registration_header: string;
    registration_subtitle: string;
    registration_subheader: string;
    login_header_text_color: string;
    render_login_cta_button: boolean;
    registration_header_photo: Asset;
    registration_header_photo_sm: Asset;
    registration_header_text_color: string;
  };
}

function HeaderImage(props: ConfirmationProps) {
  const { registration_header_photo: _default, registration_header_photo_sm: sm } = props.blok;

  return (
    <>
      {_default?.filename && (
        <img
          className={classNames({ 'hidden sm:block': !!sm?.filename })}
          alt={_default.alt || ''}
          src={_default.filename}
        />
      )}

      {!!sm?.filename && <img className="sm:hidden" alt={sm.alt || ''} src={sm.filename} />}
    </>
  );
}

export default function Confirmation(props: ConfirmationProps) {
  const registrationHeaderTextColor = props.blok.registration_header_text_color
    ? props.blok.registration_header_text_color
    : brandColor;
  const router = useRouter();

  const { registration_header_photo, registration_header_photo_sm } = props.blok;

  const action = router.query?.['action'] || 'registration';
  const steps: any[] = action ? (props.blok as any)[`${action}_steps`] : [];

  return (
    <SbEditable content={props.blok}>
      <Section {...generateQaAttrFromBlok(props.blok)}>
        {(registration_header_photo?.filename || registration_header_photo_sm?.filename) && (
          <div className="header-container" style={{ color: registrationHeaderTextColor }}>
            <header>
              <h2>{props.blok.registration_header}</h2>
              <span>{props.blok.registration_subheader}</span>
            </header>

            <HeaderImage {...props} />
          </div>
        )}
        <h3>{props.blok.registration_title}</h3>

        <p>{props.blok.registration_subtitle}</p>

        {steps && steps.length > 0 && (
          <div>
            <ol>
              {steps.map((blok, index) =>
                DynamicComponent({
                  key: blok._uid,
                  blok: blok,
                  index,
                }),
              )}
            </ol>
          </div>
        )}
      </Section>
    </SbEditable>
  );
}

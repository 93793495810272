import React from 'react';
import SbEditable from 'storyblok-react';
import { BaseBlok } from '../../../../lib/storyblok.interfaces';
import DynamicComponent from '../../../DynamicComponent';
import { SitemapLink } from '../sitemap_link';
import { StyledSiteMap } from './Sitemap.styled';
import { generateQaAttrFromBlok } from '../../../../src/helpers/qa-attribute';

export interface Sitemap extends BaseBlok {
  header: string;
  links: SitemapLink[];
}

const Sitemap = (props: { blok: Sitemap }) => {
  return (
    <SbEditable content={props.blok}>
      <StyledSiteMap {...generateQaAttrFromBlok(props.blok)}>
        <h2>{props.blok.header}</h2>
        {props.blok.links &&
          props.blok.links.map((blok) =>
            DynamicComponent({
              key: blok._uid,
              blok: blok,
            }),
          )}
      </StyledSiteMap>
    </SbEditable>
  );
};

export default Sitemap;

import { useLazyQuery } from '@apollo/client';
import { Sync } from '@material-ui/icons';
import { Formik } from 'formik';
import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import SbEditable from 'storyblok-react';
import { useConfig, useErrorPage } from '../../lib/hooks';
import { PromoCodeDetails } from '../../lib/interfaces';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import { validatePromoCode } from '../../src/apollo/queries/promotionCodes';
import { Button, Container, HelperText, Input, StyledForm } from './PromoCodeEntryExtend.styled';
import validationSchema from './validation';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import switchLambdaService from '../../src/helpers/switchLambdaService';

interface Error {
  code?: string;
  message?: string;
}

interface PromocodeRoute extends BaseBlok {
  promocode_route: string;
  promocode_prefix: string;
}

export interface PromoCodeEntryExtend extends BaseBlok {
  breakpoint: string;
  button_text: string;
  helper_text: string;
  promocode_routes: PromocodeRoute[];
  button_text_color: string;
  input_border_color: string;
  input_placeholder_text: string;
  button_background_color: string;
  default_promocode_route: string;
}

interface PromoCodeEntryExtendProps {
  blok: PromoCodeEntryExtend;
}

export function getFailedCodeValidationMessage(err: Error): string | undefined {
  const includes = (search: string) => _.get(err, 'message', '').includes(search);

  if (includes('code has expired')) return 'This promo code has expired'; 
  else if (includes('campaign has been expired'))
    return 'We’re sorry. This promotional campaign is no longer available. Please notify your administrator for further instructions.';
  else if (includes('combination was already redeemed')) return 'This promo code has already been used';
  else if (includes('combination is not found')) return 'This promo code does not exist';
  else if (includes('has been redeemed to capacity'))
    return 'We’re sorry. This promotion has reached its limit. Please notify your administrator for further instructions.';
}

const extractError = (err: Error) => [_.get(err, 'code', 'Unknown'), _.get(err, 'message', 'Unknown')];

function canValidatePromoCode(promotionCode: string, route: PromocodeRoute) {
  const prefix = promotionCode.toUpperCase().substring(0, 2);
  return prefix === route.promocode_prefix.toUpperCase() && !_.isEmpty(route.promocode_route);
}

export default function PromoCodeEntryExtend(props: PromoCodeEntryExtendProps) {
  const config = useConfig();
  const router = useRouter();
  const errorPage = useErrorPage();

  function viewPromoCodeRoute(route: string, { promotionCode, promotionType }: PromoCodeDetails) {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    router.push({
      pathname: `/${route}`,
      query: { promotionCode, promotionType },
    });
  }

  const breakpoint = props.blok?.breakpoint;
  const buttonBackgroundColor = props.blok?.button_background_color;
  const buttonColor = props.blok?.button_text_color;

  const initialValues = { promotionCode: '' };
  const [error, setError] = useState<string>();

  const resetError = () => error && setError(undefined);

  const [onValidatePromoCode, { loading }] = useLazyQuery(validatePromoCode, {
    onCompleted(res) {
      if (!_.get(res, 'validatePromoCode.success')) {
        errorPage('Unknown', 'Error validating promo code, please try again.');
        return;
      }

      let routeFound = false;

      if (props.blok.promocode_routes) {
        props.blok.promocode_routes.forEach((route) => {
          if (canValidatePromoCode(_.get(res, 'validatePromoCode.promotionCode', ''), route)) {
            routeFound = true;
            viewPromoCodeRoute(route.promocode_route, { ...res.validatePromoCode });
          }
        });
      }

      if (props.blok.default_promocode_route && !routeFound) {
        viewPromoCodeRoute(props.blok.default_promocode_route, { ...res.validatePromoCode });
      } else if (!routeFound) {
        errorPage('Unknown', 'Error validating promo code, please try again.');
      }
    },

    onError(err) {
      const message = getFailedCodeValidationMessage(err);
      if (message) setError(message);
      else errorPage(...extractError(err));
    },
  });

  const placeholder = props.blok?.input_placeholder_text || 'Enter Code Here';

  return (
    <SbEditable content={props.blok}>
      <Container {...generateQaAttrFromBlok(props.blok)}>
        {props.blok.helper_text && <HelperText breakpoint={breakpoint}>{props.blok.helper_text}</HelperText>}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={({ promotionCode }) => {
            resetError();
            onValidatePromoCode({
              variables: {
                codeInput: {
                  promotionCode,
                  promotionRef: config.promotion.ref,
                  DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
                },
              },
            });
          }}
        >
          {({ isValid }) => (
            <StyledForm breakpoint={breakpoint} onChange={() => resetError()}>
              <Input type="text" name="promotionCode" placeholder={placeholder} error={error} breakpoint={breakpoint} />
              <Button
                type="submit"
                className={loading ? 'loading' : ''}
                disabled={!isValid || loading}
                breakpoint={breakpoint}
                backgroundColor={buttonBackgroundColor}
                color={buttonColor}
              >
                <span className="text">{props.blok.button_text}</span>
                {loading && <Sync className="spinner" />}
              </Button>
            </StyledForm>
          )}
        </Formik>
      </Container>
    </SbEditable>
  );
}

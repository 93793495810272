import classNames from 'classnames';
import { FC } from 'react';

interface Props {
  className?: string;
  name: string;
  required?: boolean;
  label: string;
  error?: any;
  handleChange: any;
  value: string | number;
  options: { label: string; value: string }[] | undefined;
  touched?: boolean;
  handleBlur?: any;
}

const TwSelect: FC<Props> = ({
  className,
  error,
  name,
  label,
  required,
  value,
  handleChange,
  options,
  handleBlur,
  touched,
}) => {
  return (
    <div
      className={classNames(
        className,
        'relative isolate cursor-pointer rounded-full border bg-white py-2 px-6 shadow-sm focus-within:ring-1',
        {
          'border-ev-dark-blue focus-within:border-slate-400 focus-within:ring-slate-400 hover:border-slate-400':
            !error,
        },
        {
          'border-red-400 ring-1 ring-red-400': error && touched,
        },
      )}
    >
      <label
        htmlFor={name}
        className={classNames(
          'absolute left-6 z-0 cursor-pointer font-medium transition-all peer-autofill:top-2 peer-autofill:z-20 peer-focus:top-2 peer-focus:text-xs',
          { 'top-4 text-base text-slate-500': !value },
          { 'top-2 text-xs': value },
        )}
      >
        {label}
        {required && '*'}
      </label>
      <select
        id={name}
        name={name}
        className="peer form-select block h-10 w-full cursor-pointer border-0 bg-transparent p-0 pt-3 text-base placeholder-transparent focus-within:outline-none focus:ring-0"
        value={value}
        onChange={handleChange}
        defaultValue=""
        onBlur={handleBlur}
      >
        <option disabled value=""></option>
        {options?.map((item) => (
          <option className="relative z-10" key={item.value} value={item.value} label={item.label}>
            {item.label}
          </option>
        ))}
      </select>
      {error && touched && <p className="absolute -bottom-5 text-xs text-red-400">{error}</p>}
    </div>
  );
};

export default TwSelect;

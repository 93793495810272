import styled from 'styled-components';
import { brandColor, colors } from '../../config';

export const Divider = styled.div`
  border-bottom: 1px solid #dee5ea;
  max-width: 956px;
  margin: 0px 20px;
  padding-top: 100px;
  @media only screen and (min-width: 768px) {
    margin: auto;
  }
`;

export const Section = styled.section`
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  text-align: center;
  color: ${brandColor};

  .header-container {
    width: 100%;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
    padding-left: 24px;
    padding-right: 24px;
    header {
      width: 100%;
      max-width: 1024px;

      h2 {
        font-size: 26px;
        font-weight: 700;
        padding: 0;
        margin-top: 0;
        margin-bottom: 30px;
      }

      h3 {
        margin: 20px 0;
        font-size: 24px;
        font-weight: normal;
      }

      span {
        font-size: 16px;
        font-weight: 500;
        padding: 0;
        margin: 20px 0;
      }
    }
  }

  button,
  .button {
    height: 48px;
    margin: 2em 0;
    border-radius: 24px;
    padding: 0 24px;
    background-color: ${colors.accentYellow};
    color: ${brandColor};
    font-size: 20px;
    font-weight: 600;
    text-decoration: none;
    display: inline-block;
    padding: 12px 24px;
  }

  h3 {
    font-size: 20px;
    font-weight: 700;
    margin-top: 48px;
    letter-spacing: 0.03em;

    @media only screen and (min-width: 980px) {
      font-size: 36px;
    }
  }

  > div {
    display: flex;
    margin: 2em auto 0;
    width: 70%;

    ol {
      padding: 0px;
      ul {
        padding: 0px;
        li {
          text-align: left;
          font-size: 16px;
          font-weight: 400;
          letter-spacing: 0.012em;
          margin-bottom: 48px;

          @media only screen and (min-width: 980px) {
            font-size: 20px;
          }
        }
      }
    }

    img.step-image {
      max-width: 95%;
      max-height: 310px;
    }

    div:second-child {
      margin-right: 10%;
    }
  }

  p {
    margin: 8px 0 24px;
    width: 95%;
  }

  @media only screen and (min-width: 480px) {
    > div {
      width: auto;
      align-items: center;
    }
  }

  @media only screen and (max-width: 768px) {
    .header-container {
      header {
        h2 {
          font-size: 30px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (min-width: 769px) {
    .header-container {
      height: 500px;
      header {
        h2 {
          font-size: 48px;
        }

        span {
          font-size: 20px;
        }
      }
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    > div:not(.header-container) {
      margin: 2em auto;
      width: 100%;
      justify-content: center;

      ol {
        display: flex;
        li {
          margin: 0 24px 0;
        }
        li::marker {
          font-size: 20px;
        }
      }

      p.step-header {
        font-size: 20px;
        width: 70%;
      }
    }
  }

  @media only screen and (max-width: 640px) {
    button,
    .button {
      display: block;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
`;

import { BaseBlok } from '@/lib/storyblok.interfaces';
import SbEditable from 'storyblok-react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { renderRichText } from 'src/helpers/rich-text-render';

type Props = {
  blok: BaseBlok & {
    text: StoryblokRichtext;
  };
};

const RichText = ({ blok }: Props) => {
  const { text } = blok;
  return (
    <SbEditable content={blok}>
      <section className="container py-16 text-left sm:py-24">
        {text.content[0].content && <div className="mx-auto text-sm">{renderRichText(text)}</div>}
      </section>
    </SbEditable>
  );
};

export default RichText;

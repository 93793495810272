import { BaseBlok } from '@/lib/storyblok.interfaces';
import { FC } from 'react';
import { renderRichText } from 'src/helpers/rich-text-render';
import SbEditable from 'storyblok-react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

type Props = {
  blok: BaseBlok & {
    text: StoryblokRichtext;
  };
};

const FooterSimple: FC<Props> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <footer className="bg-[#232323] px-4 py-20">
        <div className="text-center text-[#656565]">{renderRichText(blok.text)}</div>
      </footer>
    </SbEditable>
  );
};

export default FooterSimple;

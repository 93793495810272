import { useField } from 'formik';
import _ from 'lodash';
import React from 'react';
import { StyledTextInput } from './FormikTextInput.styled';

interface FormikTextInputProps extends React.HTMLProps<HTMLInputElement> {
  error?: any;
}

export default function FormikTextInput(props: FormikTextInputProps) {
  const { label, className: inputClass, error, ...fieldProps } = props;
  const [field, meta] = useField(fieldProps as any);
  let customClass = '';

  if (inputClass) customClass = inputClass;

  return (
    <StyledTextInput className={customClass}>
      {label ? <label htmlFor={fieldProps.id || fieldProps.name}>{label}</label> : null}
      <input
        className={(meta.touched && meta.error) || !_.isEmpty(error) ? 'error' : ''}
        size={1}
        {...field}
        {...fieldProps}
      />
      <span className="error">
        {meta.touched && meta.error ? meta.error : <>&nbsp;</>}
        {error ? error : <>&nbsp;</>}
      </span>
    </StyledTextInput>
  );
}

import styled from 'styled-components';

interface ContainerProps {
  backgroundColor: string;
}

export const Section = styled.section`
  display: flex;
  width: 100%;
  margin: auto;
  flex-direction: column;
  padding: 30px 24px;
  background-color: ${({ backgroundColor }: ContainerProps) => (backgroundColor ? backgroundColor : '')};
  @media (min-width: 600px) {
    display: grid;
    grid-template-rows: 1fr;
    flex-direction: row;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  max-width: 668px;
  margin: auto;
`;

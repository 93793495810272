export default async function apiClient({
  url,
  method,
  customHeaders,
  body,
}: {
  url: string;
  method: 'POST' | 'GET';
  customHeaders?: Record<string, string>;
  body?: any;
}) {
  if (method === 'POST') {
    return await fetch(url, {
      method,
      headers: {
        'Content-Type': 'application/json',
        ...customHeaders,
      },
      body: JSON.stringify(body),
    });
  } else {
    return await fetch(url, {
      method,
      headers: customHeaders ? customHeaders : undefined,
    });
  }
}

import { BaseBlok } from '@/lib/storyblok.interfaces';
import { useRouter } from 'next/router';
import { FC } from 'react';
import { renderRichText } from 'src/helpers/rich-text-render';
import SbEditable from 'storyblok-react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

type Props = {
  blok: BaseBlok & {
    description: StoryblokRichtext;
  };
};

// This is a copy of Footnote.tsx with Qmerit logic.
// TODO eventually delete this file.
const QmeritFootnote: FC<Props> = ({ blok }) => {
  const { query } = useRouter();
  const { qmeritonly } = query;
  // Footnote should only render if qmeritonly=true url param is present.
  if (qmeritonly === 'true') {
    return (
      <SbEditable content={blok}>
        <section className="mx-auto my-10 w-full max-w-4xl px-4 text-sm">{renderRichText(blok.description)}</section>
      </SbEditable>
    );
  }
  return null;
};

export default QmeritFootnote;

import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { Sync } from '@material-ui/icons';
import { FirebaseError } from '@firebase/util';
import { Formik, FormikHelpers, FormikErrors } from 'formik';
import React, { Fragment, useCallback, useMemo, useRef, useState } from 'react';
import {
  setPersistence,
  signInWithEmailAndPassword,
  signInAnonymously,
  inMemoryPersistence,
  AuthErrorCodes,
} from 'firebase/auth';
import { useRouter } from 'next/router';
import { events, getAnalyticsClient } from '@/lib/analytics';
import { useActionPage, useConfig, usePromoCodeDetailsFromQuery } from '../../lib/hooks';
import { redeemPromotion } from '../../src/apollo/queries/promotionCodes';
import { RegisterViewExtendParams } from '../RegisterViewExtend';
import FormikCheckbox from '../shared/FormikCheckbox';
import FormikTextInput from '../shared/FormikTextInput';
import { Button, StyledForm, ForgotPasswordContainer } from './LoginFormExtend.styled';
import createValidationSchema from './validations';
import { generateQaAttr, generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import switchLambdaService from '../../src/helpers/switchLambdaService';
import { auth } from '../../lib/firebase';
import { authServiceDomain } from '../../config';
import Storyblok from '../../lib/storyblok';
import {
  createConsentConditionsValidation,
  getConsentConditionsDefaultValues,
  getOmittableConsentConditionsKeys,
  ConsentConditionCheckbox,
} from '../RegisterForm/shared';
import { linkAmazonAccount, isAmazon } from 'src/helpers/amazon';

interface LoginFormExtendProps {
  id?: string;
  className?: string;
  blok: RegisterViewExtendParams & {
    terms_conditions_copy?: string;
  };
  setView: (view: string) => void;
}

type Values = {
  email: string;
  password: string;
  subscribe: boolean;
  terms: boolean;
  subscriptionCopy: string;
};

/**
 * For this one we want to verify that the email and password are valid,
 * but we do not want to keep the user authenticated,
 * instead we revert to the previous state which is signed in anonymously.
 * For more details see: EXT-325
 * @param email
 * @param password
 */
async function verifyEmailAndPasswordWithoutPersistence(email: string, password: string) {
  await setPersistence(auth, inMemoryPersistence);
  await signInWithEmailAndPassword(auth, email, password);
  await signInAnonymously(auth);
}

const firebaseErrors: { [key: string]: FormikErrors<Values> } = {
  [AuthErrorCodes.USER_DELETED]: { email: 'This email address does not exist.' },
  [AuthErrorCodes.INVALID_PASSWORD]: { password: 'Password does not match.' },
};

export default function LoginFormExtend(props: LoginFormExtendProps) {
  const [loading, setLoading] = useState<boolean>();
  const config = useConfig();
  const promoCodeDetails = usePromoCodeDetailsFromQuery();
  const analytics = getAnalyticsClient();

  const router = useRouter();
  const [error, setError] = useState<string | React.ReactNode>();
  const actionPage = useActionPage();

  const resetError = () => {
    setError(undefined);
  };

  const onError = (message = 'Something wrong happened, please try again.', code?: string) => {
    let error: string | React.ReactNode = message.includes(':') ? message : `Error: ${message}`;

    if (message.includes('request_body')) {
      error = `Error: ${message}`;
    } else if (message.includes('INVALID_LAST_FIRST_NAME')) {
      error = 'Error: Email and password not found.';
    } else if (message.includes('UNEXPECTED_ERROR')) {
      error = 'Something wrong happened, please try again.';
    } else if (message.includes('Reached maximum redemptions by account per campaign')) {
      error =
        "We’re sorry, we’re unable to redeem this code since your account has reached the maximum number (4) of promo codes that can be redeemed for this promotion.";
    } else if (message.includes('payment.method.not.found')) {
      error = (
        <span>
          Looks like your account is not yet active.{' '}
          <a href={process.env.NEXT_PUBLIC_EVGO_LOGIN_URL} target="_blank" className="action" rel="noreferrer">
            Activate your account
          </a>{' '}
          by logging in and adding a payment method.
        </span>
      );
    }

    setError(typeof error === 'object' ? error : `${error}${code ? `| Code: ${code}` : ''}`);

    setLoading(false);
  };

  const initialValues: Values = {
    email: '',
    password: '',
    subscribe: true,
    terms: false,
    subscriptionCopy: props.blok.subscriptionCopy,
    ...getConsentConditionsDefaultValues(props.blok?.consent_conditions || []),
  };

  const [applyPromotion] = useMutation(redeemPromotion, {
    onCompleted(res) {
      if (res && res.redeemPromotion && res.redeemPromotion.success) {
        if (props.blok.confirmation_route && props.blok.confirmation_route.url) {
          actionPage(props.blok.confirmation_route.url, 'login');
        } else {
          actionPage('/confirmation', 'login');
        }
      } else {
        onError(res.redeemPromotion.message, res.redeemPromotion.errorCode);
      }
    },
    onError(error) {
      onError(error.message);
    },
  });

  const formRef = useRef<any>();

  const validationSchema = useMemo(
    () => createValidationSchema(createConsentConditionsValidation(props.blok?.consent_conditions || [])),
    [props.blok.consent_conditions],
  );

  const handleSubmit = useCallback(
    (values: any) => {
      return applyPromotion({
        variables: {
          input: {
            address: null,
            city: null,
            email: values.email,
            firstName: null,
            lastName: null,
            make: null,
            model: null,
            password: values.password,
            phone: null,
            postalCode: null,
            subscribe: values.subscribe,
            state: null,
            terms: values.terms,
            year: null,
            subscriptionCopy: values.subscriptionCopy,
            promotionCode: promoCodeDetails?.promotionCode,
            promotionRef: config.promotion.ref,
            DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
          },
        },
      });
    },
    [applyPromotion, promoCodeDetails, config.promotion.ref],
  );

  const handleLogin = async (values: Values, { setErrors, setStatus }: FormikHelpers<Values>) => {
    setLoading(true);
    setError(undefined);
    setStatus('');

    const { email, password } = values;

    analytics.track(events.clickLoginButton, {
      host: window.location.host,
      pathname: window.location.pathname,
      email,
    });

    const omittedKeys = getOmittableConsentConditionsKeys(props.blok?.consent_conditions || []);

    try {
      await verifyEmailAndPasswordWithoutPersistence(email, password);
      if (isAmazon(router.query)) {
        try {
          await linkAmazonAccount({ email, password, query: router.query });
        } catch (error) {
          console.error('Failed to link Amazon account', { error });
          setError('Failed to link Amazon account. Please try again');
          setLoading(false);
          return;
        }
      }
      return handleSubmit(_.omit(values, omittedKeys));
    } catch (e) {
      const code = (e as FirebaseError)?.code;
      const isKnownError = Object.keys(firebaseErrors).includes(code);

      if (isKnownError) {
        setErrors(firebaseErrors[code]);
        setStatus(code);
      } else {
        setError('Something went wrong, please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Fragment>
      <Formik
        innerRef={formRef}
        initialValues={initialValues}
        onSubmit={handleLogin}
        validationSchema={validationSchema}
        validateOnMount={true}
        {...generateQaAttrFromBlok(props.blok)}
      >
        {(formik) => (
          <StyledForm onChange={resetError}>
            <h3>{props.blok.login_copy}</h3>
            <FormikTextInput name="email" label="Email" {...generateQaAttr('email')} />
            <ForgotPasswordContainer>
              <a href={`${authServiceDomain}/reset-password`} target="_blank" rel="noreferrer">
                Forgot Password?
              </a>
              <FormikTextInput name="password" label="Password" type="password" {...generateQaAttr('password')} />
            </ForgotPasswordContainer>
            <h6>
              Minimum 8 chars: with at least 1 number, 1 uppercase, 1 lowercase
              &&nbsp;1&nbsp;special&nbsp;character&nbsp;(!,&nbsp;@,&nbsp;#&nbsp;...)
            </h6>
            <hr />
            {props.blok.terms_conditions_copy ? (
              <FormikCheckbox name="terms" {...generateQaAttr('terms-checkbox')}>
                <div dangerouslySetInnerHTML={{ __html: props.blok.terms_conditions_copy }} />
              </FormikCheckbox>
            ) : (
              <FormikCheckbox name="terms" {...generateQaAttr('terms-checkbox')}>
                By checking this box, you acknowledge you have read and agree to be bound by our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/terms-service/" className="underline">
                  Terms of Service
                </a>{' '}
                and our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
                  Privacy Policy
                </a>
                . By submitting this form, you acknowledge you are sharing your personal information with EVgo and
                consent to EVgo’s Privacy Notice for California Residents. More detail regarding personal information we
                collect, how we use that information, how we share that information, and your rights and choices can be
                found in our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
                  Privacy Policy
                </a>
                .
              </FormikCheckbox>
            )}
            <FormikCheckbox name="subscribe" {...generateQaAttr('subscribe-checkbox')}>
              {props.blok.subscriptionCopy}
            </FormikCheckbox>

            {props.blok?.consent_conditions
              ?.filter((v) => !!v.id)
              ?.map((condition) => (
                <ConsentConditionCheckbox
                  key={condition._uid}
                  name={condition.id}
                  {...generateQaAttr(`${condition.id}-checkbox`)}
                  wrapLabel={false}
                >
                  <span
                    className="condition"
                    dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(condition.description) }}
                  />
                </ConsentConditionCheckbox>
              ))}

            <div className="submit-container">
              <Button type="submit" disabled={!!loading || !formik.isValid}>
                <span className={loading ? 'loading' : ''}>{props.blok.login_submit_button_copy}</span>
                {loading ? <Sync className="spinner" /> : null}
              </Button>

              {formik.status === AuthErrorCodes.USER_DELETED && (
                <p className="error-container">
                  This email address does not exist. Please try again, or{' '}
                  <span className="action" onClick={() => props.setView('register')}>
                    register for an EVgo account
                  </span>
                  .
                </p>
              )}

              {formik.status === AuthErrorCodes.INVALID_PASSWORD && (
                <p className="error-container">Password does not match. Please try again.</p>
              )}

              {error ? <p className="error-container">{error}</p> : <p>{props.blok.login_submit_button_subtext}</p>}
            </div>
          </StyledForm>
        )}
      </Formik>
    </Fragment>
  );
}

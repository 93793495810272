import { BaseBlok, Asset } from '@/lib/storyblok.interfaces';
import classNames from 'classnames';
import { FC, useState } from 'react';
import SbEditable from 'storyblok-react';
import LoginForm from './LoginForm';
import RegisterForm from './RegisterForm';

interface Props {
  blok: BaseBlok & {
    hero_img: Asset;
    hero_title: string;
  };
}

const SubaruRegisterForm: FC<Props> = ({ blok }) => {
  const [isLoginForm, setIsLoginForm] = useState(false);
  const { hero_title, hero_img } = blok;

  return (
    <SbEditable content={blok}>
      {/* hero start */}
      <div className="relative flex items-center justify-center px-10 py-20 lg:min-h-[500px]">
        <img className="absolute inset-0 h-full w-full object-cover" src={hero_img?.filename} alt={hero_img?.alt} />
        <h1 className="relative text-center text-3xl font-bold text-white lg:text-5xl">{hero_title}</h1>
      </div>
      <div className="relative mx-auto mb-10 w-full bg-[#F9FAFB] p-10 text-center shadow-lg lg:-mt-10 lg:max-w-5xl lg:rounded-lg">
        <div className="mx-auto grid w-full max-w-xs grid-cols-2 justify-between rounded-full bg-white shadow-lg">
          <button
            onClick={() => setIsLoginForm(false)}
            className={classNames(
              'rounded-full px-4 py-2',
              { '': isLoginForm },
              { 'bg-[#20609F] text-white': !isLoginForm },
            )}
          >
            Sign Up
          </button>
          <button
            onClick={() => setIsLoginForm(true)}
            className={classNames('rounded-full px-4 py-2', { 'bg-[#20609F] text-white': isLoginForm })}
          >
            Log In
          </button>
        </div>
        <h2 className="mt-10 text-2xl font-bold capitalize lg:text-3xl">
          {isLoginForm ? 'Log In' : 'Sign up'} to activate your charging credit
        </h2>
        {isLoginForm ? <LoginForm /> : <RegisterForm />}
      </div>
    </SbEditable>
  );
};

export default SubaruRegisterForm;

import classNames from 'classnames';
import React, { FC } from 'react';

const CardLoader: FC = () => {
  return (
    <div
      className={classNames(
        'mx-auto flex w-3/4 max-w-md shrink-0 snap-center flex-col items-center rounded-lg border border-slate-200 bg-white pt-8 text-center text-slate-200 shadow-lg sm:w-[25rem] lg:col-span-3 lg:w-full lg:border-0 lg:shadow-none',
      )}
    >
      <div className="m-0 h-8 w-1/3 rounded-lg bg-slate-200 text-base font-bold lg:text-3xl" />

      <hr className="mt-4" />
      <div
        className={classNames(
          'm-0 h-14 w-1/2 rounded-lg bg-slate-200 text-5xl font-bold text-slate-200 lg:text-3xl xl:text-6xl',
        )}
      />
      <div className="m-0 mt-2 h-4 w-8 rounded-lg bg-slate-200 text-xs italic text-slate-200" />
      <div className="mt-6 self-stretch">
        <div className="m-0 flex flex-col gap-4 p-0">
          <div className="mx-auto h-4 w-2/3 rounded-lg bg-slate-200" />
          <div className="mx-auto h-4 w-2/3 rounded-lg bg-slate-200" />
          <div className="mx-auto h-4 w-2/3 rounded-lg bg-slate-200" />
        </div>
      </div>
    </div>
  );
};

export default CardLoader;

import React from 'react';
import styled from 'styled-components';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import { brandColor, colors } from '../../config';

type StyledProps = {
  isSquare: boolean | undefined;
};

type ButtonBlok = {
  button_square?: boolean;
} & BaseBlok;

type Props = {
  children: React.ReactNode;
  blok?: ButtonBlok;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  className?: string;
  disabled?: boolean;
};

const StyledButton = styled.button<StyledProps>`
  cursor: pointer;
  display: flex;
  align-items: center;
  align-content: center;
  width: 272px;
  height: 32px;
  margin-top: 16px;
  padding: 0 24px;
  background-color: ${colors.accentYellow};
  color: ${brandColor};
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  text-decoration: none;
  border: 0;
  border-radius: ${(props) => (props.isSquare ? '0' : '24px')};

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }
`;

export default function Button({ children, blok, onClick, className, disabled }: Props) {
  return (
    <StyledButton onClick={onClick} className={className} isSquare={blok && blok.button_square} disabled={disabled}>
      {children}
    </StyledButton>
  );
}

import React, { forwardRef, MutableRefObject, useEffect, useState } from 'react';
import _ from 'lodash';
import { useField, useFormikContext } from 'formik';
import states from '../../../config/states.json';
import { StyledDropdown } from './FormikDropdown.styled';

interface DropdownOptions {
  name: string | number;
  value: string | number;
}

interface FormikDropdownProps {
  className?: string;
  label?: string;
  disabled?: boolean | string;
  id?: string;
  name?: string;
  options?: DropdownOptions[];
  onChange?: any;
  value?: any;
}

export default forwardRef<HTMLInputElement, FormikDropdownProps>(function FormikDropdown(props, ref) {
  const { label, className: inputClass, onChange, ...fieldProps } = props;
  const { setErrors, setTouched, setFieldValue } = useFormikContext();
  const [open, setOpen] = useState(false);
  const [field, meta] = useField(fieldProps as any);

  const handleClickOutside = (e: any) => {
    const _ref = ref as MutableRefObject<HTMLInputElement>;
    if (_ref?.current && !_ref.current.contains(e.target)) setOpen(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  });

  let customClass = '';

  if (inputClass) customClass = inputClass;
  if (open) customClass = `${customClass} open`;
  const { options, disabled } = props;
  if (options && !options.find(({ value }) => value === field.value)) {
    // Originally we only checked for empty strings "",
    // but we had an issue with formik returning an undefined value for the field
    // which improperly trigged this block of code, so now we also check for undefined.
    if (!['', undefined].includes(field.value)) {
      setTouched({});
      setErrors({});
      setFieldValue(field.name, '');
    }
  }

  let error = '';
  if (!open && meta.touched && meta.error) {
    if ((meta.error === 'Required' && !field.value) || (field.value && meta.error !== 'Required')) {
      error = meta.error;
    }
  }

  return (
    // TODO: Why are we assigning a HTMLInputElement ref into a HTMLDivElement?
    <StyledDropdown className={customClass} ref={ref as any}>
      <label htmlFor={fieldProps.id || fieldProps.name}>{label}</label>

      <input
        className={!open && meta.touched && !!error ? 'error' : null}
        onClick={() => setOpen(!open)}
        size={1}
        readOnly
        {...field}
        {...(fieldProps as any)}
      />
      <ul>
        {_.map(options || states, (state: any) => (
          <li
            key={state.value}
            onClick={() => {
              setFieldValue(field.name, state.value);
              setOpen(false);
              if (onChange) {
                onChange(state);
              }
            }}
          >
            {state.value}
          </li>
        ))}
      </ul>
      <div className="arrow" onClick={() => (disabled ? () => {} : setOpen(!open))} />
      <span className="error">{!open && meta.touched && error ? error : <>&nbsp;</>}</span>
    </StyledDropdown>
  );
});

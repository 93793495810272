import styled from 'styled-components';
import { brandColor, colors } from '../../config';

export const Section = styled.section`
  flex-direction: column;
  justify-content: flex-start;
  flex-grow: 1;
  text-align: center;
  color: ${brandColor};

  .header-container {
    width: 100%;
    margin: 0 auto;
    align-items: center;

    header {
      width: 100%;
      position: absolute;
      h2 {
        font-size: 26px;
        font-weight: 700;
        padding: 0;
      }

      span {
        font-size: 1em;
        font-weight: 500;
        padding: 0;
      }
    }
    img {
      width: 100%;
    }
  }

  button,
  .button {
    display: flex;
    align-items: center;
    align-content: center;
    height: 48px;
    margin: 2em 0;
    border-radius: 24px;
    padding: 0 24px;
    background-color: ${colors.accentYellow};
    color: ${brandColor};
    font-size: 20px;
    font-weight: 600;
    justify-content: center;
    text-decoration: none;
  }

  h3 {
    font-size: 24px;
    font-weight: 700;
    margin-top: 48px;
    letter-spacing: 0.03em;

    @media only screen and (min-width: 980px) {
      font-size: 36px;
    }
  }

  > div {
    display: flex;
    margin-left: 2.5em;
    width: 70%;

    ol {
      max-width: 968px;

      li {
        text-align: left;
        margin-bottom: 48px;
      }
    }

    img.step-image {
      max-width: 95%;
      max-height: 310px;
    }

    div:second-child {
      margin-right: 10%;
    }
  }

  p {
    margin: 8px 0 24px;
    width: 95%;
  }

  @media only screen and (max-width: 980px) {
    > div {
      justify-content: center;

      ol {
        li {
          text-align: center;
        }
      }

      .step-header {
        text-align: left;
      }
    }
  }

  @media only screen and (min-width: 414px) {
    .header-container {
      header {
        h2 {
          font-size: 48px;
        }

        span {
          font-size: 20px;
        }
      }
    }
  }

  @media only screen and (min-width: 480px) {
    > div {
      width: auto;
      align-items: center;
    }
  }

  @media only screen and (min-width: 640px) {
    .header-container {
      header {
        h2 {
          font-size: 36px;
        }

        span {
          font-size: 16px;
        }
      }
    }
  }

  @media only screen and (min-width: 768px) {
    .header-container {
      header {
        h2 {
          font-size: 48px;
        }

        span {
          font-size: 20px;
        }
      }
    }
  }

  @media only screen and (min-width: 980px) {
    .header-container {
      height: 500px;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    > div:not(.header-container) {
      margin: 2em auto;
      width: 100%;
      justify-content: center;

      ol {
        display: flex;
        li {
          margin: 0 24px 0;
        }
        li::marker {
          font-size: 20px;
        }
      }

      p.step-header {
        font-size: 20px;
        width: 70%;
      }
    }
  }
`;

import { useRouter } from 'next/router';
import { PromoCodeDetails } from './interfaces';
import translations from './translations.json';

export { useConfig } from './config';

export function usePromoCodeDetailsFromQuery(): PromoCodeDetails | null {
  const router = useRouter();
  const { promotionCode, promotionType, ...rest } = router.query as any;

  if (!promotionCode || !promotionType) return null;

  return { promotionCode, promotionType, ...rest };
}

export function useErrorPage() {
  const router = useRouter();
  return function (error = '', message = '') {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    router.push({
      query: { error, message },
      pathname: `/error`,
    });
  };
}

export function useActionPage() {
  const router = useRouter();

  return (pathname: string, action?: string) => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
    router.push({ pathname: `/${pathname}`, query: { action } });
  };
}

type Theme = 'theme-subaru' | 'theme-uber' | 'theme-cadillac' | 'theme-nissan' | 'theme-default';

export function useThemeClass(theme: any): Theme {
  switch (theme) {
    case 'subaru':
      return 'theme-subaru';
    case 'uber':
      return 'theme-uber';
    case 'cadillac':
      return 'theme-cadillac';
    case 'nissan':
      return 'theme-nissan';
    default:
      return 'theme-default';
  }
}

type Translation = typeof translations;
type Locale = keyof Translation;
type Key = keyof Translation[Locale];

function t(key: string, locale: string | undefined = 'en') {
  const translation = translations[locale as Locale][key as Key];
  return translation || key;
}

export function useTranslation() {
  const { locale } = useRouter();

  return {
    translate: (key: Key) => t(key, locale),
  };
}

import React from 'react';
import SbEditable from 'storyblok-react';
import { Asset, BaseBlok, Link } from '../../lib/storyblok.interfaces';
import { Section } from './DualLogoHeaderExtend.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface DualLogoHeaderExtendProps {
  blok: BaseBlok & {
    left_logo: Asset;
    right_logo: Asset;
    link: Link;
    link_color: string;
    background_color: string;
    link_display_name: string;
  };
}

export default function DualLogoHeaderExtend(props: DualLogoHeaderExtendProps) {
  return (
    <SbEditable content={props.blok}>
      <div
        className="container mx-auto"
        style={{ backgroundColor: props.blok.background_color }}
        {...generateQaAttrFromBlok(props.blok)}
      >
        <Section>
          {props.blok.left_logo && props.blok.right_logo && (
            <div className={!props.blok.right_logo.filename ? 'images-container-left' : 'images-container'}>
              <img alt={props.blok.left_logo.alt} src={props.blok.left_logo.filename} />
              {!!props.blok.right_logo.filename && (
                <>
                  <div className="divider-container">
                    <div className="divider-parent">
                      <span className="divider" />
                    </div>
                  </div>

                  <img alt={props.blok.right_logo.alt} src={props.blok.right_logo.filename} />
                </>
              )}
            </div>
          )}
        </Section>
      </div>
    </SbEditable>
  );
}

import { gql } from '@apollo/client';

export default gql`
  query listPortalsVehicleMakes($makeInput: ListPortalsVehicleMakesInput) {
    listPortalsVehicleMakes(input: $makeInput) {
      value: altId
      text: manufacturer
    }
  }
`;

import { ParsedUrlQuery } from 'querystring';
import apiClient from '../../src/helpers/apiClient';

const getEvGoInsideToken = async ({ email, password }: { email: string; password: string }) => {
  const url = `https://services.evgo.com/ext_api/v1/account_link`;
  const partyId = '74c0fda1054b04bf3e2365d467e32a47e3feba7b';

  const response = await apiClient({
    url,
    method: 'POST',
    body: { party_id: partyId, email, password },
  });
  if (response.ok) {
    const data = await response.json();
    const token = data.token;
    if (!token) {
      throw new Error('EVGo inside token missing in response');
    }
    return token;
  } else {
    throw new Error(`Failed to get EVGo inside token`);
  }
};

export const linkAmazonAccount = async ({
  email,
  password,
  query,
}: {
  email: string;
  password: string;
  query: ParsedUrlQuery;
}) => {
  const token = await getEvGoInsideToken({ email, password });
  const { state, redirect_uri: redirectUri } = query;
  if (!state || !redirectUri) {
    throw new Error('Missing required amazon URL params');
  }
  const url = `${redirectUri}#token_type=Bearer&state=${state}&access_token=${token}`;
  location.href = url;
};

export const isAmazon = (query: ParsedUrlQuery) => {
  return query.host?.includes('evamzn');
};

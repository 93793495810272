import React, { Fragment } from 'react';
import SbEditable from 'storyblok-react';
import { brandColor } from '../../config';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import DynamicComponent from '../DynamicComponent';
import { Section } from './Confirmation.styled';
import { StepBlok } from './Step';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface ConfirmationProps {
  blok: BaseBlok & {
    login_title: string;
    login_header: string;
    login_subtitle: string;
    login_subheader_1: string;
    login_subheader: string;
    login_header_photo: Asset;
    registration_steps: StepBlok[];
    registration_title: string;
    registration_header: string;
    registration_subtitle: string;
    registration_subheader: string;
    login_header_text_color: string;
    render_login_cta_button: boolean;
    registration_header_photo: Asset;
    registration_header_text_color: string;
  };
}

export default function ConfirmationLogin(props: ConfirmationProps) {
  const loginHeaderTextColor = props.blok.login_header_text_color ? props.blok.login_header_text_color : '';
  const steps: any[] = (props.blok as any)[`login_steps`] || [];

  const getHeaderContainerStyle = () => ({
    color: loginHeaderTextColor || brandColor,
    ...(!props.blok.login_header_photo.filename
      ? {}
      : {
          backgroundSize: 'cover',
          backgroundImage: `url("${props.blok.login_header_photo.filename}")`,
          margin: '0 auto 80px',
          paddingBottom: '80px',
        }),
  });

  const showHeader = props.blok.login_header_photo.filename || props.blok.login_header;

  return (
    <SbEditable content={props.blok}>
      <Section {...generateQaAttrFromBlok(props.blok)}>
        {showHeader && (
          <div className="header-container" style={getHeaderContainerStyle()}>
            <header>
              {props.blok.login_header && <h2>{props.blok.login_header}</h2>}
              {props.blok.login_subheader_1 && <h3>{props.blok.login_subheader_1}</h3>}
              {props.blok.login_subheader && <span>{props.blok.login_subheader}</span>}
              {!props.blok.login_header_photo.filename}
            </header>
          </div>
        )}
        <h3>{props.blok.login_title}</h3>

        <p>{props.blok.login_subtitle}</p>

        {steps && steps.length > 0 && (
          <div>
            <ol>
              {steps.map((blok, index) =>
                DynamicComponent({
                  key: blok._uid,
                  blok: blok,
                  index,
                }),
              )}
            </ol>
          </div>
        )}

        {props.blok.render_login_cta_button && (
          <Fragment>
            <a className="button" href="https://account.evgo.com/login" rel="noreferrer" target="_blank">
              Log into your account
            </a>
          </Fragment>
        )}
      </Section>
    </SbEditable>
  );
}

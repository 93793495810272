import React, { useEffect } from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { StyledBox } from './UberCallback.styled';
import { loginPartnerDriver } from '../../src/apollo/queries/partnerDrivers';
import { useMutation } from '@apollo/client';
import { useRouter } from 'next/router';
import { useConfig, useErrorPage } from '../../lib/hooks';

export default function UberCallBack() {
  const errorPage = useErrorPage();
  const config = useConfig();
  const router = useRouter();

  const [generatePromo, { data, loading, error }] = useMutation(loginPartnerDriver, {
    onCompleted(res) {
      const { promotionCode, promotionType, campaignRef } = res.loginPartnerDriver;

      router.push(
        {
          pathname: `/register`,
          query: { promotionCode, promotionType, campaignRef },
        },
        undefined,
        { locale: router.locale },
      );
    },
    onError() {
      errorPage('', 'We are unable to confirm your driver details at this time.');
    },
  });

  useEffect(() => {
    if (!loading && !error && !data) {
      generatePromo({
        variables: {
          input: {
            promotionRef: config.promotion.ref,
            authorizationCode: router.query.code,
            platformName: 'uber',
          },
        },
      });
    }
  }, [loading, error, data, generatePromo, config.promotion.ref, router.query.code]);

  return (
    <StyledBox>
      <CircularProgress />
    </StyledBox>
  );
}

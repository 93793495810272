import { Asset, BaseBlok } from '@/lib/storyblok.interfaces';
import { FC } from 'react';
import SbEditable from 'storyblok-react';

type Props = {
  blok: BaseBlok & {
    img: Asset;
  };
};

const Banner: FC<Props> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <section>
        <img className="h-auto w-full" src={blok.img.filename} alt={blok.img.alt} />
      </section>
    </SbEditable>
  );
};

export default Banner;

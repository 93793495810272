import { Asset, BaseBlok, Link } from '@/lib/storyblok.interfaces';
import React, { FC } from 'react';
import { renderRichText } from 'src/helpers/rich-text-render';
import SbEditable from 'storyblok-react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

interface Props {
  blok: BaseBlok & {
    hero_img: Asset;
    title: string;
    confirmation_title: string;
    confirmation_steps: { title: string; subtitle: StoryblokRichtext }[];
    app_title: string;
    app_links: { image: Asset; link: Link }[];
  };
}

const ConfirmationHero: FC<Props> = ({ blok }) => {
  const { title, hero_img, confirmation_title, confirmation_steps, app_title, app_links } = blok;

  return (
    <SbEditable content={blok}>
      {/* hero start */}
      <div className="relative flex items-center justify-center px-10 py-20 lg:min-h-[500px]">
        <img className="absolute inset-0 h-full w-full object-cover" src={hero_img?.filename} alt={hero_img?.alt} />
        <h1 className="relative text-center text-3xl font-bold text-white lg:text-5xl">{title}</h1>
      </div>
      {/* hero end */}
      <div className="relative mx-auto mb-10 w-full bg-[#F9FAFB] p-10 text-center shadow-lg lg:-mt-10 lg:max-w-5xl lg:rounded-lg">
        <h2 className="text-2xl font-bold lg:text-3xl">{confirmation_title}</h2>
        <div className="grid grid-cols-1 gap-6 py-10 lg:grid-cols-3">
          {confirmation_steps.map((step, index) => {
            const stepNumber = index + 1;
            return (
              <div
                key={index}
                className="col-span-1 flex flex-col rounded-lg border border-slate-200 bg-white px-6 py-8"
              >
                <div className="flex h-12 w-12 items-center justify-center self-center rounded-full bg-[#21A58D] font-bold text-white">
                  {stepNumber}
                </div>
                <h3 className="mt-6 text-lg font-bold">{step.title}</h3>
                <p className="text-base">{renderRichText(step.subtitle)}</p>
              </div>
            );
          })}
        </div>
        <div className="mx-auto mt-10 flex w-full max-w-xs flex-col items-center justify-center">
          <h4 className="text-2xl font-bold">{app_title}</h4>
          <div className="mt-6 flex gap-4">
            {app_links.map((app, index) => {
              return (
                <a key={index} href={app.link.url}>
                  <img alt={app.image.alt} src={app.image.filename} />
                </a>
              );
            })}
          </div>
        </div>
      </div>
    </SbEditable>
  );
};

export default ConfirmationHero;

import * as Yup from 'yup';

export const promotionSchema = Yup.object().shape({
  promotion: Yup.string().required('Please make a selection'),
});

export const qmeritSchema = Yup.object().shape({
  email: Yup.string()
    .strict(true)
    .trim('Email cannot include leading and trailing spaces')
    .email('Please enter a valid email address')
    .required('Required'),
  address: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string()
    .strict(true)
    .matches(/^[0-9]{5,5}$/, 'Please enter a valid zip code')
    .required('Required'),
});

import { BaseBlok } from '../../lib/storyblok.interfaces';
import { SbEditableContent } from 'storyblok-react';

type QaBaseBlok =
  | ({
      qa_attr?: string;
    } & BaseBlok)
  | SbEditableContent;

/**
 * Adds an attribute to a dom element for qa automated testing.
 *
 * Usage:
 *
 * import { generateQaAttr } from '.../qa-attribute';
 * <div {...generateQaAttr('name')}></div>
 *
 */
export function generateQaAttr(value: string) {
  return { 'data-testid': value };
}

/**
 * Adds an attribute to a dom element for qa automated testing.
 *
 * Usage:
 *
 * import { generateQaAttrFromBlok } from '.../qa-attribute';
 * <div {...generateQaAttrFromBlok(props.blok)}></div>
 *
 */
export function generateQaAttrFromBlok(blok: QaBaseBlok) {
  return { 'data-testid': blok?.qa_attr || blok?.component };
}

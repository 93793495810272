import { gql } from '@apollo/client';

export default gql`
  query ValidateNissanRdr($input: ValidateNissanRdrInput!) {
    validateNissanRdr(input: $input) {
      id
      form
      model
      modelYear
      ownerAddress1
      ownerAddress2
      ownerCity
      ownerDayPhone
      ownerEmailAddress
      ownerFirstName
      ownerLastName
      ownerState
      ownerZip
      retailSaleDate
      uuid
    }
  }
`;

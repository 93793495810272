import React, { FC, Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useFormik } from 'formik';
import Spinner from '@/components/shared/Spinner';
import TwInput from '@/components/shared/TwInput';
import ErrorMessage from '@/components/shared/ErrorMessage';
import { useMutation, useLazyQuery } from '@apollo/client';
import { initiateCustomOpenEnroll } from 'src/apollo/queries/promotionCodes';
import { useConfig } from '@/lib/config';
import states from '../../config/states.json';
import { qmeritSchema } from './validations';
import TwSelect from '@/components/shared/TwSelect';
import apiClient from 'src/helpers/apiClient';
import { checkPromoCodeRedemptionByDisplayValue } from 'src/apollo/queries/promotionCodes';

interface Props {
  isDialogOpen: boolean;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
  vin: string;
}

type FormValues = {
  email: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
};

const QmeritDialog: FC<Props> = ({ isDialogOpen, setIsDialogOpen, vin }) => {
  const [error, setError] = useState<null | string>(null);
  const [isLoading, setIsLoading] = useState(false);
  const config = useConfig();
  const [checkRedemptionStatus] = useLazyQuery(checkPromoCodeRedemptionByDisplayValue, {
    fetchPolicy: 'network-only',
  });

  const [initiateEnrollment] = useMutation(initiateCustomOpenEnroll, {
    onCompleted({ initiateCustomOpenEnroll }) {
      const { success } = initiateCustomOpenEnroll;
      if (!success) {
        setError('There was an error with redeeming your promotion. Please try again later.');
        return success;
      }
      return success;
    },
    onError(err) {
      console.log(err);
    },
  });

  const formik = useFormik<FormValues>({
    initialValues: {
      email: '',
      address: '',
      city: '',
      state: '',
      postalCode: '',
    },
    validationSchema: qmeritSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values: FormValues) => {
      setIsLoading(true);
      setError(null);
      try {
        // Check again to see if VIN has been redeemed already with EVgo
        const { data: evgoRedemptionStatus } = await checkRedemptionStatus({
          variables: {
            input: {
              promotionRef: config.promotion.ref,
              groupName: config.promotion.campaign,
              displayValue: vin,
            },
          },
        });

        const isRedeemed = evgoRedemptionStatus.checkPromoCodeRedemptionByDisplayValue.hasBeenRedeemed;

        if (isRedeemed) {
          setIsLoading(false);
          setError('This VIN has already been redeemed.');
          return;
        }

        // Create Qmerit customer
        const fullAddress = `${values.address} ${values.city}, ${values.state} ${values.postalCode}`;
        const customerRes = await apiClient({
          url: `/api/create-qmerit-customer`,
          method: 'POST',
          body: {
            externalReferenceId: vin,
            email: values.email,
            address: fullAddress,
          },
        });

        const qmeritCustomerData = await customerRes.json();

        if (!customerRes.ok) {
          setError(qmeritCustomerData.message);
          setIsLoading(false);
          return;
        }

        const { assessmentUrl } = qmeritCustomerData;

        // On succes of Customer creation, initiate enrollment
        const isSuccess = await initiateEnrollment({
          variables: {
            input: {
              customDisplayValue: vin,
              enforceUniqueDisplayValue: true,
              groupName: 'qmerit',
              promotionRef: config.promotion.ref,
            },
          },
        });

        if (!isSuccess) {
          setError('There was an error with redeeming your promotion. Please try again later.');
          return;
        }

        // On success of enrollment, redeem VIN
        const redeemPromotionRes = await apiClient({
          url: '/api/redeem-external-promotion',
          method: 'POST',
          body: {
            externalReferenceId: vin,
          },
        });

        const redeemPromotionData = await redeemPromotionRes.json();

        // On success of redemption, redirect to assessment
        if (redeemPromotionData.isSuccess) {
          window.location.href = assessmentUrl;
          setIsLoading(false);
        }
      } catch (e) {
        setIsLoading(false);
        console.log(e);
      }
    },
  });

  return (
    <Transition.Root show={isDialogOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => setIsDialogOpen(false)}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white p-10 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-10 lg:max-w-2xl">
                <div className="absolute right-0 top-0 pr-4 pt-4">
                  <button
                    type="button"
                    className="h-10 w-10 rounded-full border border-slate-400 bg-white p-1 text-slate-400 hover:border-slate-500 hover:text-slate-500 focus:outline-none focus:ring-2 focus:ring-offset-2"
                    onClick={() => setIsDialogOpen(false)}
                  >
                    X
                  </button>
                </div>
                <div className="flex w-full flex-col gap-6">
                  <h3 className="text-2xl font-bold">Qmerit Home Charging Installation Registration</h3>
                  <div className="space-y-2">
                    <p>Please enter your address to verify a Qmerit Certified Installer can service your home.</p>
                    <p className="font-bold">
                      After selecting “Confirm,” you will be redirected to the Qmerit registration page and will no
                      longer be eligible to receive the EVgo public charging credit.
                    </p>
                    <p>
                      If you have any questions, please contact Qmerit Customer Service at{' '}
                      <a
                        className="text-theme-link"
                        href="mailto:customerservice@qmerit.com"
                        target="_blank"
                        rel="noreferrer"
                      >
                        customerservice@qmerit.com
                      </a>{' '}
                      or{' '}
                      <a className="text-theme-link" href="tel:+18882720090" target="_blank" rel="noreferrer">
                        +1(888) 272-0090
                      </a>
                      .
                    </p>
                  </div>
                  <form onSubmit={formik.handleSubmit} className="grid grid-cols-1 gap-6 lg:grid-cols-3">
                    <TwInput
                      value={formik.values.email}
                      label="Email"
                      error={formik.errors.email}
                      required
                      name="email"
                      handleChange={formik.handleChange}
                      className="lg:col-span-3"
                    />
                    <TwInput
                      value={formik.values.address}
                      label="Address"
                      error={formik.errors.address}
                      required
                      name="address"
                      handleChange={formik.handleChange}
                      className="lg:col-span-3"
                    />
                    <TwInput
                      value={formik.values.city}
                      label="City"
                      error={formik.errors.city}
                      required
                      name="city"
                      handleChange={formik.handleChange}
                      className="lg:col-span-1"
                    />
                    <TwSelect
                      value={formik.values.state}
                      label="State"
                      error={formik.errors.state}
                      required
                      name="state"
                      handleChange={formik.handleChange}
                      className="lg:col-span-1"
                      options={states}
                    />
                    <TwInput
                      value={formik.values.postalCode}
                      label="Zip"
                      error={formik.errors.postalCode}
                      required
                      name="postalCode"
                      handleChange={formik.handleChange}
                      className="lg:col-span-1"
                    />
                    <button
                      type="submit"
                      className="relative ml-auto flex h-12 w-44 items-center justify-center rounded-full bg-[#20609F] px-4 font-bold text-white hover:bg-opacity-80 lg:col-span-1 lg:col-start-3"
                    >
                      {isLoading ? <Spinner /> : 'Confirm'}
                    </button>
                  </form>
                  {error && <ErrorMessage errorMessage={error} />}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default QmeritDialog;

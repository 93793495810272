import React from 'react';
import { useField } from 'formik';
import { CheckBox, CheckBoxOutlineBlank } from '@material-ui/icons';
import { StyledCheckbox } from './FormikCheckbox.styled';

interface FormikCheckboxProps extends React.HTMLProps<HTMLInputElement> {
  wrapLabel?: boolean;
}

export default function FormikCheckbox(props: FormikCheckboxProps) {
  const { children, wrapLabel = true, className: inputClass, ...fieldProps } = props;
  const [field, meta] = useField({ ...fieldProps, type: 'checkbox' } as any);
  const checkboxClass = `${meta.touched && meta.error ? 'error' : ''}`;
  let customClass = '';
  let checkbox = <CheckBoxOutlineBlank className={checkboxClass} />;

  if (inputClass) customClass = inputClass;
  if (field.checked) checkbox = <CheckBox className={checkboxClass} />;

  return (
    <StyledCheckbox className={customClass}>
      <label>
        <input type="checkbox" {...field} {...fieldProps} />
        {checkbox}
        {wrapLabel ? <span>{children}</span> : children}
      </label>
      {meta.touched && meta.error ? <span className="error">{meta.error}</span> : null}
    </StyledCheckbox>
  );
}

import classNames from 'classnames';

interface Props {
  className?: string;
  errorMessage: string;
}

const ErrorMessage = ({ className, errorMessage }: Props) => {
  return (
    <div className={classNames('rounded-lg border border-red-400 bg-red-100 p-4 text-red-500', className)}>
      {errorMessage}
    </div>
  );
};
export default ErrorMessage;

import _ from 'lodash';
import { useRouter } from 'next/router';
import React, { Fragment, useEffect, useState } from 'react';
import SbEditable from 'storyblok-react';
import { Richtext } from 'storyblok-js-client';
import { usePromoCodeDetailsFromQuery } from '../../lib/hooks';
import { BaseBlok, Link } from '../../lib/storyblok.interfaces';
import DynamicComponent from '../DynamicComponent';
import LoginFormExtend from '../LoginFormExtend';
import RegisterForm from '../RegisterForm';
import { Section } from './RegisterViewExtend.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import NissanRegisterForm from '../NissanRegisterForm';
import { ConsentCondition } from '../RegisterForm/shared';

export interface RegisterViewExtendParams extends BaseBlok {
  plan_code: string;
  code_entry: boolean;
  full_width: boolean;
  list_items: { text: string }[];
  login_copy: string;
  login_header: string;
  redirect_url: Link;
  platform_name: string;
  register_header: string;
  background_color: string;
  subscriptionCopy: string;
  confirmation_route: Link;
  login_header_cta_text: string;
  selected_vehicle_make: string;
  login_submit_button_copy: string;
  register_header_cta_text: string;
  header_cta_text_underline: boolean;
  header_cta_background_color: string;
  login_submit_button_subtext: string;
  register_submit_button_copy: string;
  register_submit_button_subtext: string;
  consent_conditions?: ConsentCondition[];
  default_to_login_form: boolean;
}

enum View {
  Login = 'login',
  Register = 'register',
}

const RegisterViewExtend = (props: { blok: RegisterViewExtendParams }) => {
  const backgroundColor = props.blok['background_color'] || null;
  const ctaBackgroundColor = props.blok['header_cta_background_color'] || null;
  const ctaTextUnderline = props.blok['header_cta_text_underline'];
  const router = useRouter();
  const defaultView = router?.query?.form
    ? router?.query?.form
    : props.blok.default_to_login_form
    ? View.Login
    : View.Register;
  const [view, setView] = useState(defaultView);
  const [clicked, setClicked] = useState(false);
  const fullWidth = props.blok['full_width'];
  const promoCodeDetails = usePromoCodeDetailsFromQuery();

  // If there is a query param portal=nissan then run the specific Nissan query
  const isNissan = router?.query?.portal === 'nissan';

  useEffect(() => {
    const viewingFromStoryblok = router.asPath.includes('_storyblok');

    // ignore verification because there is no home page for Nissan
    if (isNissan) return;

    if (!viewingFromStoryblok && !promoCodeDetails && router.isReady) {
      router.push(`/home`);
    }
  }, [promoCodeDetails, router, isNissan]);

  useEffect(() => {
    if (router.pathname && router.pathname.includes(View.Register) && view === View.Login && !clicked) {
      setView(View.Register);
      setClicked(true);
    } else {
      setClicked(false);
    }
  }, [clicked, router.pathname, setView, view]);

  useEffect(() => {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, [view]);

  const registerForm = isNissan ? (
    <NissanRegisterForm {...props} setView={setView} />
  ) : (
    <RegisterForm {...props} setView={setView} />
  );

  const headerCtaText =
    view === View.Register ? props.blok['register_header_cta_text'] : props.blok['login_header_cta_text'];

  const header = (
    <Fragment>
      <button
        className="cta-button"
        onClick={() => {
          setClicked(true);
          setView(view == View.Login ? View.Register : View.Login);
        }}
      >
        {headerCtaText}
      </button>
      <hr />
    </Fragment>
  );

  return (
    <SbEditable content={props.blok}>
      <Section
        fullWidth={fullWidth}
        backgroundColor={backgroundColor}
        ctaBackgroundColor={ctaBackgroundColor}
        ctaTextUnderline={ctaTextUnderline}
        {...generateQaAttrFromBlok(props.blok)}
      >
        {!_.isEmpty(props.blok.list_items) && (
          <div>
            <ul>{props.blok.list_items && props.blok.list_items.map((blok) => DynamicComponent({ blok }))}</ul>
          </div>
        )}
        <div>
          {view === View.Register ? (
            <h2 className="header" dangerouslySetInnerHTML={{ __html: props.blok['register_header'] }} />
          ) : (
            <h2 className="header" dangerouslySetInnerHTML={{ __html: props.blok['login_header'] }} />
          )}
          {headerCtaText && header}
          {view === View.Login ? <LoginFormExtend {...props} setView={setView} /> : registerForm}
        </div>
      </Section>
    </SbEditable>
  );
};

export default RegisterViewExtend;

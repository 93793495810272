import styled from 'styled-components';

export const Section = styled.section`
  position: relative;
  padding: 24px;
  max-width: 1168px;
  margin: auto;

  .images-container {
    display: flex;
    align-items: center;
  }

  img {
    max-height: 55px;
    vertical-align: middle;
    display: table-cell;
  }

  .divider-parent {
    display: flex;
  }

  .divider-container {
    display: table-cell;
    vertical-align: middle;
  }

  .divider {
    width: 1px;
    background: #707070;
    height: 36px;
    margin: 0 10px;
  }

  @media screen and (max-width: 980px) {
    padding: 0 24px;
    margin-top: 24px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    .images-container {
      display: table;
    }

    .images-container-left {
      display: table-cell;
    }

    img {
      height: auto;
      width: 100%;
    }

    .divider {
      height: 24px;
    }
  }
`;

import _ from 'lodash';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import SbEditable from 'storyblok-react';
import React, { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import { initiateOpenEnroll } from '../../src/apollo/queries/promotionCodes';
import { useConfig, useErrorPage } from '../../lib/hooks';
import Spinner from '../shared/Spinner';
import Button from '../Controls/Button';

type OEGettingStartedBlok = BaseBlok & {
  helper_text: string;
  button_text: string;
  button_text_color: string;
  button_background_color: string;
  button_square: boolean;
  align_center: boolean;
};

type OEGettingStartedProps = { blok: OEGettingStartedBlok };

export const Container = styled.div<{ alignCenter: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${(props) =>
    props.alignCenter &&
    css`
      align-items: center;
    `}
`;

export const HelperText = styled.div`
  color: #707070;
  margin: 0 0 14px;
`;

export const StyledButton = styled(Button)<OEGettingStartedProps>`
  position: relative;
  padding: 10px 18px;
  height: 48px;

  min-width: 200px;
  font-family: 'Mulish';
  font-weight: bold;
  font-size: 18px;

  ${(props) =>
    props.blok.button_background_color &&
    css`
      background-color: ${props.blok.button_background_color};
    `}

  ${(props) =>
    props.blok.button_text_color &&
    css`
      color: ${props.blok.button_text_color};
    `}

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

export default function OpenEnrollmentButton(props: OEGettingStartedProps) {
  const { blok } = props;
  const config = useConfig();
  const router = useRouter();
  const { campaign } = router.query;
  const errorPage = useErrorPage();

  const [initiateEnrollment, { loading }] = useMutation(initiateOpenEnroll, {
    onCompleted(res) {
      const { promotionCode, promotionType } = _.get(res, 'initiateOpenEnroll', {});

      const originQuery = router?.query;
      router.push({
        pathname: `/register`,
        query: { ...originQuery, promotionCode, promotionType },
      });
    },
    onError(err) {
      errorPage(_.get(err, 'code', 'Unknown'), _.get(err, 'message', 'Unknown'));
    },
  });

  const onClick = useCallback(async () => {
    await initiateEnrollment({
      variables: {
        input: {
          promotionRef: config.promotion.ref,
          campaignRef: campaign,
        },
      },
    });
  }, [initiateEnrollment, config, campaign]);

  return (
    <SbEditable content={blok}>
      <Container alignCenter={blok.align_center}>
        {blok.helper_text && <HelperText>{blok.helper_text}</HelperText>}

        <StyledButton {...props} onClick={onClick} disabled={loading}>
          {loading ? <Spinner /> : blok.button_text}
        </StyledButton>
      </Container>
    </SbEditable>
  );
}

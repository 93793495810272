import SbEditable from 'storyblok-react';
import classNames from 'classnames';
import { Asset, BaseBlok, NestedBlok } from '@/lib/storyblok.interfaces';
import PricingPlan from './PricingPlan';

type Feature = BaseBlok & {
  text: string;
};

export type BasePlan = {
  is_partner_plan: boolean;
  is_trademarked: boolean;
  logo: Asset;
  plan_data: string;
  savings_rate: string;
  subheadline: string;
  features: Array<Feature>;
};

type Plan = NestedBlok<BasePlan>;

type Props = {
  blok: BaseBlok & {
    header: string;
    plans: Array<Plan>;
    partner_plans: Array<Plan>;
  };
};

function PartnerPlans({ plans }: { plans: Array<Plan> }) {
  return (
    <div className="isolate mx-auto mt-10 flex max-w-md flex-col justify-center gap-8 md:max-w-2xl md:flex-row lg:max-w-4xl xl:max-w-3xl">
      {plans.map(({ content, uuid }) => {
        const base_plan = content as unknown as BasePlan;
        return <PricingPlan key={uuid} {...base_plan} />;
      })}
    </div>
  );
}

function EvgoPlans({ plans }: { plans: Array<Plan> }) {
  return (
    <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-8 md:max-w-2xl md:grid-cols-2 lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-4">
      {plans.map(({ content, uuid }) => {
        const base_plan = content as unknown as BasePlan;
        return <PricingPlan key={uuid} {...base_plan} />;
      })}
    </div>
  );
}

export default function PricingPlans({ blok }: Props) {
  const { header, plans, partner_plans } = blok;

  const renderPartnerPlans = partner_plans && partner_plans.length > 0;
  return (
    <SbEditable content={blok}>
      <section className={classNames('py-16 lg:py-24')}>
        <div className="mx-auto max-w-7xl text-center">
          <h2 className="m-0 text-3xl font-bold text-theme-base lg:text-5xl">{header}</h2>
        </div>
        <div className="container">
          {renderPartnerPlans && <PartnerPlans plans={partner_plans} />}
          <EvgoPlans plans={plans} />
        </div>
      </section>
    </SbEditable>
  );
}

import DynamicComponent from './DynamicComponent';
import SbEditable, { SbEditableContent } from 'storyblok-react';
import Footer from './shared/Footer';
import { BaseBlok } from '../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../src/helpers/qa-attribute';

interface PageProps {
  content: SbEditableContent;
  footer: BaseBlok;
}

const Page = ({ content, footer }: PageProps) => {
  return (
    <SbEditable content={content}>
      <main {...generateQaAttrFromBlok(content)}>
        {content.body.map((blok: BaseBlok) => (
          <DynamicComponent blok={blok} key={blok._uid} />
        ))}
        {!content['remove_footer'] && <Footer blok={footer as any} />}
      </main>
    </SbEditable>
  );
};

export default Page;

import React from 'react';
import SbEditable from 'storyblok-react';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

export interface AppIcon extends BaseBlok {
  app_icon_link: string;
  app_icon_image: Asset;
}

interface AppIconProps {
  index: number;
  className: string;
  blok: AppIcon;
}

const AppIcon = (props: AppIconProps) => (
  <SbEditable content={props.blok}>
    <a
      rel="noreferrer"
      className={`${props.className || ''} app-icon`}
      href={props.blok['app_icon_link']}
      target="_blank"
      {...generateQaAttrFromBlok(props.blok)}
    >
      <img
        className={`${props.className || ''}`}
        src={props.blok['app_icon_image'].filename}
        alt={props.blok['app_icon_image'].alt}
      />
    </a>
  </SbEditable>
);

export default AppIcon;

import { BaseBlok } from '@/lib/storyblok.interfaces';
import { FC } from 'react';
import { renderRichText } from 'src/helpers/rich-text-render';
import SbEditable from 'storyblok-react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

type Props = {
  blok: BaseBlok & {
    description: StoryblokRichtext;
  };
};

const Footnote: FC<Props> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <section className="my-10 mx-auto w-full max-w-4xl px-4 text-sm">{renderRichText(blok.description)}</section>
    </SbEditable>
  );
};

export default Footnote;

import styled from 'styled-components';
import { Sync } from '@material-ui/icons';

const Spinner = styled(Sync)`
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  animation: around 3s infinite;

  ::after {
    animation: around 0.7s ease-in-out 0.2s infinite;
    background: transparent;
  }

  @keyframes around {
    100% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(1080deg);
    }
  }
`;

export default Spinner;

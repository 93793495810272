import React from 'react';
import SbEditable from 'storyblok-react';
import Image from 'next/image';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import { Section } from './FastChargingExtend.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface FastChargingExtendProps {
  blok: BaseBlok & {
    image: Asset;
    title: string;
    flip_order: boolean;
    full_width: boolean;
    description: string;
    background_color: string;
    text_color?: string;
  };
}

/**
 * Fast Charging component
 * @returns {Object}
 */
export default function FastChargingExtend(props: FastChargingExtendProps) {
  const id = 'fastcharging';
  const backgroundColor = props.blok.background_color ? props.blok.background_color : '';
  const textColor = props.blok.text_color;

  return (
    <SbEditable content={props.blok}>
      <Section
        backgroundColor={backgroundColor}
        textColor={textColor}
        fullWidth={props.blok.full_width}
        photoFirst={props.blok.flip_order}
        {...generateQaAttrFromBlok(props.blok)}
      >
        <div>
          <div>
            <h2>{props.blok.title}</h2>
            <span dangerouslySetInnerHTML={{ __html: props.blok.description }} />
          </div>
          <div>
            <Image width="500" height="329" alt={props.blok.image.alt} src={props.blok.image.filename} />
          </div>
        </div>
      </Section>
    </SbEditable>
  );
}

import { Form } from 'formik';
import styled from 'styled-components';

import { brandColor, colors } from '../../config';

export const Button = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  width: 272px;
  height: 48px;
  margin: 1.5em auto 0;
  padding: 0 24px;
  background-color: ${colors.accentYellow};
  color: ${brandColor};
  font-size: 20px;
  font-weight: 600;
  justify-content: center;
  text-decoration: none;
  min-width: 272px;
  border-radius: 0;
  border: none;

  span {
    &.loading {
      opacity: 0;
    }
  }

  .spinner {
    position: absolute;
    animation: around 3s infinite;
  }

  .spinner::after {
    animation: around 0.7s ease-in-out 0.2s infinite;
    background: transparent;
  }

  @keyframes around {
    100% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(1080deg);
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  h3 {
    font-weight: 700;
    padding-left: 10px;
    padding-bottom: 5px;
    padding-top: 10px;
    font-size: 24px;
    width: 100%;
  }

  h6 {
    flex-basis: 100%;
    padding: 0 10px;
    text-align: center;
    font-size: 13px;
    color: #3f647c;
  }

  hr {
    flex-basis: auto;
    width: 100%;
    margin: 2em 0;
    border-top: 1px solid #dee5ea;
  }

  div.submit-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    p {
      padding: 15px;
    }
    .error-container {
      color: #cc0000;

      .action {
        color: inherit;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  @media only screen and (min-width: 768px) {
    h3 {
      font-size: 36px;
    }
  }
`;

export const ForgotPasswordContainer = styled.div`
  width: 100%;
  position: relative;

  a {
    position: absolute;
    top: 4px;
    right: 10px;
    color: ${colors.accentLink};
  }
`;

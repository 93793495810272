import styled from 'styled-components';

export const StyledSiteMap = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  margin: 32px 0 0;

  h2 {
    padding: 0;
    margin: 0 0 16px;
    font-weight: 500;
    font-size: 1.125em;
  }

  @media only screen and (min-width: 980px) {
    margin: 0 32px;
  }
`;

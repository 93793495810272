import React from 'react';
import { BasePlan as Props } from './PricingPlans';
import classNames from 'classnames';

export default function PricingPlan({ is_partner_plan, features, savings_rate, ...delegated }: Props) {
  return (
    <div
      className={classNames(
        'rounded-3xl p-8 ring-1',
        is_partner_plan ? 'w-full shadow-xl ring-gray-900/10 md:max-w-sm' : 'ring-gray-200',
      )}
    >
      <Header is_partner_plan={is_partner_plan} {...delegated} />
      <SavingsRate savings_rate={savings_rate} />
      <Features features={features} />
    </div>
  );
}

function Header({
  is_partner_plan,
  logo,
  plan_data,
  subheadline,
  is_trademarked,
}: Omit<Props, 'features' | 'savings_rate'>) {
  // plan is in the format of "Plan Display Name_Plan Code_Plan Alt ID due to the limitations of the Storyblok datasource value
  const [planDisplayName] = plan_data.split('_');
  const planName = is_trademarked ? `${planDisplayName}™` : planDisplayName;

  if (is_partner_plan) {
    return (
      <div className="flex flex-col justify-center gap-4">
        {logo.filename ? (
          <>
            <img src={logo.filename} alt={logo.alt} className="mx-auto h-auto w-full max-w-[200px]" />
          </>
        ) : (
          <>
            <h3 className="text-lg font-bold leading-8">{planDisplayName}</h3>
          </>
        )}
        {subheadline && <p className="text-center text-base leading-6 text-gray-600">{subheadline}</p>}
      </div>
    );
  }
  return (
    <div>
      <h3 className="text-center text-xl font-bold leading-8 text-theme-base">{planName}</h3>
      {subheadline && <p className="mt-4 text-center text-base leading-6 text-gray-600">{subheadline}</p>}
    </div>
  );
}

function SavingsRate({ savings_rate }: Pick<Props, 'savings_rate'>) {
  return (
    <div className="mt-6 flex flex-col items-center text-theme-base">
      <p className="flex flex-col">
        <span className="text-xs leading-6 text-gray-600">Save up to</span>
        <span className="text-center text-4xl font-bold tracking-tight">{savings_rate}%</span>
        <span className="text-xs leading-6 text-gray-600">per month*</span>
      </p>
    </div>
  );
}

function Features({ features }: Pick<Props, 'features'>) {
  return (
    <ul role="list" className="mt-8 list-disc space-y-3 pl-8 text-base leading-6 text-gray-600 marker:text-gray-600">
      {features?.map((feature) => (
        <li key={feature._uid}>{feature.text}</li>
      ))}
    </ul>
  );
}

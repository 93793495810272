import { useRouter } from 'next/router';
import { useState, FC } from 'react';
import SbEditable from 'storyblok-react';
import VinForm from './VinForm';
import PromotionForm from './PromotionForm';
import { HeroProps } from './types';

const SubaruHeroForm: FC<HeroProps> = ({ blok }) => {
  const [isValidated, setIsValidated] = useState(false);
  const [vin, setVin] = useState('');
  const { title, hero_img } = blok;
  const { query } = useRouter();
  const { conf, date, qmeritonly } = query;

  return (
    <SbEditable content={blok}>
      {/* hero start */}
      <div className="relative flex items-center justify-center px-10 py-20 lg:min-h-[500px]">
        <img className="absolute inset-0 h-full w-full object-cover" src={hero_img?.filename} alt={hero_img?.alt} />
        <h1 className="relative text-center text-3xl font-bold text-white lg:text-5xl">{title}</h1>
      </div>

      {/* hero end */}
      {/* Vin form start */}
      {isValidated ? (
        <PromotionForm
          promotion_options={blok.promotion_options}
          qmeritonly={qmeritonly}
          promotion_text={blok.promotion_text}
          vin={vin}
        />
      ) : (
        <VinForm
          conf={conf}
          date={date}
          qmeritonly={qmeritonly}
          handleValidateVin={() => setIsValidated(true)}
          setVin={setVin}
        />
      )}
      {/* form end */}
    </SbEditable>
  );
};

export default SubaruHeroForm;

import styled from 'styled-components';

export const SitemapItem = styled.li`
  position: relative;
  top: auto;
  left: auto;
  right: auto;
  bottom: auto;
  margin: 6px 0;
  list-style: none;
`;

export const StyledSitemapLink = styled.a`
  flex: 0 1 auto;
  color: white;
  text-decoration: none;
  font-style: normal;
`;

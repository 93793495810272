import styled, { css } from 'styled-components';

interface SectionProps {
  backgroundColor: string;
  textColor?: string;
  fullWidth: boolean;
  photoFirst: boolean;
}

export const Section = styled.section`
  background: ${({ backgroundColor }: SectionProps) => (backgroundColor ? backgroundColor : '')};
  flex-direction: column;
  box-sizing: border-box;
  padding: 64px 32px;
  width: 100%;

  ${({ textColor }: SectionProps) =>
    textColor &&
    css`
      color: ${textColor};
    `}

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    text-align: center;
    h2 {
      margin: 0 0 16px;
      padding: 0;
      font-size: 24px;
      font-weight: 700;
      letter-spacing: 0.03em;
    }

    span {
      font-size: 16px;
      font-weight: 400;
    }

    img {
      padding: 24px 0px 0px;
      max-height: 300px;
      max-width: 350px;
    }
  }

  @media only screen and (min-width: 768px) {
    width: ${({ fullWidth }: SectionProps) => (fullWidth ? '100%' : '95%')};
    &.full-width {
      width: 100%;
    }
    > div {
      display: grid;
      grid-template-columns: 500px 1fr;
      grid-auto-flow: dense;
      max-width: 1120px;
      height: 100%;
      margin: auto;
      > div {
        align-items: flex-start;
        text-align: left;
        padding: 0px;
        justify-self: flex-end;
        &:first-child {
          grid-column: ${({ photoFirst }: SectionProps) => (photoFirst ? 2 : 0)};
          justify-self: flex-start;
        }
        img {
          padding: 0px;
          max-height: 450px;
          max-width: 550px;
        }
      }
    }
  }

  @media only screen and (min-width: 980px) {
    > div {
      h2 {
        font-size: 36px;
      }
      span {
        font-size: 20px;
      }
      img {
        max-height: 500px;
        max-width: 550px;
      }
    }
  }
`;

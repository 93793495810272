import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { Sync } from '@material-ui/icons';
import { Formik } from 'formik';
import _ from 'lodash';
import React, {
  createRef,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  FocusEvent,
  ChangeEvent,
} from 'react';
import { useRouter } from 'next/router';
import { events, getAnalyticsClient } from '@/lib/analytics';
import { isAmazon, linkAmazonAccount } from '@/src/helpers/amazon';
import Storyblok from '../../lib/storyblok';
import { useActionPage, useConfig, useErrorPage, usePromoCodeDetailsFromQuery } from '../../lib/hooks';
import { redeemPromotion } from '../../src/apollo/queries/promotionCodes';
import { listPortalsVehicleMakes, listPortalsVehicleModels } from '../../src/apollo/queries/vehicleLookup';
import { RegisterViewExtendParams } from '../RegisterViewExtend';
import FormikCheckbox from '../shared/FormikCheckbox';
import FormikDropdown from '../shared/FormikDropdown';
import FormikTextInput from '../shared/FormikTextInput';
import { Button, StyledForm } from './RegisterForm.styled';
import createValidationSchema from './validations';
import { generateQaAttr, generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import switchLambdaService from '../../src/helpers/switchLambdaService';
import {
  isEmailRegistered,
  ErrorMessage,
  EmailStatus,
  getConsentConditionsDefaultValues,
  getOmittableConsentConditionsKeys,
  createConsentConditionsValidation,
  ConsentConditionCheckbox,
} from './shared';

interface VehicleMake {
  value: string;
  name: string;
}

interface RegisterFormProps {
  id?: string;
  className?: string;
  blok: RegisterViewExtendParams & {
    terms_conditions_copy?: string;
    vehicle_models?: string;
    default_vehicle_year?: any;
  };
  setView: (view: string) => void;
}

const RegisterForm = (props: RegisterFormProps) => {
  const stateRef = createRef<any>();
  const makeRef = createRef<any>();
  const modelRef = createRef<any>();
  const yearRef = createRef<any>();
  const [loading, setLoading] = useState<boolean>();
  const [make, setMake] = useState<string>('');
  const [model, setModel] = useState<string>('');
  const [year, setYear] = useState<string>('');
  const config = useConfig();
  const promoCodeDetails = usePromoCodeDetailsFromQuery();
  const errorPage = useErrorPage();
  const actionPage = useActionPage();
  const router = useRouter();
  const analytics = getAnalyticsClient();
  const [emailStatus, setEmailStatus] = useState<EmailStatus>(EmailStatus.pendingValidation);

  const { data: makes } = useQuery(listPortalsVehicleMakes, {
    variables: {
      makeInput: {
        DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
      },
    },
  });

  const [onListModels, { data: models }] = useLazyQuery(listPortalsVehicleModels);

  const validationSchema = useMemo(
    () => createValidationSchema(createConsentConditionsValidation(props.blok?.consent_conditions || [])),
    [props.blok.consent_conditions],
  );

  const makeOptions = useMemo(
    () =>
      makes && makes.listPortalsVehicleMakes
        ? makes.listPortalsVehicleMakes.map(({ text, value }: { text: string; value: string }) => ({
            value: text,
            name: value,
          }))
        : [],
    [makes],
  );

  let modelOptions =
    models && models.listPortalsVehicleModels
      ? models.listPortalsVehicleModels.map(({ text, value }: { text: string; value: string }) => ({
          value: text,
          name: value,
        }))
      : [];
  if (props.blok.vehicle_models) {
    const vehicleMakes = props.blok.vehicle_models.split(',');
    modelOptions = vehicleMakes
      ? modelOptions.filter((m: VehicleMake) => m.name && _.includes(vehicleMakes, m.name))
      : modelOptions;
  }

  const [defaultVehicleMake, setDefaultVehicleMake] = useState<VehicleMake | null>(null);

  useEffect(() => {
    if (props.blok.selected_vehicle_make && _.isEmpty(defaultVehicleMake)) {
      _.map(makeOptions, (make) => {
        if (make.value === props.blok.selected_vehicle_make) {
          setDefaultVehicleMake(make);
        }
      });
    }
  }, [defaultVehicleMake, makeOptions, setDefaultVehicleMake, props.blok.selected_vehicle_make]);

  const startYear = 2000;
  const yearOptions = Array.from(Array(new Date().getFullYear() - startYear + 2).keys())
    .map((i) => {
      return { value: String(startYear + i), name: String(startYear + i) };
    })
    .reverse();

  const [applyPromotion] = useMutation(redeemPromotion, {
    async onCompleted(res) {
      if (res && res.redeemPromotion && res.redeemPromotion.success) {
        // Clear memoization's cache.
        isEmailRegistered?.cache?.clear?.();

        if (props.blok.confirmation_route && props.blok.confirmation_route.url) {
          actionPage(props.blok.confirmation_route.url);
        } else {
          if (isAmazon(router.query)) {
            try {
              await linkAmazonAccount({
                email: formRef.current.values.email,
                password: formRef.current.values.password,
                query: router.query,
              });
              actionPage('/confirmation', 'login');
              return;
            } catch (error) {
              console.error('Failed to link Amazon account', { error });
              errorPage('Failed to link Amazon account. Please try to login');
              props.setView('login');
              return;
            }
          }
          actionPage('finish-registration');
        }
      }
    },
    onError(err) {
      errorPage(err.message || '');
    },
  });

  const formRef = useRef<any>();

  const handleSubmit = useCallback(
    ({ confirmPassword, ...contractInput }: { confirmPassword: string }) => {
      const input = {
        promotionCode: promoCodeDetails?.promotionCode,
        promotionRef: config.promotion.ref,
        planCode: props.blok.plan_code,
        platformName: props.blok.platform_name,
        DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
        ...contractInput,
      };

      return applyPromotion({ variables: { input } });
    },
    [applyPromotion, config.promotion.ref, promoCodeDetails, props.blok.plan_code, props.blok.platform_name],
  );

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    state: '',
    postalCode: '',
    make: props.blok.selected_vehicle_make,
    model: '',
    year: props.blok.default_vehicle_year,
    password: '',
    confirmPassword: '',
    subscribe: true,
    terms: false,
    subscriptionCopy: props.blok.subscriptionCopy,
    ...getConsentConditionsDefaultValues(props.blok?.consent_conditions || []),
  };

  const handleChange = useCallback(
    ({ name, value }, type) => {
      switch (type) {
        case 'make':
          setMake(value);
          setModel('');
          setYear('');
          onListModels({
            variables: {
              modelInput: {
                altId: name,
                DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
              },
            },
          });
          break;
        case 'model':
          setModel(value);
          setYear('');
          break;
        case 'year':
          setYear(value);
          break;
        default:
          break;
      }
    },
    [onListModels],
  );

  useEffect(() => {
    if (!_.isEmpty(defaultVehicleMake) && defaultVehicleMake?.value !== make) {
      handleChange(defaultVehicleMake, 'make');
      formRef.current.values.make = defaultVehicleMake?.value;
    }
  }, [defaultVehicleMake, make, handleChange]);

  const handleRegister = async (values: any) => {
    analytics.track(events.clickRegisterButton, {
      host: window.location.host,
      pathname: window.location.pathname,
      email: values.email,
    });

    setLoading(true);

    const newValue = _.omit(values, getOmittableConsentConditionsKeys(props.blok?.consent_conditions || []));

    return handleSubmit(newValue as any);
  };

  if (
    formRef &&
    formRef.current &&
    formRef.current.values &&
    formRef.current.values.make === 'BMW' &&
    !_.isEmpty(modelOptions)
  ) {
    const filterArray = [
      '1 Active E',
      'ActiveE',
      'Vision',
      'Megacity I3',
      '530e',
      '740e xDrive iPerformance',
      '740e xDrive Plug-In Hybrid',
    ];
    modelOptions = _.filter(modelOptions, (o) => {
      return !filterArray.includes(o.value);
    });

    modelOptions = _.map(modelOptions, (model) => {
      if (model.value === 'I3' || model.value === 'I8') {
        model.value = model.value.toLowerCase();
      }
      return model;
    });

    modelOptions.sort((a: any, b: any) => {
      if (
        a.value.charAt(0) === a.value.charAt(0).toLowerCase() &&
        _.isNaN(Number(a.value.charAt(0))) &&
        b.value.charAt(0) === b.value.charAt(0).toUpperCase()
      ) {
        return -1;
      }
      if (
        a.value.charAt(0) === a.value.charAt(0).toLowerCase() &&
        _.isNaN(Number(a.value.charAt(0))) &&
        !_.isNaN(b.value.charAt(0))
      ) {
        return 1;
      }
      if (
        a.value.charAt(0) === a.value.charAt(0).toUpperCase() &&
        _.isNaN(Number(a.value.charAt(0))) &&
        !_.isNaN(b.value.charAt(0))
      ) {
        return 1;
      }
      if (a.value == b.value) return 0;
      return a.value < b.value ? -1 : 1;
    });
  }

  const getEmailStatus = async (input: HTMLInputElement) => {
    const value = input.value;

    if (value) {
      const registered = await isEmailRegistered(value);
      setEmailStatus(registered ? EmailStatus.registered : EmailStatus.unregistered);
    }
  };

  const onEmailBlur = (event: FocusEvent<HTMLInputElement>) => {
    formRef.current.handleBlur(event);
    getEmailStatus(event.target);
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    const isFocused = document.activeElement === event.target;

    formRef.current.handleChange(event);

    if (emailStatus !== EmailStatus.pendingValidation) {
      setEmailStatus(EmailStatus.pendingValidation);
    }

    if (!isFocused) {
      getEmailStatus(event.target);
    }
  };

  const isRegistered = emailStatus === EmailStatus.registered;
  const isUnregistered = emailStatus === EmailStatus.unregistered;

  return (
    <Fragment>
      <Formik
        innerRef={formRef}
        onSubmit={handleRegister}
        validationSchema={validationSchema}
        initialValues={initialValues}
        validateOnMount={true}
        {...generateQaAttrFromBlok(props.blok)}
      >
        {(formik) => (
          <StyledForm>
            <h3>Account Details</h3>
            <FormikTextInput className="half" name="firstName" label="First Name*" {...generateQaAttr('first-name')} />
            <FormikTextInput className="half" name="lastName" label="Last Name*" {...generateQaAttr('last-name')} />
            <FormikTextInput
              className="half"
              name="email"
              label="Email*"
              onBlur={onEmailBlur}
              onChange={onEmailChange}
              error={isRegistered && 'This email address already exists in our system.'}
              {...generateQaAttr('email')}
            />
            <FormikTextInput
              className="half"
              name="phone"
              type="tel"
              label="Contact Number*"
              {...generateQaAttr('phone')}
            />

            <h3>Address</h3>
            <FormikTextInput name="address" label="Street Address*" {...generateQaAttr('address')} />
            <FormikTextInput className="half" name="city" label="City*" {...generateQaAttr('city')} />
            <FormikDropdown
              className="quarter"
              name="state"
              label="State*"
              {...generateQaAttr('state')}
              ref={stateRef}
            />
            <FormikTextInput className="quarter" name="postalCode" label="Zip*" {...generateQaAttr('postal-code')} />

            <h3>Vehicle Information</h3>
            {!_.isEmpty(defaultVehicleMake) ? (
              <FormikTextInput
                className="half"
                name="make"
                label="Make*"
                {...generateQaAttr('vehicle-make')}
                value={defaultVehicleMake?.value}
                ref={makeRef}
                disabled
              />
            ) : (
              <FormikDropdown
                className="half"
                name="make"
                label="Make*"
                value={make}
                onChange={(value: any) => handleChange(value, 'make')}
                options={makeOptions}
                {...generateQaAttr('vehicle-make-dropdown')}
                ref={makeRef}
              />
            )}
            <FormikDropdown
              value={model}
              className="quarter"
              disabled={!make}
              name="model"
              label="Model*"
              onChange={(value: any) => handleChange(value, 'model')}
              options={modelOptions}
              {...generateQaAttr('vehicle-model-dropdown')}
              ref={modelRef}
            />
            <FormikDropdown
              value={year || props.blok.default_vehicle_year || ''}
              className="quarter"
              disabled={!make}
              name="year"
              label="Year*"
              onChange={(value: any) => handleChange(value, 'year')}
              options={yearOptions as any}
              {...generateQaAttr('vehicle-year-dropdown')}
              ref={yearRef}
            />

            <h3>Security</h3>
            <FormikTextInput
              className="half"
              name="password"
              label="Password*"
              type="password"
              {...generateQaAttr('password')}
            />
            <FormikTextInput
              className="half"
              name="confirmPassword"
              label="Confirm Password*"
              type="password"
              {...generateQaAttr('confirm-password')}
            />

            <h6>
              Password requirements: 8-24 characters, including at least 1 number, 1 uppercase, 1 lowercase & 1 special
              character
            </h6>
            <hr />
            {props.blok.terms_conditions_copy ? (
              <FormikCheckbox name="terms" {...generateQaAttr('terms-checkbox')}>
                <div dangerouslySetInnerHTML={{ __html: props.blok.terms_conditions_copy }} />
              </FormikCheckbox>
            ) : (
              <FormikCheckbox name="terms" {...generateQaAttr('terms-checkbox')}>
                By checking this box, you acknowledge you have read and agree to be bound by our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/terms-service/" className="underline">
                  Terms of Service
                </a>{' '}
                and our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
                  Privacy Policy
                </a>
                . By submitting this form, you acknowledge you are sharing your personal information with EVgo and
                consent to EVgo’s Privacy Notice for California Residents. More detail regarding personal information we
                collect, how we use that information, how we share that information, and your rights and choices can be
                found in our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
                  Privacy Policy
                </a>
                .
              </FormikCheckbox>
            )}
            <FormikCheckbox name="subscribe" {...generateQaAttr('subscribe-checkbox')}>
              {props.blok.subscriptionCopy}
            </FormikCheckbox>

            {props.blok?.consent_conditions
              ?.filter((v) => !!v.id)
              ?.map((condition) => (
                <ConsentConditionCheckbox
                  key={condition._uid}
                  name={condition.id}
                  {...generateQaAttr(`${condition.id}-checkbox`)}
                  wrapLabel={false}
                >
                  <span
                    className="condition"
                    dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(condition.description) }}
                  />
                </ConsentConditionCheckbox>
              ))}

            <div className="submit-container">
              <Button type="submit" disabled={!!loading || !formik.isValid || !isUnregistered}>
                <span className={loading ? 'loading' : ''}>{props.blok.register_submit_button_copy}</span>
                {loading ? <Sync className="spinner" /> : null}
              </Button>

              {isRegistered && (
                <ErrorMessage>
                  This email address already exists in our system.{' '}
                  <span className="action" onClick={() => props.setView('login')}>
                    Log in here
                  </span>{' '}
                  with your email and password.
                </ErrorMessage>
              )}

              <p>{props.blok.register_submit_button_subtext}</p>
            </div>
          </StyledForm>
        )}
      </Formik>
    </Fragment>
  );
};

export default RegisterForm;

import classNames from 'classnames';
import { FC } from 'react';

interface Props {
  className?: string;
  name: string;
  required: boolean;
  type?: string;
  label: string;
  error: any;
  handleChange: any;
  value: string;
  touched?: boolean;
  handleBlur?: any;
}

const TwInput: FC<Props> = ({
  className,
  error,
  name,
  required,
  type,
  label,
  handleChange,
  value,
  touched,
  handleBlur,
}) => {
  return (
    <div
      className={classNames(
        className,
        'relative isolate rounded-md border bg-white py-2 px-3 shadow-sm focus-within:ring-1',
        {
          'border-slate-200 focus-within:border-slate-400 focus-within:ring-slate-400 hover:border-slate-400': !error,
        },
        {
          'border-red-400 ring-1 ring-red-400': error && touched,
        },
      )}
    >
      <input
        onChange={handleChange}
        onBlur={handleBlur}
        name={name}
        value={value}
        type={type}
        placeholder={label}
        className="peer form-input relative z-10 block h-10 w-full border-0 bg-transparent p-0 pt-3 text-base placeholder-transparent focus-within:outline-none focus:ring-0"
      />
      <label
        htmlFor={name}
        className="absolute left-3 top-2 z-0 text-xs font-medium transition-all peer-placeholder-shown:top-4 peer-placeholder-shown:text-base peer-placeholder-shown:text-slate-500 peer-autofill:top-2 peer-autofill:z-20 peer-focus:top-2 peer-focus:text-xs"
      >
        {label}
        {required && '*'}
      </label>
      {error && touched && <p className="absolute -bottom-5 text-xs text-ev-error text-red-400">{error}</p>}
    </div>
  );
};

export default TwInput;

import styled from 'styled-components';

export const StyledTextInput = styled.div`
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  label {
    padding-bottom: 2px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.024em;

    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
  }

  input {
    box-sizing: border-box;
    height: 48px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    border-radius: 0px;
    padding: 8px;
    color: #002b39;
    font-family: 'Mulish';
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.012em;

    &.error {
      border-color: #cc0000;
    }

    &[disabled] {
      color: #aaaaaa;
    }

    @media screen and (max-width: 425px) {
      height: auto;
      font-size: 20px;
      font-weight: 300;
      line-height: 1.2em;
    }
  }

  span {
    color: #cc0000;
    font-size: 14px;
    margin-top: 4px;
  }

  @media only screen and (min-width: 768px) {
    padding: 5px 10px 0;
    input {
      padding: 8px 18px;
    }
    &.half {
      flex-basis: 50%;
    }

    &.quarter {
      flex-basis: 25%;
    }

    &.quarter {
      flex-basis: 25%;
    }
  }
`;

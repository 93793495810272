import { gql } from '@apollo/client';

export default gql`
  mutation initiateOpenEnrollForAAA($input: AAAInitiateOpenEnrollInput!) {
    initiateOpenEnrollForAAA(input: $input) {
      membershipNumber
      promotionCode
      promotionRef
      promotionType
      success
    }
  }
`;

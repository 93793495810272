import Teaser from './Teaser';
import Feature from './Feature';
import Grid from './Grid';
import Placeholder from './Placeholder';
import DualLogoHeaderExtend from './DualLogoHeaderExtend';
import HeroExtend from './HeroExtend';
import PromoCodeEntryExtend from './PromoCodeEntryExtend';
import AAAMemberNumber from './AAAMemberNumber/AAAMemberNumber';
import ProgramBenefitsExtend from './ProgramBenefitsExtend';
import FastChargingExtend from './FastChargingExtend';
import List from './shared/List';
import ListItem from './shared/list_item';
import AppIcon from './MobileAppBannerExtend/app_icon';
import MobileAppBannerExtend from './MobileAppBannerExtend';
import MobileAppBannerExtend2 from './MobileAppBannerExtend2';
import ContainerExtend from './ContainerExtend';
import ErrorComponent from './Error';
import FAQExtend from './FAQExtend';
import Navi from './shared/Navi';
import PricingWidget from './PricingWidget';
import RegisterViewExtend from './RegisterViewExtend';
import RegisterForm from './RegisterForm';
import Sitemap from './shared/Footer/sitemap';
import SitemapLink from './shared/Footer/sitemap_link';
import Confirmation from './Confirmation';
import Step from './Confirmation/Step';
import HowToList from './HowToList';
import HowToListItem from './HowToList/ListItem';
import ConfirmationLogin from './ConfirmationLogin';
import AuthenticateWithUber from './AuthenticateWithUber';
import UberCallback from './UberCallback';
import NissanCallback from './NissanCallback';
import OpenEnrollmentButton from './OpenEnrollmentButton';
import SubaruHeroForm from './SubaruHeroForm';
import AlternatingStories from './AlternatingStories';
import Footnote from './Footnote';
import Banner from './Banner';
import FooterSimple from './FooterSimple';
import SingleLogoHeader from './SingleLogoHeader';
import SubaruRegisterForm from './SubaruRegisterForm';
import ConfirmationHero from './ConfirmationHero';
import ButtonLink from '@/components/ButtonLink';
import PromotionExpiredView from '@/components/PromotionExpiredView';
import Header from '@/components/Header';
import RichText from '@/components/RichText';
import PricingPlans from './PricingPlans';
import UberErrorMessage from '@/components/UberErrorMessage';
import FeatureSection from '@/components/FeatureSection';
import QmeritFootnote from '@/components/QmeritFootnote';
import SubaruHeroFormWithQmerit from './SubaruHeroFormWithQmerit';

const Components: any = {
  app_icon: AppIcon,
  confirmation: Confirmation,
  step: Step,
  container_extend: ContainerExtend,
  fast_charging_extend: FastChargingExtend,
  teaser: Teaser,
  error: ErrorComponent,
  faq_section_extend: FAQExtend,
  feature: Feature,
  grid: Grid,
  dual_logo_header_extend: DualLogoHeaderExtend,
  hero_extend: HeroExtend,
  promocode_entry_extend: PromoCodeEntryExtend,
  aaa_member_number: AAAMemberNumber,
  open_enrollment_button: OpenEnrollmentButton,
  program_benefits_extend: ProgramBenefitsExtend,
  list: List,
  list_item: ListItem,
  navi: Navi,
  mobile_app_banner_extend: MobileAppBannerExtend,
  mobile_app_banner_extend_2: MobileAppBannerExtend2,
  sitemap: Sitemap,
  sitemap_link: SitemapLink,
  register_form: RegisterForm,
  register_view_extend: RegisterViewExtend,
  how_to_list: HowToList,
  how_to_list_item: HowToListItem,
  confirmation_login: ConfirmationLogin,
  pricing_widget: PricingWidget,
  authenticate_with_uber: AuthenticateWithUber,
  uber_callback: UberCallback,
  nissan_callback: NissanCallback,
  subaru_hero_form: SubaruHeroForm,
  alternating_stories: AlternatingStories,
  footnote: Footnote,
  banner: Banner,
  footer_simple: FooterSimple,
  single_logo_header: SingleLogoHeader,
  subaru_register_form: SubaruRegisterForm,
  confirmation_hero: ConfirmationHero,
  button_link: ButtonLink,
  promotion_expired_view: PromotionExpiredView,
  header: Header,
  rich_text: RichText,
  pricing_plans: PricingPlans,
  uber_error_message: UberErrorMessage,
  feature_section: FeatureSection,
  qmerit_footnote: QmeritFootnote,
  subaru_hero_form_with_qmerit: SubaruHeroFormWithQmerit,
};

const DynamicComponent = (props: any) => {
  const Component = Components[props.blok.component];
  return Component ? <Component {...props} /> : <Placeholder componentName={props.blok.component} />;
};

export default DynamicComponent;

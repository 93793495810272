import React from 'react';
import SbEditable from 'storyblok-react';
import { Asset, BaseBlok } from '../../../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../../../src/helpers/qa-attribute';

export interface StepBlok extends BaseBlok {
  header: string;
  image: Asset;
  subtext: string;
}

interface StepProps {
  index: number;
  blok: StepBlok;
}

export default function Step(props: StepProps) {
  return (
    <SbEditable content={props.blok}>
      <li {...generateQaAttrFromBlok(props.blok)}>
        <p className="step-header">{props.blok.header}</p>
        <img className="step-image" alt={props.blok.image.alt} src={props.blok.image.filename} />
        <p>{props.blok.subtext}</p>
      </li>
    </SbEditable>
  );
}

import React, { Fragment } from 'react';
import SbEditable from 'storyblok-react';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import {
  BenefitImage,
  Container,
  ContentContainer,
  ItemColumn,
  ListIcon,
  ListItem,
  Title,
} from './ProgramBenefitsExtend.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface Item extends BaseBlok {
  text: string;
}

interface ProgramBenefitsExtendProps {
  blok: BaseBlok & {
    image: Asset;
    items: Item[];
    title: string;
    list_item_icon: Asset;
    background_color: string;
    image_full_width: boolean;
  };
}

/**
 * Program Benefits component
 * @returns {Object}
 */
export default function ProgramBenefitsExtend(props: ProgramBenefitsExtendProps) {
  const {
    items,
    list_item_icon: { filename: icon },
    background_color,
    title,
    image,
    image_full_width,
  } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <section style={{ backgroundColor: background_color }} {...generateQaAttrFromBlok(props.blok)}>
        <Fragment>
          <Container>
            {title && <Title>{title}</Title>}
            <ContentContainer>
              <ItemColumn hasImage={image?.filename !== null}>
                {items &&
                  items.map(({ _uid, text }) => (
                    <ListItem key={_uid}>
                      <ListIcon src={icon} />
                      {text}
                    </ListItem>
                  ))}
              </ItemColumn>
              {image?.filename && <BenefitImage alt={image.alt} src={image?.filename} fullWidth={image_full_width} />}
            </ContentContainer>
          </Container>
        </Fragment>
      </section>
    </SbEditable>
  );
}

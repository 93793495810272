import SbEditable from 'storyblok-react';
import { BaseBlok } from '../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../src/helpers/qa-attribute';

interface TeaserProps {
  blok: BaseBlok & {
    headline: string;
  };
}

export default function Teaser({ blok }: TeaserProps) {
  return (
    <SbEditable content={blok}>
      <div className="mb-6 py-8" {...generateQaAttrFromBlok(blok)}>
        <h2 className="text-center text-5xl font-bold"> {blok.headline} </h2>
      </div>
    </SbEditable>
  );
}

import { useQuery } from '@apollo/client';
import { Box, CircularProgress } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { useCallback, useEffect, useState } from 'react';

import { useConfig, useErrorPage, usePromoCodeDetailsFromQuery } from '../../lib/hooks';
import { PromoCodeDetails } from '../../lib/interfaces';
import validateNissanRdr from '../../src/apollo/queries/nissan/validateNissanRdr';
import { validatePromoCode as validatePromoCodeQuery } from '../../src/apollo/queries/promotionCodes';
import switchLambdaService from '../../src/helpers/switchLambdaService';
import { getFailedCodeValidationMessage } from '../PromoCodeEntryExtend';

import _ from 'lodash';
import { extractErrorCode } from '../../src/helpers/extractErrorCode';

export default function NissanCallback() {
  const errorPage = useErrorPage();
  const router = useRouter();
  const promoCodeDetails = usePromoCodeDetailsFromQuery();
  const config = useConfig();
  const [validated, setValidated] = useState(false);

  const { refetch: validatePromoCode } = useQuery(validatePromoCodeQuery, {
    variables: {
      codeInput: {
        promotionCode: promoCodeDetails?.promotionCode,
        promotionRef: config.promotion.ref,
        DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
      },
    },
    skip: true,
  });

  const { refetch: validateRdr } = useQuery(validateNissanRdr, {
    variables: {
      input: {
        promoCode: promoCodeDetails?.promotionCode,
      },
    },
    skip: true,
  });

  const redirectSuccess = useCallback(
    ({ form, promotionCode, promotionType }: { form: string; promotionCode: string; promotionType: string }) => {
      router.push({
        pathname: `/register`,
        query: { promotionCode, promotionType, form, portal: 'nissan' },
      });
    },
    [router],
  );

  useEffect(() => {
    if (validated) return;
    const validate = async () => {
      setValidated(true);
      try {
        await validatePromoCode();
        const rdrData = await validateRdr();
        const { form } = rdrData.data.validateNissanRdr;

        redirectSuccess({ form, ...(promoCodeDetails as PromoCodeDetails) });
      } catch (error) {
        const message = getFailedCodeValidationMessage(error as Error);
        if (message) return errorPage(extractErrorCode(error), message);
        errorPage(extractErrorCode(error), _.get(error, 'message', 'Unknown'));
      }
    };
    validate();
  }, [errorPage, promoCodeDetails, validated, validateRdr, validatePromoCode, redirectSuccess]);

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', my: 18 }}>
      <CircularProgress />
    </Box>
  );
}

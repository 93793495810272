import { RetailPlanName, TouPricingRangeType } from '../types';

export function filterDuplicateRegions(regions: any, propertyFilter: any) {
  const duplicateRegion = new Set();
  return regions.filter((item: any) => {
    const isDuplicate = duplicateRegion.has(item[propertyFilter]);
    duplicateRegion.add(item[propertyFilter]);
    return !isDuplicate;
  });
}

export function filteredRegionsWithRange(regions: any) {
  return regions.filter((item: any) => item.touPricingByRange.length > 1);
}

export const renderFeesText = (fee: any, feeType: string, perText: string, isMobile: boolean) => {
  if (isMobile) {
    return fee === 0 ? 'none' : `$${fee}`;
  }
  return fee === 0 ? `No ${feeType} fees` : `$${fee}/${perText}`;
};

export const renderRetailPlanName = (altId: string): RetailPlanName => {
  switch (altId) {
    case '2c6a1924-f29a-44d5-99b4-2a50a7979c50':
      return 'Pay As You Go';
    case '8c1c29f1-1027-40f5-87af-d7218229be0b':
      return 'Member';
    case '9864a664-6ca0-4bdf-bc9f-ef2b1bc703f1':
      return 'EVgo Plus™️';
    case 'be7add8f-8da7-4469-8b1f-f29067c93228':
      return 'EVgo PlusMax';
    case '4d9a500a-89eb-463d-85eb-732c2fc00598':
      return 'EVgo Basic';
    default: {
      throw new Error(`Retail Plan Name not found for altId: ${altId}`);
    }
  }
};

const BASE_URL = `https://pricing-widgets-evgo.s3.us-east-2.amazonaws.com`;

async function apiClient(query: string) {
  const res = await fetch(`${BASE_URL}/${query}`);
  return await res.json();
}

export async function fetchRetailPlans(regionId: number) {
  return await apiClient(`retail/region-id-${regionId}.json`);
}

export async function fetchRegions() {
  return await apiClient('regions/regions.json');
}

type TiersPick = Pick<TouPricingRangeType, 'priceTier' | 'priceTierSchedule'>;

type PriceTier = 'Off-Peak' | 'On-Peak' | 'Early Bird';

type NewTier = {
  priceTier: PriceTier | string;
  start: number;
  end: number;
  startStandard: string;
  endStandard: string;
};

function convertTime12to24(time12h: string) {
  const meridian = time12h.slice(-2) as 'AM' | 'PM';
  let hour = parseInt(time12h, 10);

  if (hour === 12) {
    hour = 0;
  }

  if (meridian === 'PM') {
    hour = hour + 12;
  }
  return hour;
}

function compareStartTime(a: NewTier, b: NewTier) {
  if (a.start < b.start) {
    return -1;
  }
  if (a.start > b.start) {
    return 1;
  }
  return 0;
}

export function createPriceTierSchedule(tiers: Array<TiersPick>) {
  const newTiers: Array<NewTier> = [];
  tiers.forEach(({ priceTier, priceTierSchedule }) => {
    const tempTier: Array<NewTier> = [];
    priceTierSchedule.forEach((schedule) => {
      const timeString = (position: number) => schedule.split('-')[position];
      const startHour = convertTime12to24(timeString(0));
      const endHour = convertTime12to24(timeString(1));

      tempTier.push({
        priceTier,
        start: startHour,
        end: endHour,
        startStandard: timeString(0),
        endStandard: timeString(1),
      });
    });
    newTiers.push(...tempTier);
  });

  return newTiers.sort(compareStartTime);
}

import Head from 'next/head';
import Layout from '../components/Layout';
import Page from '../components/Page';
import { BaseBlok } from '../lib/storyblok.interfaces';

// The Storyblok Client
import useStoryblok from '../lib/storyblok-hook';
import { useThemeClass } from '@/lib/hooks';

interface HomeProps {
  story: any;
  footer: any;
  theme?: any;
}

export default function Home(props: HomeProps) {
  // the Storyblok hook to enable live updates
  const story: any = useStoryblok(props.story);

  const theme = useThemeClass(props.theme.data?.story.content.theme);

  // fallback to the title of the Hero Extend component as the page title.
  const pageTitle = story.content.page_title;
  const findHeroExtend = (bloks: BaseBlok) => bloks.component === 'hero_extend';
  const heroExtend = story.content.body.find(findHeroExtend);

  return (
    <>
      <Head>
        <title>{pageTitle || heroExtend?.title}</title>
      </Head>
      <Layout className={theme}>
        <Page content={story.content} footer={props.footer.content} />
      </Layout>
    </>
  );
}

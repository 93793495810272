import { Asset, BaseBlok } from '@/lib/storyblok.interfaces';
import { FC } from 'react';
import SbEditable from 'storyblok-react';

interface Props {
  blok: BaseBlok & {
    logo: Asset;
  };
}

const SingleLogoHeader: FC<Props> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <header className="flex justify-center px-11 py-10">
        <img src={blok.logo.filename} alt={blok.logo.alt} />
      </header>
    </SbEditable>
  );
};

export default SingleLogoHeader;

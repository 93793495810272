import { FC } from 'react';
import SbEditable from 'storyblok-react';
import VinForm from './VinForm';
import { BaseBlok, Asset } from '@/lib/storyblok.interfaces';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

interface HeroProps {
  blok: BaseBlok & {
    hero_img: Asset;
    title: string;
    promotion_text: StoryblokRichtext;
  };
}

const SubaruHeroForm: FC<HeroProps> = ({ blok }) => {
  const { title, hero_img } = blok;

  return (
    <SbEditable content={blok}>
      <div className="relative flex items-center justify-center px-10 py-20 lg:min-h-[500px]">
        <img className="absolute inset-0 h-full w-full object-cover" src={hero_img?.filename} alt={hero_img?.alt} />
        <h1 className="relative text-center text-3xl font-bold text-white lg:text-5xl">{title}</h1>
      </div>
      <VinForm />
    </SbEditable>
  );
};

export default SubaruHeroForm;

import { BaseBlok } from '@/lib/storyblok.interfaces';
import { FC, Fragment } from 'react';
import SbEditable from 'storyblok-react';
import Story from './Story';
import { StoryType } from './types';

type Props = {
  blok: BaseBlok & {
    title: string;
    stories: StoryType[];
  };
};

const AlternatingStories: FC<Props> = ({ blok }) => {
  return (
    <SbEditable content={blok}>
      <div className="mx-auto my-16 w-full lg:max-w-5xl">
        <h2 className="text-center text-2xl font-bold lg:text-3xl">{blok.title}</h2>
        <div className="flex flex-col gap-6 md:flex-row md:gap-0">
          {blok.stories.map((story, index) => {
            return (
              <Fragment key={index}>
                <Story story={story} />
                {index === 0 && (
                  <div className="flex justify-center md:mt-36">
                    <span className="bg-white px-4 text-4xl font-bold text-theme-base">or</span>
                  </div>
                )}
              </Fragment>
            );
          })}
        </div>
      </div>
    </SbEditable>
  );
};

export default AlternatingStories;

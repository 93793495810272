import { ChangeEvent, useState } from 'react';
import _ from 'lodash';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import SbEditable from 'storyblok-react';
import { useMutation } from '@apollo/client';

import { BaseBlok } from '../../lib/storyblok.interfaces';
import Button from '../Controls/Button';
import Spinner from '../shared/Spinner';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import { useConfig, useErrorPage } from '../../lib/hooks';
import { initiateOpenEnrollForAAA } from '../../src/apollo/queries/promotionCodes';

const MemberNumberForm = styled.form`
  font-family: 'Mulish';
  width: 100%;
`;

const HeadingText = styled.h3`
  color: #707070;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const SearchContainer = styled.div`
  display: flex;
  margin-bottom: 4px;

  @media only screen and (max-width: 768px) {
    display: block;
  }
`;

const MemberNumberInput = styled.input`
  border: 1px solid #707070;
  font-size: 20px;
  font-weight: 300;
  padding: 10px 18px;
  width: 100%;

  @media only screen and (max-width: 768px) {
    margin: 0 0 8px;
  }
`;

const FormButton = styled(Button)<Props>`
  position: relative;
  margin: 0 0 0 8px;
  padding: 10px 18px;
  height: 48px;

  min-width: 200px;
  font-family: 'Mulish';
  font-weight: bold;
  font-size: 18px;

  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

const ErrorMessage = styled.div`
  color: rgb(204, 0, 0);
  font-size: 14px;
  margin-top: 4px;
`;

type Props = {
  blok: BaseBlok & {
    heading_text: string;
    placeholder_text: string;
    button_text: string;
    button_square: boolean;
    error_text: string;
  };
};

export default function AAAMemberNumber({ blok }: Props) {
  const { heading_text, placeholder_text, button_text, error_text } = blok;
  const [membershipNumber, setMemberNumber] = useState('');
  const [error, setError] = useState('');
  const router = useRouter();
  const errorPage = useErrorPage();
  const config = useConfig();
  const [isLoading, setIsLoading] = useState(false);

  const [initiateEnrollment] = useMutation(initiateOpenEnrollForAAA, {
    onCompleted(res) {
      const { promotionCode, promotionType, success } = _.get(res, 'initiateOpenEnrollForAAA', {});

      if (!success) {
        errorPage('Unknown', 'Error validating initiating enroll, please try again.');
        return;
      }

      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;

      router.push({
        pathname: `/register`,
        query: { promotionCode, promotionType },
      });
    },
    onError(err) {
      errorPage(_.get(err, 'code', 'Unknown'), _.get(err, 'message', 'Unknown'));
      setIsLoading(false);
    },
  });

  async function onSubmit(event: any) {
    event.preventDefault();

    if (!validateUserInput(membershipNumber)) return;

    setIsLoading(true);
    await initiateEnrollment({
      variables: {
        input: {
          membershipNumber,
          promotionRef: config.promotion.ref,
        },
      },
    });
  }

  function onValueChange({ target: { value } }: ChangeEvent<HTMLInputElement>) {
    setMemberNumber(value);
    validateUserInput(value);
  }

  /**
   * For now, we are pseudo-validating a AAA member number.
   */
  function validateUserInput(input: string) {
    input.trim();

    const isEmpty = !input;
    const isNotNumeric = !Number(input);
    const isNotCorrectLength = input.length !== 16;

    if (isEmpty || isNotNumeric || isNotCorrectLength) {
      setError(error_text);
      return false;
    } else {
      setError('');
      return true;
    }
  }

  return (
    <SbEditable content={blok}>
      <MemberNumberForm {...generateQaAttrFromBlok(blok)}>
        <HeadingText>{heading_text}</HeadingText>
        <SearchContainer>
          <MemberNumberInput
            placeholder={placeholder_text}
            value={membershipNumber}
            onChange={onValueChange}
            maxLength={16}
          />
          <FormButton blok={blok} disabled={isLoading || !!error} onClick={onSubmit}>
            {isLoading ? <Spinner /> : button_text}
          </FormButton>
        </SearchContainer>
        <ErrorMessage>{error}</ErrorMessage>
      </MemberNumberForm>
    </SbEditable>
  );
}

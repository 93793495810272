import { ReactNode } from 'react';
import { render, StoryblokRichtext, NODE_UL, MARK_LINK } from 'storyblok-rich-text-react-renderer';
import Link from 'next/link';

export const renderRichText = (document: StoryblokRichtext) => {
  return render(document, {
    nodeResolvers: {
      [NODE_UL]: (children: ReactNode) => <ul className="list-outside list-disc space-y-4 [&_p]:inline">{children}</ul>,
    },
    markResolvers: {
      [MARK_LINK]: (children, { linktype, href, target }) => {
        if (linktype === 'email') {
          return <a href={`mailto:${href}`}>{children}</a>;
        }
        if (href?.match(/^(https?:)?\/\//)) {
          // External links: map to <a>
          return (
            <a href={href} target={target}>
              {children}
            </a>
          );
        }
        // Internal links: map to <Link>
        // Need to convert href type to type URL to make it work with Next.js
        const newHref = href as unknown as URL;
        return (
          <Link href={newHref}>
            <a>{children}</a>
          </Link>
        );
      },
    },
  });
};

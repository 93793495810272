import styled from 'styled-components';

export const StyledDropdown = styled.div`
  position: relative;
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  label {
    padding-bottom: 2px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.024em;

    @media only screen and (min-width: 768px) {
      font-size: 20px;
    }
  }

  input {
    box-sizing: border-box;
    height: 48px;
    border: 2px solid rgba(0, 0, 0, 0.25);
    padding: 10px;
    color: #666666;
    font-size: 20px;
    cursor: pointer;

    &[disabled] {
      background-color: #cccccc;
      color: #aaaaaa;
    }

    &:focus {
      border-radius: 4px;
    }
  }

  input.error {
    border: 2px solid red;
  }

  span {
    color: #cc0000;
    font-size: 14px;
  }

  ul {
    position: absolute;
    top: 65px;
    display: none;
    box-sizing: border-box;
    width: 100%;
    border-top: 1px solid #aaaaaa;
    background-color: white;
    height: calc(44px * 3);
    overflow-y: auto;
    list-style: none;
    padding: 0;
    border-radius: 6px;
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.2), 0px 4px 6px -2px rgba(0, 0, 0, 0.05),
      0px 0px 0px 1px rgba(0, 0, 0, 0.05);

    li {
      box-sizing: border-box;
      padding: 10px 20px;
      color: #666666;
      font-size: 20px;
      cursor: pointer;

      &:hover {
        background-color: #cccccc;
      }
    }
  }

  &.open {
    z-index: 1000;

    ul {
      display: block;
    }

    .arrow {
      transform: rotate(-135deg);
    }
  }

  .arrow {
    position: absolute;
    top: calc(50% - 2px);
    right: 30px;
    display: inline-block;
    border: solid #666666;
    border-width: 0 4px 4px 0;
    padding: 4px;
    transform: rotate(45deg);
    transform-origin: 8.5px 8.5px;
    transition: all 0.2s ease;
    cursor: pointer;
  }

  @media screen and (max-width: 425px) {
    input {
      height: auto;
      font-size: 20px;
      line-height: 1.2em;
    }
    &::after {
      top: 18px;
      right: 30px;
    }
    &.open {
      &::after {
        transform: rotate(-135deg);
        top: 15px;
      }
    }
    ul {
      top: 48px;
      margin-left: 0;
    }
  }

  @media only screen and (min-width: 480px) {
    &::after {
      right: 30px;
    }
  }

  @media only screen and (min-width: 768px) {
    padding: 5px 10px 0;
    input {
      padding: 10px 20px;
    }
    &.half {
      flex-basis: 50%;
    }

    &.quarter {
      flex-basis: 25%;
    }

    ul {
      width: calc(100% - 20px);
    }
  }
`;

import { useState } from 'react';
import { useFormik } from 'formik';
import { generateQaAttr } from 'src/helpers/qa-attribute';
import TwInput from '@/components/shared/TwInput';
import Spinner from '@/components/shared/Spinner';
import ErrorMessage from '@/components/shared/ErrorMessage';
import { useLazyQuery, useMutation } from '@apollo/client';
import { checkPromoCodeRedemptionByDisplayValue, initiateCustomOpenEnroll } from 'src/apollo/queries/promotionCodes';
import { useConfig } from '@/lib/hooks';
import apiClient from 'src/helpers/apiClient';
import { object, string } from 'yup';
import { useRouter } from 'next/router';

type VinValues = {
  vin: string;
};

const VinForm = () => {
  const router = useRouter();
  const { conf, date } = router.query;
  const [vin, setVin] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const config = useConfig();

  const [checkRedemptionStatus] = useLazyQuery(checkPromoCodeRedemptionByDisplayValue);

  const [initiateEnrollment] = useMutation(initiateCustomOpenEnroll, {
    onCompleted({ initiateCustomOpenEnroll }) {
      const { promotionCode, promotionType, success } = initiateCustomOpenEnroll;
      if (!success) {
        setError('There was an error with redeeming your promotion. Please try again later.');
        return;
      }
      router.push({
        pathname: `/register`,
        query: { promotionCode, promotionType, displayValue: vin },
      });
    },
    onError(err) {
      console.log(err);
      setError('There was an error with redeeming your promotion. Please try again later.');
    },
  });

  const formik = useFormik<VinValues>({
    initialValues: { vin: '' },
    validationSchema: object().shape({
      vin: string().required('VIN is required'),
    }),
    onSubmit: async (values: VinValues) => {
      setIsSubmitting(true);
      try {
        const sanitizedVin = values.vin.toUpperCase().trim();
        setVin(sanitizedVin);

        const res = await apiClient({
          url: '/api/validate-vin',
          method: 'POST',
          body: {
            conf,
            date,
            vin: sanitizedVin,
          },
        });

        const data = await res.json();

        if (!res.ok) {
          setError(data.error.message);
          return null;
        }

        // Check redemption status in EVgo
        const { data: evgoRedemptionStatus } = await checkRedemptionStatus({
          variables: {
            input: {
              promotionRef: config.promotion.ref,
              groupName: config.promotion.campaign,
              displayValue: sanitizedVin,
            },
          },
        });

        const isRedeemed = evgoRedemptionStatus.checkPromoCodeRedemptionByDisplayValue.hasBeenRedeemed;

        if (isRedeemed) {
          setError('This VIN has already been redeemed');
        } else if (!isRedeemed) {
          await initiateEnrollment({
            variables: {
              input: {
                customDisplayValue: sanitizedVin,
                enforceUniqueDisplayValue: true,
                groupName: config.promotion.campaign,
                promotionRef: config.promotion.ref,
              },
            },
          });
          setError(null);
        }
      } catch (e) {
        console.log(e);
        setError('There was an error with redeeming your promotion. Please try again later.');
      } finally {
        setIsSubmitting(false);
      }
    },
  });

  return (
    <div className="relative mx-auto w-full bg-[#F9FAFB] p-10 text-center shadow-lg lg:-mt-10 lg:max-w-5xl lg:rounded-lg">
      <h2 className="text-2xl font-bold lg:text-3xl">Select Charging Credit</h2>
      <form onSubmit={formik.handleSubmit} className="mx-auto mt-8 max-w-4xl">
        <TwInput
          type="text"
          handleChange={formik.handleChange}
          value={formik.values.vin}
          name="vin"
          required
          label="Please enter your VIN"
          error={formik.errors.vin}
          touched={formik.touched.vin}
          {...generateQaAttr('vin')}
        />
        <p className="mt-4 text-sm text-theme-secondary">
          *Your VIN is included in the email that directed you to this page
        </p>
        {error && <ErrorMessage errorMessage={error} className="mt-10" />}
        <button
          type="submit"
          className="mx-auto mt-10 flex h-12 w-44 items-center justify-center rounded-full bg-theme-btn-fill px-4 font-bold text-theme-btn hover:bg-opacity-80"
        >
          {isSubmitting ? <Spinner /> : 'Get Started'}
        </button>
      </form>
    </div>
  );
};

export default VinForm;

import React from 'react';
import { useRouter } from 'next/router';
import Home from '../../components/Home';
import { buildPath } from '../../src/path/buildPath';

// The Storyblok Client
import Storyblok from '../../lib/storyblok';
import { convertStoryblokContentToConfig } from '../../lib/config';

export default function HomePage(props) {
  const router = useRouter();

  return <Home {...props} key={router.asPath} />;
}

export async function getStaticProps(context) {
  const { host, slug } = context.params;
  const [subdomain] = host.split('.');
  const path = slug === 'favicon.ico' || slug === 'favicon.png' ? slug : buildPath(host, slug);

  // the slug of the story
  // the storyblok params
  const params = {
    version: process.env.STORYBLOK_VERSION,
    language: context.locale,
    resolve_relations: ['pricing_plans.plans', 'pricing_plans.partner_plans'],
  };

  // checks if Next.js is in preview mode
  if (context.preview) {
    // loads the draft version
    params.version = process.env.STORYBLOK_VERSION;
    // appends the cache version to get the latest content
    params.cv = Date.now();
  }

  let storyData;
  let footerData;
  let config;
  try {
    // loads the story from the Storyblok API
    const [{ data: initialStory }, { data: initialFooter }, initialConfig] = await Promise.all([
      Storyblok.get(`cdn/stories/${path}`, params),
      Storyblok.get('cdn/stories/footer', params),
      Storyblok.get(`cdn/stories/${subdomain}/reportal-config`, params),
    ]);
    storyData = initialStory;
    footerData = initialFooter;
    config = initialConfig;
  } catch (e) {
    console.log(e);
    return {
      notFound: true,
    };
  }

  // TODO move this to promise when themes are integrated to all portals.
  // If the theme is not found, Promise will fail and cause 404 error on build.
  const theme = await Storyblok.get(`cdn/stories/${subdomain}/theme`, params).catch(() => {
    return '';
  });

  // return the story from Storyblok and whether preview mode is active
  return {
    // TODO look into if ISR should be used
    revalidate: parseInt(process.env.SSG_CACHE_IN_SECONDS),
    props: {
      story: storyData?.story || false,
      footer: footerData?.story || false,
      config: config?.data?.story ? convertStoryblokContentToConfig(config.data.story) : {},
      preview: context.preview || false,
      theme,
    },
  };
}

export async function getStaticPaths() {
  const data = await Storyblok.get(`cdn/stories/`, {
    content_type: 'page',
  });

  const paths = data.data.stories.map((story) => {
    return { params: { host: story.full_slug.split('/')[0], slug: story.full_slug.split('/')[1] } };
  });

  return {
    paths,
    fallback: 'blocking',
  };
}

export const name = 'EVgo - Reliant Promotion ';
export const shortName = 'Reliant Promotion';
export const description = 'Register with EVgo and get hours of free charging on our network.';
export const brandColor = '#002B39';
export const apiHost = process.env.API_HOST || '//localhost:4000/';
export const basename = process.env.BASENAME || '/';
export const noscript = 'You need to enable JavaScript to run this application.';

export const colors = {
  accentBlue: '#27657E',
  accentFaq: '#008ABD',
  accentLink: '#1FA3FF',
  accentYellow: '#FFC425',
  bismarkBlue: '#426F85',
};

export const support = {
  phone: '877-494-3833',
  email: 'support@evgo.com',
};

export const vehicleInfo = {
  make: '',
  model: '',
  year: '',
};

export const authServiceDomain = process.env.NEXT_PUBLIC_AUTH_SERVICE_DOMAIN || '';

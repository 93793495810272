import styled from 'styled-components';
import SbEditable from 'storyblok-react';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import BaseButton from '../Controls/Button';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useRouter } from 'next/router';

const HeadingText = styled.h3`
  color: #707070;
  font-size: 20px;
  font-weight: 400;
  margin-bottom: 16px;
`;

const Button = styled(BaseButton)<Props>`
  padding: 10px 18px;
  min-width: 200px;
  height: initial;
  width: initial;
  &:disabled {
    opacity: 0.5;
    cursor: default;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
    margin: 0;
  }
`;

type Props = {
  blok: BaseBlok & {
    heading_text: string;
    button_text: string;
    button_square: boolean;
  };
};

export default function AuthenticateWithUber({ blok }: Props) {
  const { heading_text, button_text } = blok;
  const router = useRouter();

  useEffect(() => {
    TagManager.initialize({ gtmId: process.env.NEXT_PUBLIC_GTM_ID || '' });
  }, []);

  const onClick = () => {
    const clientId = process.env.NEXT_PUBLIC_UBER_CLIENT_ID;
    const redirectUrl =
      router.locale === 'es' ? process.env.NEXT_PUBLIC_UBER_REDIRECT_ES_URL : process.env.NEXT_PUBLIC_UBER_REDIRECT_URL;
    const uberAuthorizeUrl = 'https://login.uber.com/oauth/v2/authorize';

    if (!clientId) {
      console.error('Missing NEXT_PUBLIC_UBER_CLIENT_ID environment variable.');
      return;
    }
    if (!redirectUrl) {
      console.error('Missing NEXT_PUBLIC_UBER_REDIRECT_URL environment variable.');
      return;
    }
    window.location.href = `${uberAuthorizeUrl}?response_type=code&client_id=${clientId}&scope=partner.rewards&redirect_uri=${redirectUrl}`;
  };

  return (
    <SbEditable content={blok}>
      <div {...generateQaAttrFromBlok(blok)}>
        <HeadingText>{heading_text}</HeadingText>
        <Button blok={blok} onClick={onClick}>
          {button_text}
        </Button>
      </div>
    </SbEditable>
  );
}

import classNames from 'classnames';
import { FC } from 'react';
import { StoryType } from './types';
import { renderRichText } from '../../src/helpers/rich-text-render';

type Props = {
  story: StoryType;
};

const Story: FC<Props> = ({ story: { title, description, img } }) => {
  return (
    <div className="flex flex-1 flex-col rounded-lg bg-[#F9FAFB] p-10 md:bg-transparent">
      <img className={classNames('w-full self-start rounded-lg')} src={img.filename} alt={img.alt} />
      <div
        className={classNames(
          'flex flex-col gap-y-6 py-10 text-lg lg:px-6 [&_a]:text-theme-link hover:[&_a]:underline',
        )}
      >
        <h3 className="text-2xl font-bold lg:text-3xl">{title}</h3>
        {renderRichText(description)}
      </div>
    </div>
  );
};

export default Story;

import _ from 'lodash';
import * as Yup from 'yup';
import styled from 'styled-components';
import { Richtext } from 'storyblok-js-client';
import { fetchSignInMethodsForEmail } from 'firebase/auth';
import { auth } from '../../lib/firebase';
import FormikCheckbox from '../shared/FormikCheckbox';
import { colors } from '../../config';

export enum EmailStatus {
  registered,
  unregistered,
  pendingValidation,
}

export interface ConsentCondition {
  _uid: string;
  id: string;
  required: boolean;
  description: Richtext;
  default_value: boolean;
}

export const isEmailRegistered = _.memoize(async function (email: string) {
  try {
    const methods = await fetchSignInMethodsForEmail(auth, email);
    return methods.length > 0;
  } catch (e) {
    return false;
  }
});

export const ErrorMessage = styled.p`
  color: #cc0000;
  font-size: 14px;

  .action {
    color: inherit;
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const ConsentConditionCheckbox = styled(FormikCheckbox)`
  .condition {
    p {
      margin: 0;
      padding: 0;
    }

    a {
      color: ${colors.accentLink};
      text-decoration: underline;
    }
  }
`;

export function getConsentConditionsDefaultValues(consentConditions: ConsentCondition[]) {
  return consentConditions.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.id]: currentValue.default_value,
    }),
    {},
  );
}

export function createConsentConditionsValidation(consentConditions: ConsentCondition[]) {
  return consentConditions.reduce(
    (previousValue, currentValue) => ({
      ...previousValue,
      [currentValue.id]: currentValue.required ? Yup.boolean().oneOf([true], 'Required') : Yup.boolean(),
    }),
    {},
  );
}

export function getOmittableConsentConditionsKeys(consentConditions: ConsentCondition[]) {
  return consentConditions.map((value) => value.id).filter((key) => !['terms', 'subscribe'].includes(key));
}

import * as Yup from 'yup';

export default Yup.object().shape({
  firstName: Yup.string()
    .strict(true)
    .trim('First name cannot include leading and trailing spaces')
    .test('len', 'First name be at least 2 characters', (val = '') => val.length >= 2)
    .matches(/(^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*)?(^[a-zA-Z][.])*$/, 'Please enter a valid first name')
    .required('Required'),
  lastName: Yup.string()
    .strict(true)
    .trim('Last name cannot include leading and trailing spaces')
    .test('len', 'Last name be at least 2 characters', (val = '') => val.length >= 2)
    .matches(/(^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*)?(^[a-zA-Z][.])*$/, 'Please enter a valid last name')
    .required('Required'),
  email: Yup.string()
    .strict(true)
    .trim('Email cannot include leading and trailing spaces')
    .email('Please enter a valid email address')
    .required('Required'),
  phone: Yup.string()
    .strict(true)
    .trim('Phone cannot include leading and trailing spaces')
    .matches(/^[0-9]{10,10}$/, 'Please enter a valid phone number')
    .required('Required'),
  address: Yup.string()
    .strict(true)
    .trim('Address cannot include leading and trailing spaces')
    .matches(/^(?=.*[A-Z])(?=.*[0-9])/i, 'Please enter a valid address')
    .required('Required'),
  city: Yup.string()
    .strict(true)
    .trim('City cannot include leading and trailing spaces')
    .matches(/^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/, 'Please enter a valid city name')
    .required('Required'),
  state: Yup.string().required('Required'),
  postalCode: Yup.string()
    .strict(true)
    .trim('Postal code cannot include leading and trailing spaces')
    .matches(/^[0-9]{5,5}$/, 'Please enter a valid zip code')
    .required('Required'),
  // make: Yup.string().required('Required'),
  // model: Yup.string().required('Required'),
  // year: Yup.string().required('Required'),
  password: Yup.string()
    .strict(true)
    .trim('Password cannot include leading and trailing spaces')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[`~!@#$%^&*()\-_=+[\]{}\\|:;'",<.>/?])(?=.{8,24}$)/,
      'Password requirements not met',
    )
    .required('Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  subscribe: Yup.boolean(),
  terms: Yup.boolean().oneOf([true], 'Required'),
});

import styled from 'styled-components';
import { colors } from '../../config';
interface ErrorProps {
  withBackground: boolean;
  textColor: string;
  fullWidth: boolean;
  image: string;
}

export const Section = styled.section`
  position: relative;
  width: ${({ fullWidth }: ErrorProps) => (fullWidth ? '100%' : '95%')};
  min-height: 80vh;
  margin: 0 auto;

  header {
    background-image: ${({ image }: ErrorProps) => `url(${image})`};
    background-repeat: no-repeat;
    background-size: cover;
    color: ${({ textColor }: ErrorProps) => (textColor ? textColor : 'inherit')};
    height: 100%;
    min-height: 650px;
    padding: 132px;
    text-align: center;

    h1 {
      margin: 0;
      font-size: 40px;
      line-height: 45px;
      font-weight: 700;
      letter-spacing: 0.03em;

      @media only screen and (min-width: 768px) {
        font-size: 36px;
      }
    }

    p {
      font-size: 20px;
      line-height: 25px;
      margin-bottom: 54px;
    }

    button {
      margin: 0 auto;
      height: 48px;
      font-size: 20px;
      line-height: 29px;
      font-weight: 700;
      padding-top: 8px;
      padding-bottom: 8px;
    }
  }

  @media only screen and (max-width: 768px) {
    header {
      background-size: fit;
      padding: 56px 21px;
      min-height: auto;

      h1 {
        font-size: 24px;
      }

      p {
        font-size: 16px;
      }

      button {
        margin: 0;
        width: 100%;
      }
    }
  }
`;

export const ErrorSection = styled.div`
  font-size: 1.25em;
  padding: 33px;
  text-align: center;

  @media only screen and (max-width: 768px) {
    font-size: 16px;
  }
`;

import { useState, useReducer, useEffect } from 'react';
import { fetchRegions, fetchRetailPlans, filterDuplicateRegions } from './utils';
import { RetailPlan, Region, PlanNames } from './types';

interface State {
  regionId: number | null;
  regions: Region[] | null;
  retailPlans:
    | {
        [planName in PlanNames]: RetailPlan;
      }
    | null;
  regionsWithTouRates: Region[] | null;
}

type Action =
  | {
      type: 'SELECT_REGION';
      regionId: number;
      retailPlans: State['retailPlans'];
    }
  | {
      type: `SET_INITIAL_DATA`;
      regionId: number;
      retailPlans: {
        [planName in PlanNames]: RetailPlan;
      };
      regions: Region[];
      regionsWithTouRates: Region[];
    };

const INITIAL_STATE: State = {
  regionId: null,
  regions: null,
  retailPlans: null,
  regionsWithTouRates: null,
};

const reducer = (state: State, action: Action) => {
  switch (action.type) {
    case 'SET_INITIAL_DATA': {
      return {
        regionId: action.regionId,
        retailPlans: action.retailPlans,
        regions: action.regions,
        regionsWithTouRates: action.regionsWithTouRates,
      };
    }
    case 'SELECT_REGION': {
      return {
        ...state,
        regionId: action.regionId,
        retailPlans: action.retailPlans,
      };
    }
    default:
      return state;
  }
};

interface UseEvgoPlansType {
  state: State;
  isLoading: boolean;
  error: any;
  getRetailPlans: (regionId: number) => void;
}

interface UseEvgoPlansConfig {
  defaultRegionId?: number;
}

export function useEvgoPlans(config?: UseEvgoPlansConfig): UseEvgoPlansType {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  // TODO setup error handling

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  async function getInitialData() {
    try {
      setIsLoading(true);
      const defaultRegion = config?.defaultRegionId || 1;

      const retailPlansRes = fetchRetailPlans(defaultRegion);
      const regionsRes = fetchRegions();
      const [{ member, payg, plus, plusmax, basic, uberPro }, regionsData] = await Promise.all([
        retailPlansRes,
        regionsRes,
      ]);

      const retailPlans = { member, payg, plus, plusmax, basic, uberPro };

      // Remove duplicate regions for regular dropdown
      const regions = filterDuplicateRegions(regionsData.listTouRegions, 'name').sort((a: Region, b: Region) =>
        a.name.localeCompare(b.name),
      );

      const { regionsWithTouRates } = regionsData;

      dispatch({
        type: 'SET_INITIAL_DATA',
        regions,
        regionsWithTouRates,
        retailPlans,
        regionId: defaultRegion,
      });
    } catch (e: any) {
      setError(e);
    } finally {
      setIsLoading(false);
    }
  }

  async function getRetailPlans(regionId: number) {
    try {
      setIsLoading(true);
      const { member, payg, plus, plusmax, basic, uberPro } = await fetchRetailPlans(regionId);
      const retailPlans = { member, payg, plus, plusmax, basic, uberPro };
      dispatch({ type: 'SELECT_REGION', regionId, retailPlans });
      setIsLoading(false);
    } catch (e: any) {
      setError(e);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getInitialData();
  }, []);

  return { state, isLoading: isLoading, error, getRetailPlans };
}

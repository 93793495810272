import { BaseBlok, Asset } from '@/lib/storyblok.interfaces';
import { renderRichText } from '@/src/helpers/rich-text-render';
import SbEditable from 'storyblok-react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';

type Props = {
  blok: BaseBlok & {
    title: string;
    image: Asset;
    description: StoryblokRichtext;
  };
};

export default function FeatureSection({ blok }: Props) {
  const { title, description, image } = blok;

  return (
    <SbEditable content={blok}>
      <div className="overflow-hidden bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="order-2 lg:order-1 lg:pr-8 lg:pt-4">
              <div className="lg:max-w-lg">
                <h2 className="mt-2 text-2xl font-bold tracking-tight lg:text-3xl">{title}</h2>
                <div className="mt-6 leading-8 text-theme-secondary [&>p]:my-5">{renderRichText(description)}</div>
              </div>
            </div>
            <img src={image.filename} alt={image.alt} className="lg:order:2 order-1 h-auto w-full" />
          </div>
        </div>
      </div>
    </SbEditable>
  );
}

import React, { Fragment } from 'react';
import DynamicComponent from '../DynamicComponent';
import SbEditable from 'storyblok-react';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import { List, Title } from './HowToList.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface HowToListProps {
  blok: BaseBlok & {
    title: string;
    images_enlarged: boolean;
    align_numbers_left: boolean;
    title_font_weight: number;
    list_items: BaseBlok[];
  };
}

export default function HowToList(props: HowToListProps) {
  const {
    images_enlarged: enlargeImages,
    align_numbers_left: alignNumbersStart,
    title_font_weight: fontWeight,
  } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <div {...generateQaAttrFromBlok(props.blok)}>
        <Title dangerouslySetInnerHTML={{ __html: props.blok.title }} fontWeight={fontWeight} />
        <List
          enlargeImages={enlargeImages}
          alignNumbersStart={alignNumbersStart}
          columns={props.blok.list_items.length}
        >
          {props.blok.list_items &&
            props.blok.list_items.map((blok, index) => <DynamicComponent key={index} blok={blok} />)}
        </List>
      </div>
    </SbEditable>
  );
}

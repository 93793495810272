import { gql } from '@apollo/client';

export default gql`
  query listPortalsVehicleModels($modelInput: GetPortalsVehicleModelInput) {
    listPortalsVehicleModels(input: $modelInput) {
      value: altId
      text: modelName
    }
  }
`;

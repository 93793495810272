// The Nissan Model that comes from the RDR is a 3 char string
// Query: getNissanRdr

export const NissanModelName: Record<string, string> = {
  LEF: 'LEAF',
  ARI: 'ARIYA',
};

// The Nissan Model Year that comes from the RDR is a 2 char string, for example: 19 represents 2019
// Query: getNissanRdr

export const getNissanModelYear = (shortYear: string, defaultYear = '2000'): string => {
  return shortYear ? `20${shortYear}` : defaultYear;
};

export const nissanVehicleModels = [
  { name: 'LEAF', value: 'LEAF' },
  { name: 'ARIYA', value: 'ARIYA' },
];

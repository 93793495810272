import classNames from 'classnames';
import { BaseBlok } from '@/lib/storyblok.interfaces';

export type ButtonLinkBlokValue = BaseBlok & {
  text: string;
  url: string;
  bg_color: string;
  text_color: string;
  rounded?: boolean;
  target?: string;
};

export type ButtonLinkProps = {
  className?: string;
  blok: ButtonLinkBlokValue;
};

export function ButtonLink({ blok, className }: ButtonLinkProps) {
  const { text, url, rounded, target, bg_color: backgroundColor, text_color: color } = blok;

  return (
    <a
      className={classNames(
        className,
        'relative flex h-12 min-w-[200px] cursor-pointer items-center justify-center border-0 px-4 text-lg font-bold no-underline',
        {
          'rounded-full': rounded,
          'rounded-none': !rounded,
        },
        'disabled:cursor-default disabled:opacity-50',
        {
          'text-black': !color,
          'bg-ev-yellow': !backgroundColor,
        },
      )}
      href={url}
      target={target || '_self'}
      style={{ color, backgroundColor }}
    >
      {text}
    </a>
  );
}

export default ButtonLink;

import React from 'react';
import SbEditable from 'storyblok-react';
import { useRouter } from 'next/router';
import { BaseBlok } from '../../lib/storyblok.interfaces';

interface Props {
  blok: BaseBlok & {
    message: string;
  };
}

/**
 *
 * Uber error message should only be displayed when the campaignRef is 'UBER_DEFAULT_PLAN' or not set. This is to notifiy the user that they do not qualify for one of the premium Uber plans
 */
export default function UberErrorMessage(props: Props) {
  const { message } = props.blok;
  const router = useRouter();

  const campaignRef = router.query.campaignRef ? router.query.campaignRef : '';
  if (campaignRef === 'UBER_BLUE_PLAN' || campaignRef === 'UBER_PREMIUM_PLAN') {
    return null;
  }
  return (
    <SbEditable content={props.blok}>
      <div className="mx-auto mt-9 max-w-6xl lg:px-4 xl:px-0">
        <div className="bg-red-100 p-4 text-red-500 lg:rounded-lg lg:border lg:border-red-400">{message}</div>
      </div>
    </SbEditable>
  );
}

import SbEditable from 'storyblok-react';
import { BaseBlok } from '../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../src/helpers/qa-attribute';

interface FeatureProps {
  blok: BaseBlok & {
    name: string;
  };
}

const Feature = ({ blok }: FeatureProps) => {
  return (
    <SbEditable content={blok}>
      <div className="py-2" {...generateQaAttrFromBlok(blok)}>
        <h2 className="text-lg"> {blok.name} </h2>
      </div>
    </SbEditable>
  );
};

export default Feature;

import _ from 'lodash';
import React from 'react';
import SbEditable from 'storyblok-react';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import DynamicComponent from '../DynamicComponent';
import { AppIcon } from './app_icon';
import { Section } from './MobileAppBannerExtend.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface Feature extends BaseBlok {
  text: string;
}

interface MobileAppBannerExtendProps {
  blok: BaseBlok & {
    image: Asset;
    header: string;
    app_icons: AppIcon[];
    flip_order: boolean;
    full_width: boolean;
    pre_header: string;
    text_color: string;
    features_list: Feature[];
    background_color: string;
  };
}

/**
 * Fast Charging component
 * @returns {Object}
 */
export default function MobileAppBannerExtend(props: MobileAppBannerExtendProps) {
  const id = 'mobile-app-banner-extend';
  const backgroundColor = props.blok.background_color ? props.blok.background_color : '';
  const textColor = props.blok.text_color ? props.blok.text_color : '';

  return (
    <SbEditable content={props.blok}>
      <Section
        fullWidth={props.blok.full_width}
        backgroundColor={backgroundColor}
        textColor={textColor}
        photoFirst={props.blok.flip_order}
        {...generateQaAttrFromBlok(props.blok)}
      >
        <div>
          <div>
            <header>
              <span id={`${id}-pre-header`}>{props.blok.pre_header}</span>
              <h2 id={`${id}-header`}>{props.blok.header}</h2>
            </header>
            {props.blok.features_list && !_.isEmpty(props.blok.features_list) && (
              <ul id={`${id}-features-list`}>
                {props.blok.features_list.map((blok, index) => (
                  <DynamicComponent key={blok._uid} blok={blok} index={index} noIcon />
                ))}
              </ul>
            )}
            {!_.isEmpty(props.blok.app_icons) && (
              <div>
                {props.blok.app_icons.map((blok, index) =>
                  DynamicComponent({
                    key: blok._uid,
                    blok: blok,
                    index,
                  }),
                )}
              </div>
            )}
          </div>
          <img alt={props.blok.image.alt} src={props.blok.image.filename} id={`${id}-image`} />
        </div>
      </Section>
    </SbEditable>
  );
}

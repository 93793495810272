import { BaseBlok } from '@/lib/storyblok.interfaces';
import { renderRichText } from '@/src/helpers/rich-text-render';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { ButtonLinkBlokValue, ButtonLink } from '@/components/ButtonLink';

export type PromotionExpiredViewProps = {
  blok: BaseBlok & {
    heading_text: string;
    content: StoryblokRichtext;
    call_to_action: ButtonLinkBlokValue[];
  };
};

export default function PromotionExpiredView({ blok }: PromotionExpiredViewProps) {
  return (
    <section className="mx-auto flex w-full flex-col justify-center p-5 py-20 text-center md:pt-32 md:pb-52 lg:max-w-5xl">
      <h1 className="text-3xl font-bold md:text-5xl">{blok.heading_text}</h1>

      <div className="content my-8 text-lg md:mb-14 md:mt-10 md:text-xl">{renderRichText(blok.content)}</div>

      <div className="flex justify-center">
        {blok.call_to_action.length && <ButtonLink blok={blok.call_to_action[0]} className="w-full md:w-72" />}
      </div>
    </section>
  );
}

import React from 'react';
import SbEditable from 'storyblok-react';
import checkIcon from '../../src/images/icon_check.png';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface ListItemProps {
  noIcon?: any;
  className?: string;
  index?: string;
  blok: BaseBlok & {
    text: string;
  };
}

const ListItem = (props: ListItemProps) => {
  return (
    <SbEditable content={props.blok}>
      <li className={`${props.className || ''} list-item`} {...generateQaAttrFromBlok(props.blok)}>
        {!props.noIcon && <img className={`${props.className || ''}`} src={checkIcon.src} alt="Check mark icon" />}
        <p className={`${props.className || ''}`} dangerouslySetInnerHTML={{ __html: props.blok.text }} />
      </li>
    </SbEditable>
  );
};

export default ListItem;

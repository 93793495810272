import React from 'react';
import SbEditable from 'storyblok-react';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import { Section } from './FAQExtend.styled';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { Accordion, AccordionButton, AccordionItem, AccordionPanel, useAccordionItemContext } from '@reach/accordion';
import '@reach/accordion/styles.css';
import { Richtext } from 'storyblok-js-client';
import Storyblok from '../../lib/storyblok';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

export interface FAQQuestion extends BaseBlok {
  question: string;
  answer: Richtext;
}

interface FAQExtendProps {
  blok: BaseBlok & {
    title: string;
    background_color: string;
    title_font_weight: string;
    divider_color: string;
    caret_icon_color: string;
    faq_questions: FAQQuestion[];
  };
}

interface AccordionHeaderProps {
  children: React.ReactNode;
  toggle: (index: number) => void;
}

const AccordionHeader = ({ children, toggle }: AccordionHeaderProps) => {
  const { isExpanded, index } = useAccordionItemContext();

  return (
    <div className="faq-extend-accordion-header">
      <h3 onClick={() => toggle(index)}>{children}</h3>
      <AccordionButton>{isExpanded ? <ExpandLess /> : <ExpandMore />}</AccordionButton>
    </div>
  );
};

const isMobile = () => typeof window !== 'undefined' && window.innerWidth <= 768;

const FAQExtend = (props: FAQExtendProps) => {
  const backgroundColor = props.blok.background_color;
  const fontWeight = props.blok.title_font_weight;
  const dividerColor = props.blok.divider_color;
  const caretIconColor = props.blok.caret_icon_color;
  const rows = props.blok.faq_questions || [];
  const [active, setActive] = React.useState(() => (isMobile() ? [] : [0]));

  function toggle(index: number) {
    setActive(active.includes(index) ? [] : [index]);
  }

  return (
    <SbEditable content={props.blok}>
      <Section
        backgroundColor={backgroundColor}
        fontWeight={fontWeight}
        dividerColor={dividerColor}
        caretIconColor={caretIconColor}
        {...generateQaAttrFromBlok(props.blok)}
      >
        <div className="faq-extend-container">
          <h2>{props.blok.title}</h2>

          <Accordion index={active} onChange={toggle}>
            {rows.map((row, index) => {
              return (
                <AccordionItem key={row._uid}>
                  <AccordionHeader toggle={toggle}>{row.question}</AccordionHeader>

                  <AccordionPanel>
                    <div dangerouslySetInnerHTML={{ __html: Storyblok.richTextResolver.render(row.answer) }} />
                  </AccordionPanel>
                </AccordionItem>
              );
            })}
          </Accordion>
        </div>
      </Section>
    </SbEditable>
  );
};

export default FAQExtend;

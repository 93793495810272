import React from 'react';
import DynamicComponent from '../../DynamicComponent';
import { Asset, BaseBlok } from '../../../lib/storyblok.interfaces';
import { Header } from './Navi.styled';
import { generateQaAttrFromBlok } from '../../../src/helpers/qa-attribute';

interface NaviProps {
  blok: BaseBlok & {
    logo: Asset;
    nav_items: BaseBlok[];
    cta_link: string;
    cta_color: string;
    cta_label: string;
  };
}

const Navi = (props: NaviProps) => {
  return (
    <Header>
      <div className={props.blok.nav_items.length ? 'with-nav' : ''} {...generateQaAttrFromBlok(props.blok)}>
        {props.blok.logo ? <img alt={props.blok.logo.alt} src={props.blok.logo.filename} /> : null}
        {props.blok.nav_items.length ? (
          <nav>
            {props.blok.nav_items &&
              props.blok.nav_items.map((blok) =>
                DynamicComponent({
                  key: blok._uid,
                  blok: blok,
                }),
              )}
          </nav>
        ) : (
          ''
        )}
        {props.blok['cta_link'] && (
          <a style={{ backgroundColor: props.blok.cta_color }} href={props.blok['cta_link'] || '#'}>
            {props.blok['cta_label'] || ''}
          </a>
        )}
      </div>
    </Header>
  );
};

export default Navi;

import styled from 'styled-components';

interface MobileAppBannerProps {
  backgroundColor: string;
  textColor: string;
  fullWidth: boolean;
  photoFirst: boolean;
}

export const Section = styled.section`
  background: ${({ backgroundColor }: MobileAppBannerProps) => (backgroundColor ? backgroundColor : '')};
  color: ${({ textColor }: MobileAppBannerProps) => (textColor ? textColor : 'inherit')};
  flex-direction: column;
  box-sizing: border-box;
  padding: 64px 32px;
  width: 100%;

  header {
    text-align: center;

    h2 {
      margin-top: 10px;
      margin-bottom: 16px;
      padding: 0;
      font-size: 26px;
      font-weight: 700;
      letter-spacing: 0.03em;
    }

    span {
      font-size: 16px;
      text-transform: uppercase;
      font-weight: 400;
    }
  }

  > div {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    .app-icon {
      height: 40px;
    }
    > div {
      div {
        display: grid;
        grid-template-columns: 1fr 1fr;
        width: 100%;
        justify-items: center;
        margin-top: 40px;
        margin-bottom: 32px;
        width: 100%;
        a {
          cursor: pointer;
          height: 40px;
          img {
            height: 100%;
          }
        }
        a:last-child {
          padding-left: 32px;
        }
      }
      div:last-child {
        display: flex;
        justify-content: center;
      }
    }

    ul {
      font-size: 16px;
      margin-bottom: 16px;

      li {
        line-height: 2rem;
        img {
          width: 20px;
        }
        p {
          display: inline;
        }
      }
    }

    // mobile
    .mobile-app-banner-extend-image {
      max-width: 100%;
      justify-self: center;
    }
  }

  @media only screen and (min-width: 622px) {
    header {
      h2 {
        font-size: 36px;
      }

      span {
        font-size: 16px;
      }
    }
  }

  // tablet
  @media only screen and (min-width: 768px) {
    width: ${({ fullWidth }: MobileAppBannerProps) => (fullWidth ? '100%' : '95%')};
    > div {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: dense;
      max-width: 1120px;
      margin: auto;
      gap: 50px;
      align-items: flex-start;
      > div {
        div {
          justify-items: start;
        }
        div:last-child {
          display: flex;
          justify-content: flex-start;
        }
      }

      > div:first-child {
        grid-column: ${({ photoFirst }: MobileAppBannerProps) => (photoFirst ? 2 : 0)};
        align-items: ${({ photoFirst }: MobileAppBannerProps) => (photoFirst ? 'end' : 'flex-start')};
      }
      ul {
        font-size: 1.2em;
        margin-bottom: 24px;
      }

      .mobile-app-banner-extend-image {
        max-width: 100%;
      }
    }
  }

  // web
  @media only screen and (min-width: 980px) {
    header {
      text-align: left;

      h2 {
        font-size: 36px;
      }

      span {
        font-size: 20px;
      }
    }
    > div {
      ul {
        font-size: 20px;
      }

      .mobile-app-banner-extend-image {
        max-width: initial;
      }
    }
  }
`;

import { useMutation, useQuery } from '@apollo/client';
import { Sync } from '@material-ui/icons';
import { Box, CircularProgress } from '@material-ui/core';
import { Formik } from 'formik';
import React, { ChangeEvent, FocusEvent, Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { events, getAnalyticsClient } from '@/lib/analytics';
import { useActionPage, useConfig, useErrorPage, usePromoCodeDetailsFromQuery } from '../../lib/hooks';
import { generateQaAttr, generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import { getNissanModelYear, NissanModelName, nissanVehicleModels } from '../../src/helpers/nissan';
import switchLambdaService from '../../src/helpers/switchLambdaService';

import { redeemPromotion } from '../../src/apollo/queries/promotionCodes';

import { RegisterViewExtendParams } from '../RegisterViewExtend';
import { Button, StyledForm } from '../RegisterForm/RegisterForm.styled';
import validations from '../RegisterForm/validations';

import { NissanRdr } from '../../lib/interfaces';
import FormikCheckbox from '../shared/FormikCheckbox';
import FormikDropdown from '../shared/FormikDropdown';
import FormikTextInput from '../shared/FormikTextInput';
import { isEmailRegistered, ErrorMessage, EmailStatus } from '../RegisterForm/shared';
import validateNissanRdr from '../../src/apollo/queries/nissan/validateNissanRdr';
import { extractErrorCode } from '../../src/helpers/extractErrorCode';

import _ from 'lodash';

interface RegisterFormProps {
  blok: RegisterViewExtendParams & {
    terms_conditions_copy?: string;
    default_vehicle_year?: string;
  };
  setView: (value: string) => void;
}

const NissanRegisterForm = ({ blok, setView }: RegisterFormProps) => {
  const [loading, setLoading] = useState<boolean>();
  const config = useConfig();
  const promoCodeDetails = usePromoCodeDetailsFromQuery();
  const errorPage = useErrorPage();
  const actionPage = useActionPage();
  const analytics = getAnalyticsClient();
  const [emailStatus, setEmailStatus] = useState<EmailStatus>(EmailStatus.pendingValidation);

  const validateRdrData = useQuery(validateNissanRdr, {
    variables: {
      input: {
        promoCode: promoCodeDetails?.promotionCode,
      },
    },
    onError: (error) => {
      return errorPage(extractErrorCode(error), _.get(error, 'message', 'Unknown'));
    },
  });

  const nissanRdr: NissanRdr = validateRdrData?.data?.validateNissanRdr;

  const [applyPromotion] = useMutation(redeemPromotion, {
    onCompleted({ redeemPromotion }) {
      if (!redeemPromotion?.success) return;

      if (blok.confirmation_route && blok.confirmation_route.url) {
        actionPage(blok.confirmation_route.url);
      } else {
        actionPage('finish-registration');
      }
    },
    onError(err) {
      errorPage(_.get(err, 'code', 'Unknown'), _.get(err, 'message', 'Unknown'));
    },
  });

  const formRef = useRef<any>();

  const handleSubmit = useCallback(
    ({ confirmPassword, ...contractInput }: { confirmPassword: string }) => {
      const input = {
        promotionCode: promoCodeDetails?.promotionCode,
        promotionRef: config.promotion.ref,
        planCode: blok.plan_code,
        platformName: 'nissan',
        DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
        ...contractInput,
      };

      return applyPromotion({ variables: { input } });
    },
    [applyPromotion, config.promotion.ref, promoCodeDetails, blok.plan_code],
  );

  const initialValues = {
    firstName: nissanRdr?.ownerFirstName || '',
    lastName: nissanRdr?.ownerLastName || '',
    email: nissanRdr?.ownerEmailAddress || '',
    phone: nissanRdr?.ownerDayPhone || '',
    address: nissanRdr?.ownerAddress1 || '',
    city: nissanRdr?.ownerCity || '',
    state: nissanRdr?.ownerState || '',
    postalCode: nissanRdr?.ownerZip || '',
    make: 'Nissan',
    model: NissanModelName[nissanRdr?.model] || '',
    year: getNissanModelYear(nissanRdr?.modelYear, blok.default_vehicle_year) || '',
    saleDate: new Date(nissanRdr?.retailSaleDate).toLocaleDateString('en-US') || '',
    password: '',
    confirmPassword: '',
    subscribe: true,
    terms: false,
    subscriptionCopy: blok.subscriptionCopy,
  };

  const handleRegister = async (values: any) => {
    // remove custom fields from Nissan form that are "read only"
    const { saleDate, model, ...other } = values;
    const expectedModel = model === 'ARIYA' ? 'Ariya' : model;

    analytics.track(events.clickRegisterButton, {
      host: window.location.host,
      pathname: window.location.pathname,
      email: values.email,
    });

    setLoading(true);
    return handleSubmit({ ...other, model: expectedModel });
  };

  const verifyEmailStatus = async (email: string) => {
    if (email) {
      const registered = await isEmailRegistered(email);
      setEmailStatus(registered ? EmailStatus.registered : EmailStatus.unregistered);
    }
  };

  const onEmailBlur = (event: FocusEvent<HTMLInputElement>) => {
    formRef.current.handleBlur(event);
    verifyEmailStatus(event.target.value);
  };

  const onEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
    formRef.current.handleChange(event);

    if (emailStatus !== EmailStatus.pendingValidation) {
      setEmailStatus(EmailStatus.pendingValidation);
    }
  };

  const isRegistered = emailStatus === EmailStatus.registered;
  const isUnregistered = emailStatus === EmailStatus.unregistered;

  useEffect(() => {
    verifyEmailStatus(initialValues.email);
  }, [initialValues.email]);

  if (validateRdrData.loading) {
    return (
      <Box sx={{ mb: 4, display: 'flex', justifyContent: 'center' }}>
        <CircularProgress size={24} />
      </Box>
    );
  }

  return (
    <Fragment>
      <Formik
        enableReinitialize={true}
        innerRef={formRef}
        onSubmit={handleRegister}
        validationSchema={validations}
        initialValues={initialValues}
        validateOnMount={true}
        {...generateQaAttrFromBlok(blok)}
      >
        {(formik) => {
          return (
            <StyledForm>
              <h3>Account Details</h3>
              <FormikTextInput
                className="half"
                name="firstName"
                label="First Name*"
                {...generateQaAttr('first-name')}
              />
              <FormikTextInput className="half" name="lastName" label="Last Name*" {...generateQaAttr('last-name')} />
              <FormikTextInput
                className="half"
                name="email"
                label="Email*"
                onBlur={onEmailBlur}
                onChange={onEmailChange}
                error={isRegistered && 'This email address already exists in our system.'}
                {...generateQaAttr('email')}
              />
              <FormikTextInput
                className="half"
                name="phone"
                type="tel"
                label="Contact Number*"
                {...generateQaAttr('phone')}
              />

              <h3>Address</h3>
              <FormikTextInput name="address" label="Street Address*" {...generateQaAttr('address')} />
              <FormikTextInput className="half" name="city" label="City*" {...generateQaAttr('city')} />
              <FormikDropdown className="quarter" name="state" label="State*" {...generateQaAttr('state')} />
              <FormikTextInput className="quarter" name="postalCode" label="Zip*" {...generateQaAttr('postal-code')} />

              <h3>Vehicle Information</h3>
              <FormikDropdown
                className="half"
                name="make"
                label="Make*"
                disabled={true}
                options={[{ name: 'Nissan', value: 'Nissan' }]}
                {...generateQaAttr('vehicle-make-dropdown')}
              />
              <FormikDropdown
                className="quarter"
                disabled={true}
                name="model"
                label="Model*"
                options={nissanVehicleModels}
                {...generateQaAttr('vehicle-model-dropdown')}
              />
              <FormikDropdown
                className="quarter"
                disabled={true}
                name="year"
                label="Year*"
                {...generateQaAttr('vehicle-year-dropdown')}
              />
              <FormikTextInput
                disabled={true}
                className="half"
                name="saleDate"
                label="Sale Date*"
                {...generateQaAttr('sale-date')}
              />

              <h3>Security</h3>
              <FormikTextInput
                className="half"
                name="password"
                label="Password*"
                type="password"
                {...generateQaAttr('password')}
              />
              <FormikTextInput
                className="half"
                name="confirmPassword"
                label="Confirm Password*"
                type="password"
                {...generateQaAttr('confirm-password')}
              />

              <h6>
                Password requirements: 8-24 characters, including at least 1 number, 1 uppercase, 1 lowercase & 1
                special character
              </h6>
              <hr />
              <FormikCheckbox name="terms" {...generateQaAttr('terms-checkbox')}>
                By checking this box, you acknowledge you have read and agree to be bound by our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/terms-service/" className="underline">
                  Terms of Service
                </a>{' '}
                and our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
                  Privacy Policy
                </a>
                . By submitting this form, you acknowledge you are sharing your personal information with EVgo and
                consent to EVgo’s Privacy Notice for California Residents. More detail regarding personal information we
                collect, how we use that information, how we share that information, and your rights and choices can be
                found in our{' '}
                <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
                  Privacy Policy
                </a>
                .
              </FormikCheckbox>
              <FormikCheckbox name="subscribe" {...generateQaAttr('subscribe-checkbox')}>
                {blok.subscriptionCopy}
              </FormikCheckbox>
              <div className="submit-container">
                <Button type="submit" disabled={!!loading || !formik.isValid || !isUnregistered}>
                  <span className={loading ? 'loading' : ''}>{blok.register_submit_button_copy}</span>
                  {loading ? <Sync className="spinner" /> : null}
                </Button>

                {isRegistered && (
                  <ErrorMessage>
                    This email address already exists in our system.{' '}
                    <span className="action" onClick={() => setView('login')}>
                      Log in here
                    </span>{' '}
                    with your email and password.
                  </ErrorMessage>
                )}

                <p>{blok.register_submit_button_subtext}</p>
              </div>
            </StyledForm>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default NissanRegisterForm;

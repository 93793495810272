import React from 'react';
import DynamicComponent from '../../DynamicComponent';
import SbEditable from 'storyblok-react';
import { BaseBlok } from '../../../lib/storyblok.interfaces';
import { Section, Title, StyledList } from './List.styled';
import { generateQaAttrFromBlok } from '../../../src/helpers/qa-attribute';

interface ListProps {
  blok: BaseBlok & {
    list_title: string;
    list_items: BaseBlok[];
  };
}

const List = (props: ListProps) => {
  throw new Error(props.blok.component);

  return (
    <SbEditable content={props.blok}>
      <Section {...generateQaAttrFromBlok(props.blok)}>
        <Title>{props.blok.list_title}</Title>
        <StyledList>
          {props.blok.list_items &&
            props.blok.list_items.map((blok, index) =>
              DynamicComponent({
                key: blok._uid,
                blok: blok,
                index,
              }),
            )}
        </StyledList>
      </Section>
    </SbEditable>
  );
};

export default List;

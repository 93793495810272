import { Form } from 'formik';
import styled from 'styled-components';
import { brandColor, colors } from '../../config';

export const Button = styled.button`
  display: flex;
  align-items: center;
  align-content: center;
  width: 272px;
  height: 48px;
  margin: 1.5em auto 0;
  border-radius: 0;
  border: none;
  padding: 0 24px;
  background-color: ${colors.accentYellow};
  color: ${brandColor};
  font-family: 'Mulish';
  font-size: 1.25em;
  font-weight: 600;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;

  span {
    &.loading {
      opacity: 0;
    }
  }

  .spinner {
    position: absolute;
    animation: around 3s infinite;
  }

  .spinner::after {
    animation: around 0.7s ease-in-out 0.2s infinite;
    background: transparent;
  }

  @keyframes around {
    100% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(1080deg);
    }
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;

  h3 {
    padding: 0;
    font-size: 20px;
    font-weight: 700;
    letter-spacing: 0.03em;
    width: 100%;
  }

  h6 {
    flex-basis: 100%;
    padding: 0 10px;
    text-align: center;
    font-size: 13px;
    color: #3f647c;
  }

  hr {
    margin: 2em 0;
  }

  div.submit-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    p {
      padding: 14px;
      font-weight: 400;
      letter-spacing: 0.012em;
      text-align: center;
    }
  }

  @media only screen and (min-width: 768px) {
    h3 {
      font-size: 24px;
      padding-left: 10px;
      padding-bottom: 5px;
      padding-top: 10px;
    }
  }
`;

import React, { Fragment } from 'react';
import SbEditable from 'storyblok-react';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import DynamicComponent from '../DynamicComponent';
import { PromoCodeEntryExtend } from '../PromoCodeEntryExtend';
import { Container, Description, HeroImage, Section, Title } from './HeroExtend.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface HeroExtendProps {
  blok: BaseBlok & {
    image: Asset;
    title: string;
    description: string;
    promocode_entry: PromoCodeEntryExtend[];
    image_full_width: boolean;
  };
}

/**
 * Program Benefits component
 * @returns {Object}
 */
export default function HeroExtend(props: HeroExtendProps) {
  const {
    title,
    image: { filename: image },
    promocode_entry,
    description,
    image_full_width,
  } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Section {...generateQaAttrFromBlok(props.blok)}>
        <Fragment>
          <Container>
            <div>
              <Title>{title}</Title>
              <Description>{description}</Description>
            </div>
            <HeroImage alt={props.blok.image.alt} src={image} fullWidth={image_full_width} />
            <div>
              {promocode_entry.map((blok) => (
                <DynamicComponent key={blok._uid} blok={blok} />
              ))}
            </div>
          </Container>
        </Fragment>
      </Section>
    </SbEditable>
  );
}

import { gql } from '@apollo/client';

export default gql`
  query validatePromoCode($codeInput: ValidatePromoCodeInput) {
    validatePromoCode(input: $codeInput) {
      promotionType
      promotionRef
      promotionCode
      success
    }
  }
`;

import React, { Fragment } from 'react';
import SbEditable from 'storyblok-react';
import { BaseBlok } from '../../../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../../../src/helpers/qa-attribute';

interface HowToListItemProps {
  blok: BaseBlok & {
    [key: string]: { filename: string; alt: string; url: string; text: string };
  } & {
    text: string;
  };
  className: string;
  index: number;
}

export default function HowToListItem(props: HowToListItemProps) {
  const renderAppIcon = (index: number) => {
    if (
      props.blok[`app_icon_${index}`] &&
      props.blok[`app_icon_${index}`].filename &&
      props.blok[`app_link_${index}`] &&
      props.blok[`app_link_${index}`].url
    ) {
      return (
        <a
          className={`${props.className || ''} app-icon`}
          href={props.blok[`app_link_${index}`].url}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className={`${props.className || ''}`}
            src={props.blok[`app_icon_${index}`].filename}
            alt={props.blok[`app_icon_${index}`].alt || ''}
          />
        </a>
      );
    }
    return null;
  };

  return (
    <SbEditable content={props.blok}>
      <Fragment>
        <li className={`${props.className || ''}`} {...generateQaAttrFromBlok(props.blok)}>
          {props.blok.image && props.blok.image.filename && (
            <img
              className={`${props.className || ''} main-image`}
              src={props.blok.image.filename}
              alt={props.blok.image.alt || ''}
            />
          )}
          <div className={`${props.className} copy-container`}>
            {props.blok['index_image'] && props.blok['index_image'].filename ? (
              <img
                className={`${props.className || ''} index-image`}
                src={props.blok['index_image'].filename}
                alt={props.blok['index_image'].alt || ''}
              />
            ) : (
              <div className={`${props.className} index-bubble`}>{props.index + 1}</div>
            )}
            <p className={`${props.className || ''}`} dangerouslySetInnerHTML={{ __html: props.blok.text }} />
          </div>
          <div className={props.className || ''}>
            {renderAppIcon(1)}
            {renderAppIcon(2)}
          </div>
        </li>
      </Fragment>
    </SbEditable>
  );
}

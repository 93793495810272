import { BaseBlok } from '@/lib/storyblok.interfaces';
import SbEditable from 'storyblok-react';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { renderRichText } from 'src/helpers/rich-text-render';

type Props = {
  blok: BaseBlok & {
    title: string;
    description: StoryblokRichtext;
  };
};

const Header = ({ blok }: Props) => {
  const { title, description } = blok;
  return (
    <SbEditable content={blok}>
      <section className="container py-16 sm:py-24">
        <div className="mx-auto max-w-7xl text-left">
          <h3 className="m-0 text-2xl font-bold text-theme-base lg:text-5xl [&_img]:mx-auto [&_img]:max-w-[360px]">
            {title}
          </h3>
          {description.content[0].content && (
            <div className="mx-auto mt-5 text-base">{renderRichText(description)}</div>
          )}
        </div>
      </section>
    </SbEditable>
  );
};

export default Header;

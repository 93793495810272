import styled from 'styled-components';
import { brandColor, colors } from '../../config';

interface SectionProps {
  backgroundColor: string | null;
  ctaBackgroundColor: string | null;
  ctaTextUnderline: boolean;
  fullWidth: boolean;
}

export const Section = styled.section`
  display: flex;
  flex-direction: column;
  align-items: start;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto;
  background: ${({ backgroundColor }: SectionProps) => (backgroundColor ? backgroundColor : '#F0F5FF')};

  div#register-form-container {
    font-family: 'Mulish', Roboto, Verdana, sans-serif !important;
  }

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
    box-sizing: border-box;
    width: 100%;
    padding: 34px 24px;

    .header {
      text-align: center;
    }

    .cta-button {
      display: flex;
      align-items: center;
      align-content: center;
      height: 48px;
      margin-top: 1.5em;
      border: 0;
      padding: 0 24px;
      background: ${({ ctaBackgroundColor }: SectionProps) =>
        ctaBackgroundColor ? ctaBackgroundColor : colors.accentYellow};
      color: ${brandColor};
      font-family: 'Mulish';
      font-size: 20px;
      font-weight: 500;
      justify-content: center;
      text-decoration: ${({ ctaTextUnderline }: SectionProps) => (ctaTextUnderline ? 'underline' : 'none')};
      cursor: pointer;
    }

    h2 {
      font-weight: 500;
      margin: 0;
      padding: 0;
    }

    hr {
      flex-basis: auto;
      width: 100%;
      margin: 2em 0;
      border-top: 1px solid #dee5ea;
    }

    &:first-child {
      flex: 2 1 0%;
    }

    &:last-child {
      flex: 3;
    }
  }

  @media only screen and (min-width: 980px) {
    width: ${({ fullWidth }: SectionProps) => (fullWidth ? '100%' : '95%')};
    > div {
      padding: 34px 0px;
      .header {
        width: 125%;
      }
      &:first-child {
        max-width: 1120px;
        margin: auto;
      }
    }
  }
`;

import { gql } from '@apollo/client';

export default gql`
  mutation loginPartnerDriver($input: PartnerDriverInput!) {
    loginPartnerDriver(input: $input) {
      promotionCode
      promotionType
      promotionRef
      campaignRef
    }
  }
`;

import styled from 'styled-components';

export const Section = styled.section`
  flex-direction: column;
  width: 85%;
`;

export const Title = styled.h2`
  text-align: center;
  padding: 0;
  font-size: 26px;
  margin-top: 1em;
  @media only screen and (min-width: 980px) {
    font-size: 48px;
  }
`;

export const StyledList = styled.ul`
  font-size: 1.2em;
  list-style: none;
  margin: 0 auto 48px;

  li {
    position: relative;
    margin-top: 1em;
    padding-left: 30px;
    img {
      position: absolute;
      height: 20px;
      top: 3px;
      left: 0;
    }
  }
  @media only screen and (min-width: 980px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 50px;
  }
`;

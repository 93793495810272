import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import SbEditable from 'storyblok-react';
import { useEvgoPlans } from './useEvgoPlans';
import classNames from 'classnames';
import TwSelect from '../shared/TwSelect';
import { renderRichText } from 'src/helpers/rich-text-render';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import CardLoader from './CardLoader';
import { Asset, BaseBlok } from '@/lib/storyblok.interfaces';
import { StoryblokRichtext } from 'storyblok-rich-text-react-renderer';
import { createPriceTierSchedule, renderRetailPlanName } from './utils/helpers';
import { RetailPlan, TouPricingRangeType } from './types';
import { useTranslation } from '@/lib/hooks';
import classnames from 'classnames';

type PriceTier = 'Early Bird' | 'Off-Peak' | 'On-Peak';
type NewRetailPlan = RetailPlan & { recommendedText: string };

const PRICE_TIER_LEGEND: Record<PriceTier, { color: string; dollarSigns: '$' | '$$' | '$$$' }> = {
  'Early Bird': { color: 'rgb(63,157,244)', dollarSigns: '$' },
  'Off-Peak': { color: 'rgb(79,210,186)', dollarSigns: '$$' },
  'On-Peak': { color: 'rgb(228,103,88)', dollarSigns: '$$$' },
};

type TiersPick = Pick<TouPricingRangeType, 'priceTier' | 'priceTierSchedule'>;

// TODO create interface for plan names
interface PricingPlan {
  logo: Asset;
  text: StoryblokRichtext;
  plan: 'payg' | 'member' | 'plus' | 'uberPro' | 'plusmax';
  plan_name: string;
}

interface Props {
  blok: BaseBlok & {
    default_region_id: number;
    widget_type: string;
    pricing_plan: PricingPlan[];
  };
}
const parsePrice = (price: number) => parseFloat(price as any).toFixed(2);

/**@deprecated */
const PricingWidget = (props: Props) => {
  const { translate } = useTranslation();
  const { pricing_plan, default_region_id } = props.blok;

  const { state, isLoading, getRetailPlans } = useEvgoPlans({
    defaultRegionId: default_region_id > 0 ? default_region_id : 1,
  });

  const regions = state.regions?.map((region) => {
    return {
      label: region.name,
      value: region.id.toString(),
    };
  });

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    getRetailPlans(parseInt(event.target.value));
  };

  const partnerPlans =
    pricing_plan?.length > 0 ? pricing_plan.map((plan) => ({ ...state.retailPlans?.[plan.plan], sbPlan: plan })) : [];

  const newRetailPlansArray = [
    { ...state.retailPlans?.payg, recommendedText: 'Occasional charging' },
    { ...state.retailPlans?.basic, recommendedText: '1-2x month charging' },
    { ...state.retailPlans?.plus, recommendedText: '3-5x month charging' },
    { ...state.retailPlans?.plusmax, recommendedText: '6x + charging' },
  ];

  const isTouRegion = state.regionsWithTouRates?.some((region) => region.id === state.regionId);

  // For creating legend and TOU chart
  const tiers = state.retailPlans?.plus.touRegionWithRangePricing.touPricingByRange as TiersPick[] | undefined;
  const newTiers = tiers && createPriceTierSchedule(tiers);

  return (
    <SbEditable content={props.blok}>
      <section {...generateQaAttrFromBlok(props.blok)}>
        <div className="py-10">
          <div className="container">
            {partnerPlans.length > 0 ? (
              <h3 className="my-4 text-center text-lg font-bold lg:text-4xl">{translate('Special savings')}</h3>
            ) : null}
            <TwSelect
              name="Region"
              label={translate('Choose Your Region')}
              handleChange={handleChange}
              value={state.regionId || 1}
              options={regions}
              className="mx-auto mt-8 w-full max-w-xs"
            />
          </div>
          {isTouRegion ? (
            <div className="mt-8">
              <div className="container flex flex-col justify-center gap-4 lg:flex-row">
                {/* Start tiers legend */}
                {tiers?.map((tier, index) => {
                  return (
                    <div key={`tier-${index}`}>
                      <div className="flex items-center justify-center space-x-2 text-sm">
                        <div
                          className="h-4 w-4 rounded-full"
                          style={{
                            backgroundColor: PRICE_TIER_LEGEND[tier.priceTier as PriceTier].color,
                          }}
                        />
                        <div className="font-bold">
                          <span>{PRICE_TIER_LEGEND[tier.priceTier as PriceTier].dollarSigns}</span>
                          <span className="ml-1">{tier.priceTier}</span>
                        </div>{' '}
                        <p className="space-x-1">
                          {tier.priceTierSchedule.map((time) => (
                            <time key={time} dateTime={time} className="lowercase">
                              {time}
                            </time>
                          ))}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </div>
              {/* Start TOU chart */}
              <div className="container mt-6">
                <div className="grid w-full grid-cols-[repeat(24,1fr)] items-center rounded-lg px-4">
                  {newTiers?.map(({ priceTier, start, end }, index) => {
                    return (
                      <span
                        key={`price-tier-${index}`}
                        style={{
                          gridColumnStart: index === 0 ? 1 : start,
                          gridColumnEnd: index === newTiers?.length - 1 ? 25 : end,
                          backgroundColor: PRICE_TIER_LEGEND[priceTier as PriceTier].color,
                        }}
                        className={classnames(
                          priceTier === 'Early Bird' && 'h-3',
                          priceTier === 'Off-Peak' && 'h-4',
                          priceTier === 'On-Peak' && 'h-6',
                          index === 0 && 'rounded-l-lg',
                          index === newTiers?.length - 1 && 'rounded-r-lg',
                        )}
                      />
                    );
                  })}
                  {newTiers?.map(({ start, end, startStandard, endStandard }, index) => {
                    return (
                      <div
                        key={`price-tier-${index}`}
                        style={{
                          gridColumnStart: index === 0 ? 1 : start,
                          gridColumnEnd: index === newTiers?.length - 1 ? 25 : end,
                        }}
                        className="mt-2 flex justify-between text-xs lowercase"
                      >
                        <span className="-translate-x-3">{startStandard}</span>
                        {index === newTiers.length - 1 && <span className="translate-x-3">{endStandard}</span>}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          ) : null}
          <div className="mt-8">
            {isLoading ? (
              <div className="container flex snap-x snap-mandatory items-start gap-x-8 gap-y-8 overflow-x-auto scroll-smooth px-8 py-8 [scrollbar-width:none] lg:grid lg:grid-cols-12 lg:gap-x-0 [&::-webkit-scrollbar]:hidden">
                {[...Array(4)].map((i) => (
                  <CardLoader key={i} />
                ))}
              </div>
            ) : (
              <Cards
                partnerPlans={partnerPlans}
                retailPlans={newRetailPlansArray as NewRetailPlan[]}
                isKwhRegion={isTouRegion as boolean}
              />
            )}
            <div className="container mt-2 text-center text-lg">
              {translate('Charge only once a month and want to prepay? Try the')}{' '}
              <form
                action="https://account.evgo.com/signUp"
                target="_blank"
                method="post"
                name="flex_Plan"
                className="inline-flex self-center"
              >
                <input type="hidden" name="PlanCode" value={state.retailPlans?.member?.planCode} />
                <button className="inline-flex font-normal text-ev-light-blue" type="submit">
                  {translate('prepaid plan')}
                </button>
              </form>{' '}
              {translate('to add a $4.99 charging credit to your account each month.')}
            </div>
          </div>
        </div>
      </section>
      {isTouRegion ? (
        <section className="container py-12 lg:py-16">
          <div className="mx-auto text-left">
            <h3 className="m-0 text-2xl font-bold text-theme-base lg:text-5xl [&_img]:mx-auto [&_img]:max-w-[360px]">
              {translate('How Time of Use Pricing Works')}
            </h3>
            <div className="mx-auto mt-5 text-base">{translate('Electricity costs')} </div>
          </div>
        </section>
      ) : null}
    </SbEditable>
  );
};

export default PricingWidget;

function Cards({
  partnerPlans,
  retailPlans,
  isKwhRegion,
}: {
  partnerPlans: any[];
  retailPlans: NewRetailPlan[];
  isKwhRegion: boolean;
}) {
  const { translate } = useTranslation();
  const [activeIndex, setActiveIndex] = useState(0);
  const slideContainerRef = useRef<HTMLDivElement>(null);
  const slideRefs = useRef<HTMLDivElement[] | any[]>([]);

  useEffect(() => {
    const observer = new window.IntersectionObserver(
      (entries: any) => {
        for (const entry of entries) {
          if (entry.isIntersecting) {
            setActiveIndex(slideRefs.current.indexOf(entry.target));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      },
    );

    for (const slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [slideContainerRef, slideRefs]);

  return (
    <>
      {/* Start partner plans */}
      {partnerPlans.length > 0 ? (
        <div className="container mb-8 flex flex-col justify-center gap-6 lg:flex-row">
          {partnerPlans.map((plan, index) => {
            const pricing = plan && plan?.touRegionWithPricing[0].touPricing[0];
            const touPricing = plan && plan?.touRegionWithRangePricing.touPricingByRange;
            const sbPlanData = plan.sbPlan;

            return (
              <div
                key={index}
                className="mx-auto flex w-full flex-col items-center rounded-lg border border-slate-200 p-6 text-center shadow-lg sm:max-w-[360px] lg:mx-0"
              >
                <img src={sbPlanData.logo.filename} alt={sbPlanData.logo.alt} className="h-auto w-full max-w-[200px]" />
                <div className="mb-6 mt-2 text-sm">{renderRichText(sbPlanData.text)}</div>
                <div className="mt-auto flex flex-col items-baseline gap-4 p-2">
                  {isKwhRegion ? (
                    <>
                      {touPricing.map((tier: any) => {
                        return (
                          <div key={tier.order} className="relative flex items-center">
                            <span
                              className="block h-4 w-4 self-start rounded-full"
                              style={{
                                backgroundColor: PRICE_TIER_LEGEND[tier.priceTier as PriceTier].color,
                              }}
                            />
                            <span className="ml-2 text-xs italic">{tier.priceTier}</span>
                            <div className="ml-2 text-xl">
                              {tier.minPrice === tier.maxPrice ? (
                                <span>${parsePrice(tier.minPrice)}</span>
                              ) : (
                                <span>
                                  ${parsePrice(tier.minPrice)} to ${parsePrice(tier.maxPrice)}
                                </span>
                              )}
                            </div>
                            <span className="ml-2 text-center text-xs italic text-ev-neutral">
                              per {tier.priceUnit.substring(1)}
                            </span>
                          </div>
                        );
                      })}
                    </>
                  ) : (
                    <div>
                      <p className="text-4xl font-bold">${parsePrice(pricing.price)}</p>
                      <p className="text-xs italic text-ev-neutral">per {pricing.priceUnit.substring(1)}</p>
                    </div>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      ) : null}
      {/* End partner plans */}
      {/* Start retial plans */}

      <div className="container">
        <h3 className="my-4 text-center text-lg font-bold lg:text-3xl">
          {isKwhRegion ? 'EVgo Plans with Time of Use (TOU) Pricing' : 'EVgo Plans'}
        </h3>
        <div className="flex justify-center gap-2 md:max-w-2xl lg:hidden">
          {retailPlans.map((_, index) => {
            return (
              <button
                key={index}
                onClick={() => {
                  setActiveIndex(index);
                  slideRefs.current[index].scrollIntoView({
                    block: 'nearest',
                    inline: 'nearest',
                  });
                }}
                className={classNames('h-1 w-12 rounded-lg', index === activeIndex ? 'bg-ev-yellow' : 'bg-slate-200')}
              />
            );
          })}
        </div>
      </div>
      <div
        ref={slideContainerRef}
        className="flex snap-x snap-mandatory items-start gap-x-8 gap-y-8 overflow-x-auto scroll-smooth px-6 py-8 [scrollbar-width:none] lg:container lg:grid lg:grid-cols-12 lg:gap-x-0 [&::-webkit-scrollbar]:hidden"
      >
        {retailPlans.map((plan, index) => {
          const pricing = plan && plan?.touRegionWithPricing[0].touPricing[0];
          const touPricing = plan && plan?.touRegionWithRangePricing.touPricingByRange;

          return (
            <div
              key={plan?.altId}
              ref={(ref) => (slideRefs.current[index] = ref)}
              className={classNames(
                'mx-auto flex w-[90%] max-w-md shrink-0 snap-center flex-col rounded-lg border border-slate-200 bg-white pt-8 text-center shadow-lg sm:w-[25rem] lg:col-span-3 lg:w-full lg:border-0 lg:pt-0 lg:shadow-none',
              )}
            >
              <h4 className="m-0 text-base font-bold lg:text-xl">{renderRetailPlanName(plan.altId)}</h4>
              <p className="text-sm text-ev-neutral">{plan.recommendedText}</p>
              <hr className="mt-4" />
              <ul
                role="list"
                className="m-0 list-none divide-y divide-slate-200 border-b border-slate-200 p-0 text-center text-base text-ev-gray"
              >
                {isKwhRegion ? (
                  <>
                    {touPricing.map((tier: any) => {
                      return (
                        <li key={tier.order} className="flex justify-center py-6">
                          <div className="relative flex flex-col text-lg">
                            <span
                              className="absolute -left-6 top-0 block h-4 w-4 rounded-full"
                              style={{
                                backgroundColor: PRICE_TIER_LEGEND[tier.priceTier as PriceTier].color,
                              }}
                            />
                            {tier.minPrice === tier.maxPrice ? (
                              <span>${parsePrice(tier.minPrice)}</span>
                            ) : (
                              <span>
                                ${parsePrice(tier.minPrice)} to ${parsePrice(tier.maxPrice)}
                              </span>
                            )}
                            <span className="text-xs italic text-ev-neutral">per {tier.priceUnit.substring(1)}</span>
                          </div>
                        </li>
                      );
                    })}
                  </>
                ) : (
                  <li className="py-6">
                    <p className="m-0 text-3xl font-bold">${pricing.price.toFixed(2)}</p>
                    <p className="m-0 text-center text-xs italic">
                      <span>per {pricing.priceUnit.substring(1)}</span>
                    </p>
                  </li>
                )}
                <li className="py-6">
                  ${pricing.transactionFee} {translate('session fee')}
                </li>
                <li className="py-6">
                  ${pricing.subscriptionFee}/{translate('month')}
                </li>
              </ul>
            </div>
          );
        })}
      </div>
    </>
  );
}

import classNames from 'classnames';
import { FC } from 'react';

interface Props {
  className?: string;
  name: string;
  required: boolean;
  error: any;
  checked: boolean;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur: any;
  children: React.ReactNode;
}

const TwCheckbox: FC<Props> = ({ className, name, required, checked, handleChange, handleBlur, children }) => {
  return (
    <label htmlFor={name} className={classNames('flex cursor-pointer justify-start', className)}>
      <input
        id={name}
        name={name}
        required={required}
        type="checkbox"
        checked={checked}
        onChange={handleChange}
        onBlur={handleBlur}
        className="form-checkbox h-6 w-6 rounded border-gray-300 text-ev-light-blue focus-within:border-slate-400 focus-within:ring-1"
      />
      <p className="ml-4 text-left text-sm">{children}</p>
    </label>
  );
};

export default TwCheckbox;

import React, { useState } from 'react';
import FooterIcon, { IconName } from './Icons';
import { ExpandLess as ExpandLessIcon, ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import { Sitemap } from './sitemap';
import { StyledFooter } from './Footer.styled';
import DynamicComponent from '../../DynamicComponent';
import { BaseBlok } from '../../../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../../../src/helpers/qa-attribute';
import { useTranslation } from '@/lib/hooks';
interface FooterProps {
  blok: BaseBlok & {
    sitemaps: Sitemap[];
  };
}

export default function Footer(props: FooterProps) {
  const [email, setEmail] = useState('');
  const { translate } = useTranslation();
  return (
    <StyledFooter>
      <section {...generateQaAttrFromBlok(props.blok)}>
        <div>
          <span>{translate('sign up')}</span>
          <form
            action="https://evgo.us13.list-manage.com/subscribe/post?u=dcd940242b9acc87b6b2d4da5&amp;id=3f34fcf053"
            method="post"
            name="mc-embedded-subscribe-form"
            target="_blank"
          >
            <div className="mc_embed_signup_scroll">
              <label>
                <input
                  type="email"
                  value={email}
                  name="EMAIL"
                  placeholder="Email Address"
                  onChange={({ target: { value = '' } = {} }) => setEmail(value)}
                />
                <input
                  aria-hidden="true"
                  type="text"
                  name="b_dcd940242b9acc87b6b2d4da5_3f34fcf053"
                  tabIndex={-1}
                  placeholder=""
                />
              </label>
              <button type="submit" value="Subscribe" name="subscribe">
                <ArrowForwardIcon />
              </button>
            </div>
          </form>
          <img className="footer-logo" src="/images/logo.svg" alt="Logo" />
        </div>
        <div className="div-spacer"></div>
        <div className="div-spacer"></div>
        {props.blok.sitemaps &&
          props.blok.sitemaps.map((blok) =>
            DynamicComponent({
              key: blok._uid,
              blok: blok,
            }),
          )}
        <div>
          <a href="https://apps.apple.com/us/app/evgo/id1281660968" target="_blank" rel="noreferrer">
            <img src="/images/app-store.png" alt="Footer appstore image" />
          </a>
          <a
            href="https://play.google.com/store/apps/details?id=com.driivz.mobile.android.evgo.driver"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/play-store.png" alt="Footer playstore image" />
          </a>
        </div>
      </section>

      <hr />

      <section className="legal-section">
        <span className="footer-copyright">
          {translate('copyright')} &copy; {new Date().getFullYear()} {translate('evgo services')}.
        </span>

        <span>
          <a href="https://www.evgo.com/privacy-policy/" target="_blank" rel="noreferrer">
            {translate('privacy policy')}
          </a>
          &nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;
          <a href="https://www.evgo.com/terms-service/" target="_blank" rel="noreferrer">
            {translate('terms conditions')}
          </a>
        </span>

        <div className="social">
          <a href="https://www.instagram.com/evgonetwork/" target="_blank" rel="noopener noreferrer">
            <FooterIcon name={IconName.Instagram} />
          </a>
          <a href="https://www.twitter.com/evgonetwork" target="_blank" rel="noopener noreferrer">
            <FooterIcon name={IconName.Twitter} />
          </a>
          <a href="https://www.youtube.com/user/evgonetwork" target="_blank" rel="noopener noreferrer">
            <FooterIcon name={IconName.Youtube} />
          </a>
          <a href="https://www.linkedin.com/company/evgo/" target="_blank" rel="noopener noreferrer">
            <FooterIcon name={IconName.LinkedIn} />
          </a>
          <a href="https://www.facebook.com/evgonetwork" target="_blank" rel="noopener noreferrer">
            <FooterIcon name={IconName.Facebook} />
          </a>
          <a href="https://www.tiktok.com/@evgonetwork" target="_blank" rel="noopener noreferrer">
            <FooterIcon name={IconName.TikTok} />
          </a>
          <a
            className="footer-to-header"
            href="#"
            onClick={() => {
              document.body.scrollTop = 0;
              document.documentElement.scrollTop = 0;
            }}
          >
            <ExpandLessIcon fontSize="large" />
          </a>
        </div>
      </section>
    </StyledFooter>
  );
}

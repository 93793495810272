import Component from './DynamicComponent';
import SbEditable from 'storyblok-react';
import { BaseBlok } from '../lib/storyblok.interfaces';
import { generateQaAttrFromBlok } from '../src/helpers/qa-attribute';

interface GridProps {
  blok: BaseBlok & {
    columns: BaseBlok[];
  };
}

const Grid = ({ blok }: GridProps) => (
  <SbEditable content={blok}>
    <ul className="mb-6 flex py-8" {...generateQaAttrFromBlok(blok)}>
      {blok.columns.map((blok) => (
        <li key={blok._uid} className="flex-auto px-6">
          <Component blok={blok} />
        </li>
      ))}
    </ul>
  </SbEditable>
);

export default Grid;

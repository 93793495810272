import { Form } from 'formik';
import styled from 'styled-components';
import { brandColor, colors } from '../../config';
import FormikTextInput from '../shared/FormikTextInput';

interface PromoCodeEntryProps {
  backgroundColor?: string | undefined;
  color?: string | undefined;
  breakpoint: string | undefined;
}

export const Container = styled.div`
  width: 100%;
`;

export const Button = styled.button`
  position: relative;
  height: 48px;
  min-width: 200px;
  padding: 0 16px;
  border: 0px;
  background-color: ${({ backgroundColor }: PromoCodeEntryProps) =>
    backgroundColor ? backgroundColor : colors.accentYellow};
  color: ${({ color }: PromoCodeEntryProps) => (color ? color : brandColor)};
  border-radius: 0px;
  font-family: 'Mulish';
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  cursor: pointer;

  @media only screen and (max-width: 768px) {
    width: 100%;
  }

  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &.loading .text {
    opacity: 0;
  }

  .spinner {
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    text-align: center;
    animation: around 3s infinite;
  }

  .spinner::after {
    animation: around 0.7s ease-in-out 0.2s infinite;
    background: transparent;
  }

  @keyframes around {
    100% {
      transform: rotate(0deg);
    }
    0% {
      transform: rotate(1080deg);
    }
  }
`;

export const StyledForm = styled(Form)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media only screen and (min-width: ${({ breakpoint }: PromoCodeEntryProps) => (breakpoint ? breakpoint : '768px')}) {
    flex-direction: row;
  }
`;

export const Input = styled(FormikTextInput)`
  padding: 0 0 8px;
  @media only screen and (min-width: ${({ breakpoint }: PromoCodeEntryProps) => (breakpoint ? breakpoint : '768px')}) {
    padding: 0;
    margin-right: 8px;
  }

  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const HelperText = styled.div`
  color: #707070;
  margin: 0 0 14px;
  text-align: center;
  @media only screen and (min-width: ${({ breakpoint }: PromoCodeEntryProps) => (breakpoint ? breakpoint : '768px')}) {
    text-align: left;
  }
`;

import styled from 'styled-components';

interface TitleProps {
  fontWeight: number;
}

interface ListProps {
  enlargeImages: boolean;
  alignNumbersStart: boolean;
  columns: number;
}

export const Title = styled.h2`
  text-align: center;
  padding: 0;
  font-weight: ${({ fontWeight }: TitleProps) => fontWeight || 'auto'};
  font-size: 26px;
  margin: 48px auto;
  text-transform: uppercase;
  width: 85%;

  @media only screen and (min-width: 768px) {
    font-size: 48px;
  }
`;

export const List = styled.ul`
  width: 85%;
  max-width: 984px;
  font-size: 20px;
  list-style: none;
  margin: 0 auto;
  font-weight: 500;

  li {
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    margin-bottom: 56px;

    img.main-image {
      height: ${({ enlargeImages }: ListProps) => (enlargeImages ? 'auto' : '110px')};
      width: ${({ enlargeImages }: ListProps) => (enlargeImages ? '100%' : 'auto')};
    }

    img.index-image {
      height: 30px;
      margin: 16px 0 0;
    }

    .index-bubble {
      height: 30px;
      width: 30px;
      background: #6b6b6b;
      color: white;
      display: flex;
      align-items: center;
      border-radius: 50%;
      margin-top: 20px;
      font-weight: bold;
    }

    p {
      padding: 15px;
    }

    .copy-container {
      flex-direction: ${({ alignNumbersStart }: ListProps) => (alignNumbersStart ? 'row' : 'column')};
      text-align: ${({ alignNumbersStart }: ListProps) => (alignNumbersStart ? 'left' : 'center')};
      align-items: ${({ alignNumbersStart }: ListProps) => (alignNumbersStart ? 'start' : 'center')};
      align-self: ${({ alignNumbersStart }: ListProps) => (alignNumbersStart ? 'start' : 'center')};
    }

    div {
      display: flex;
      justify-content: center;

      .app-icon {
        margin: 5px;
        display: block;

        img {
          height: auto;
          width: 100%;
        }
      }
    }
  }
  @media only screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: ${({ columns }: ListProps) => `repeat(${columns}, 1fr);`};
    width: 85%;
    grid-gap: 32px;
    list-style: none;
    margin-bottom: 48px;
    li {
      img.index-image {
        margin-bottom: 16px;
      }
      p {
        padding: 15px;
      }
    }
  }
`;

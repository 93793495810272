import React, { Fragment } from 'react';
import SbEditable from 'storyblok-react';
import DynamicComponent from '../DynamicComponent';
import { BaseBlok } from '../../lib/storyblok.interfaces';
import { Section, StyledContainer } from './Container.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';

interface ContainerExtendProps {
  blok: BaseBlok & {
    background_color: string;
    items: BaseBlok[];
  };
}

/**
 * Container component
 * @returns {Object}
 */
export default function ContainerExtend(props: ContainerExtendProps) {
  const { items, background_color } = props.blok;

  return (
    <SbEditable content={props.blok}>
      <Section backgroundColor={background_color} {...generateQaAttrFromBlok(props.blok)}>
        <Fragment>
          <StyledContainer>
            {items.map((blok) =>
              DynamicComponent({
                key: blok._uid,
                blok: { ...blok },
              }),
            )}
          </StyledContainer>
        </Fragment>
      </Section>
    </SbEditable>
  );
}

import { FC, useState } from 'react';
import { useFormik } from 'formik';
import TwInput from '@/components/shared/TwInput';
import validationSchema from './validations';
import { generateQaAttr } from 'src/helpers/qa-attribute';
import TwCheckbox from '@/components/shared/TwCheckbox';
import { useLazyQuery, useMutation } from '@apollo/client';
import { checkPromoCodeRedemptionByDisplayValue, redeemPromotion } from 'src/apollo/queries/promotionCodes';
import switchLambdaService from 'src/helpers/switchLambdaService';
import { useActionPage, useConfig, usePromoCodeDetailsFromQuery } from '@/lib/hooks';
import ErrorMessage from '@/components/shared/ErrorMessage';
import Spinner from '@/components/shared/Spinner';
import { isEmailRegistered } from '../RegisterForm/shared';
import TwSelect from '@/components/shared/TwSelect';
import states from '../../config/states.json';
import classNames from 'classnames';

type FormValues = {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  address: string;
  city: string;
  state: string;
  postalCode: string;
  make: string;
  model: string;
  year: string;
  password: string;
  confirmPassword: string;
  subscribe: boolean;
  terms: boolean;
  subscriptionCopy: string;
};

const RegisterForm: FC = () => {
  const promoCodeDetails = usePromoCodeDetailsFromQuery();
  const config = useConfig();
  const [error, setError] = useState<string | null>(null);
  const actionPage = useActionPage();
  const [checkRedemptionStatus] = useLazyQuery(checkPromoCodeRedemptionByDisplayValue, { fetchPolicy: 'network-only' });

  const [applyPromotion] = useMutation(redeemPromotion, {
    onCompleted(res) {
      if (res && res.redeemPromotion?.success) {
        actionPage('finish-registration');
      }
    },
    onError(err) {
      console.log(err);
      setError('Something went wrong. Try refreshing the page. If the problem persists, contact support@EVgo.com');
    },
  });

  // TODO fix model of car when it is updated in driivz
  const formik = useFormik<FormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      postalCode: '',
      make: 'Subaru',
      model: 'Solterra',
      year: '2023',
      password: '',
      confirmPassword: '',
      subscribe: true,
      terms: false,
      subscriptionCopy: '',
    },
    validationSchema,
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async ({ confirmPassword, ...values }: FormValues) => {
      const isEmailUsed = await isEmailRegistered(values.email);
      if (isEmailUsed) {
        setError('This email is already registered. Please use a different email or log in to claim the promotion.');
      } else {
        const { data: evgoRedemptionStatus } = await checkRedemptionStatus({
          variables: {
            input: {
              promotionRef: config.promotion.ref,
              groupName: config.promotion.campaign,
              displayValue: promoCodeDetails?.displayValue,
            },
          },
        });

        const { data: qmeritRedemtionStatus } = await checkRedemptionStatus({
          variables: {
            input: {
              promotionRef: config.promotion.ref,
              groupName: 'qmerit',
              displayValue: promoCodeDetails?.displayValue,
            },
          },
        });

        qmeritRedemtionStatus.checkPromoCodeRedemptionByDisplayValue.hasBeenRedeemed;
        const isVinRedeemed =
          evgoRedemptionStatus.checkPromoCodeRedemptionByDisplayValue.hasBeenRedeemed ||
          qmeritRedemtionStatus.checkPromoCodeRedemptionByDisplayValue.hasBeenRedeemed;

        if (isVinRedeemed) {
          setError('This VIN has already been redeemed.');
        } else {
          const input = {
            promotionCode: promoCodeDetails?.promotionCode,
            promotionRef: config.promotion.ref,
            DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
            ...values,
          };
          await applyPromotion({ variables: { input } });
        }
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="mx-auto mt-8 max-w-4xl ">
        <div className="grid grid-cols-1 gap-6 md:grid-cols-12">
          <TwInput
            className="md:col-span-6"
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.firstName}
            name="firstName"
            required
            label="First Name"
            error={formik.errors.firstName}
            handleBlur={formik.handleBlur}
            touched={formik.touched.firstName}
            {...generateQaAttr('first-name')}
          />
          <TwInput
            className="md:col-span-6"
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.lastName}
            name="lastName"
            required
            label="Last Name"
            error={formik.errors.lastName}
            handleBlur={formik.handleBlur}
            touched={formik.touched.lastName}
            {...generateQaAttr('last-name')}
          />
          <TwInput
            className="md:col-span-6"
            type="email"
            handleChange={formik.handleChange}
            value={formik.values.email}
            name="email"
            required
            label="Email"
            error={formik.errors.email}
            handleBlur={formik.handleBlur}
            touched={formik.touched.email}
            {...generateQaAttr('email')}
          />
          <TwInput
            className="md:col-span-6"
            type="tel"
            handleChange={formik.handleChange}
            value={formik.values.phone}
            name="phone"
            required
            label="Phone Number"
            error={formik.errors.phone}
            handleBlur={formik.handleBlur}
            touched={formik.touched.phone}
            {...generateQaAttr('phone')}
          />
          <TwInput
            className="md:col-span-12"
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.address}
            name="address"
            required
            label="Address"
            error={formik.errors.address}
            handleBlur={formik.handleBlur}
            touched={formik.touched.address}
            {...generateQaAttr('address')}
          />
          <TwInput
            className="md:col-span-6"
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.city}
            name="city"
            required
            label="City"
            error={formik.errors.city}
            handleBlur={formik.handleBlur}
            touched={formik.touched.city}
            {...generateQaAttr('city')}
          />
          <TwSelect
            value={formik.values.state}
            label="State"
            error={formik.errors.state}
            required
            name="state"
            handleChange={formik.handleChange}
            className="md:col-span-3"
            options={states}
            handleBlur={formik.handleBlur}
            touched={formik.touched.state}
            {...generateQaAttr('state')}
          />
          <TwInput
            className="md:col-span-3"
            type="text"
            handleChange={formik.handleChange}
            value={formik.values.postalCode}
            name="postalCode"
            required
            label="Zip"
            error={formik.errors.postalCode}
            handleBlur={formik.handleBlur}
            touched={formik.touched.postalCode}
            {...generateQaAttr('postal-code')}
          />
          <TwInput
            className="md:col-span-6"
            type="password"
            handleChange={formik.handleChange}
            value={formik.values.password}
            name="password"
            required
            label="Create Password"
            error={formik.errors.password}
            handleBlur={formik.handleBlur}
            touched={formik.touched.password}
            {...generateQaAttr('password')}
          />
          <TwInput
            className="md:col-span-6"
            type="password"
            handleChange={formik.handleChange}
            value={formik.values.confirmPassword}
            name="confirmPassword"
            required
            label="Confirm Password"
            error={formik.errors.confirmPassword}
            handleBlur={formik.handleBlur}
            touched={formik.touched.confirmPassword}
            {...generateQaAttr('confirm-password')}
          />
        </div>
        <div className="mt-6 rounded-lg bg-[#F0F5FF] p-4 text-center text-sm text-[#3D4146]">
          Minimum 8 chars: with at least 1 number, 1 uppercase, 1 lowercase & 1 special character (!, @, # ...)
        </div>
        <div className="mt-14 space-y-8">
          <TwCheckbox
            handleChange={formik.handleChange}
            checked={formik.values.subscribe}
            name="subscribe"
            required={false}
            error={formik.errors.subscribe}
            handleBlur={formik.handleBlur}
            {...generateQaAttr('subscribe')}
          >
            By checking the box, you consent to receiving promotions from, or on behalf of EVgo via email or automated
            text message. Your consent is not a condition of purchase and message and data rates may apply.
          </TwCheckbox>
          <TwCheckbox
            handleChange={formik.handleChange}
            checked={formik.values.terms}
            name="terms"
            required
            error={formik.errors.terms}
            handleBlur={formik.handleBlur}
            {...generateQaAttr('terms')}
          >
            By checking this box, you acknowledge you have read and agree to be bound by our{' '}
            <a target="_blank" rel="noreferrer" href="https://www.evgo.com/terms-service/" className="underline">
              Terms of Service
            </a>{' '}
            and our{' '}
            <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
              Privacy Policy
            </a>
            . By submitting this form, you acknowledge you are sharing your personal information with EVgo and consent
            to EVgo’s Privacy Notice for California Residents. More detail regarding personal information we collect,
            how we use that information, how we share that information, and your rights and choices can be found in our
            Privacy Policy.
          </TwCheckbox>
        </div>
        <button
          disabled={!formik.isValid || !formik.dirty || formik.isSubmitting}
          type="submit"
          className={classNames(
            'mx-auto mt-10 flex h-12 w-44 items-center justify-center rounded-full bg-[#20609F] px-4 font-bold text-white hover:bg-opacity-80',
            { 'cursor-not-allowed opacity-50': !formik.isValid || !formik.dirty },
          )}
        >
          {formik.isSubmitting ? <Spinner /> : 'Sign Up'}
        </button>
      </form>
      {error && <ErrorMessage errorMessage={error} className="mt-10" />}
    </>
  );
};

export default RegisterForm;

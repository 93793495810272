import { gql } from '@apollo/client';

export default gql`
  mutation initiateCustomOpenEnroll($input: InitiateCustomOpenEnrollInput!) {
    initiateCustomOpenEnroll(input: $input) {
      displayValue
      promotionCode
      promotionRef
      promotionType
      success
    }
  }
`;

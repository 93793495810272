import classNames from 'classnames';
import { useFormik } from 'formik';
import { FC, useState } from 'react';
import { renderRichText } from 'src/helpers/rich-text-render';
import { PromotionFormProps } from './types';
import { useRouter } from 'next/router';
import Spinner from '@/components/shared/Spinner';
import ErrorMessage from '@/components/shared/ErrorMessage';
import { useConfig } from '@/lib/config';
import { useMutation } from '@apollo/client';
import { initiateCustomOpenEnroll } from 'src/apollo/queries/promotionCodes';
import QmeritDialog from './QmeritDialog';
import { promotionSchema } from './validations';

type PromotionValues = {
  promotion: 'EVgo' | 'Qmerit' | '';
};

const PromotionForm: FC<PromotionFormProps> = ({ qmeritonly, promotion_options, promotion_text, vin }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const router = useRouter();

  const config = useConfig();

  const [initiateEnrollment] = useMutation(initiateCustomOpenEnroll, {
    onCompleted({ initiateCustomOpenEnroll }) {
      const { promotionCode, promotionType, success } = initiateCustomOpenEnroll;
      if (!success) {
        setError('There was an error with redeeming your promotion. Please try again later.');
        return;
      }
      router.push({
        pathname: `/register`,
        query: { promotionCode, promotionType, displayValue: vin },
      });
    },
    onError(err) {
      console.log(err);
    },
  });

  const formik = useFormik<PromotionValues>({
    initialValues: {
      promotion: qmeritonly == 'true' ? 'Qmerit' : '',
    },
    validationSchema: promotionSchema,
    onSubmit: async (values: PromotionValues) => {
      setError(null);
      // Prevent manipulation of form if qmeritonly is true
      if (values.promotion === 'EVgo' && qmeritonly == 'true') {
        setError('You cannot enroll with EVgo at this time');
      } else if (values.promotion === 'Qmerit') {
        setIsDialogOpen(true);
      } else {
        setIsLoading(true);
        await initiateEnrollment({
          variables: {
            input: {
              customDisplayValue: vin,
              enforceUniqueDisplayValue: true,
              groupName: config.promotion.campaign,
              promotionRef: config.promotion.ref,
            },
          },
        });
      }
    },
  });

  return (
    <>
      <QmeritDialog isDialogOpen={isDialogOpen} setIsDialogOpen={setIsDialogOpen} vin={vin} />
      <div className="relative mx-auto w-full bg-[#F9FAFB] p-10 text-center shadow-lg lg:-mt-10 lg:max-w-5xl lg:rounded-lg">
        <form onSubmit={formik.handleSubmit} className="mx-auto max-w-2xl">
          <fieldset className="relative">
            <legend className="text-2xl font-bold lg:text-3xl">Select Charging Credit</legend>
            <div className="mt-4 flex flex-col gap-10 sm:flex-row">
              {promotion_options.map((option) => {
                const isSelected = formik.values.promotion === option.type;
                const disableEvgo = qmeritonly == 'true' && option.type === 'EVgo';
                return (
                  <label
                    key={option.type}
                    htmlFor={option.type}
                    className={classNames(
                      'relative flex w-full cursor-pointer items-center justify-center rounded-lg border bg-white p-6 py-10 sm:p-10',
                      { 'border-ev-light-blue': isSelected },
                      { 'cursor-not-allowed': disableEvgo },
                    )}
                  >
                    <div className="absolute left-6 flex h-5 items-center sm:left-4 lg:left-8">
                      <input
                        id={option.type}
                        className="form-radio text-ev-light-blue focus-within:border-slate-400 focus-within:ring-1 focus-within:ring-slate-400"
                        value={option.type}
                        type="radio"
                        name="promotion"
                        onChange={formik.handleChange}
                        disabled={disableEvgo}
                        checked={formik.values.promotion === option.type}
                      />
                    </div>
                    <img
                      className={classNames('h-auto w-full max-w-[190px] rounded-lg', { 'opacity-40': disableEvgo })}
                      src={option.logo.filename}
                      alt={option.logo.alt}
                    />
                  </label>
                );
              })}
            </div>
          </fieldset>
          {formik.errors.promotion && <ErrorMessage errorMessage={formik.errors.promotion} className="mt-10" />}
          {qmeritonly === 'true' && (
            <div className="mt-10 rounded-lg bg-[#F0F5FF] p-6 text-left text-xs text-theme-secondary lg:p-10">
              {renderRichText(promotion_text)}
            </div>
          )}
          <button
            type="submit"
            className="mx-auto mt-10 flex h-12 w-44 items-center justify-center rounded-full bg-theme-btn-fill px-4 font-bold text-theme-btn hover:bg-opacity-80"
          >
            {isLoading ? <Spinner /> : 'Get Started'}
          </button>
          {error && <ErrorMessage className="mt-10" errorMessage={error} />}
        </form>
      </div>
    </>
  );
};

export default PromotionForm;

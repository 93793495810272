import { FC, useState } from 'react';
import { useFormik, FormikErrors } from 'formik';
import TwInput from '@/components/shared/TwInput';
import { generateQaAttr } from 'src/helpers/qa-attribute';
import TwCheckbox from '@/components/shared/TwCheckbox';
import * as Yup from 'yup';
import { useLazyQuery, useMutation } from '@apollo/client';
import { checkPromoCodeRedemptionByDisplayValue, redeemPromotion } from 'src/apollo/queries/promotionCodes';
import { usePromoCodeDetailsFromQuery, useConfig, useActionPage } from '@/lib/hooks';
import switchLambdaService from 'src/helpers/switchLambdaService';
import { auth } from '../../lib/firebase';
import { FirebaseError } from '@firebase/util';
import {
  setPersistence,
  signInWithEmailAndPassword,
  signInAnonymously,
  inMemoryPersistence,
  AuthErrorCodes,
} from 'firebase/auth';
import Spinner from '@/components/shared/Spinner';
import ErrorMessage from '@/components/shared/ErrorMessage';
import classNames from 'classnames';

async function verifyEmailAndPasswordWithoutPersistence(email: string, password: string) {
  await setPersistence(auth, inMemoryPersistence);
  await signInWithEmailAndPassword(auth, email, password);
  await signInAnonymously(auth);
}

const firebaseErrors: { [key: string]: FormikErrors<FormValues> } = {
  [AuthErrorCodes.USER_DELETED]: { email: 'This email address does not exist.' },
  [AuthErrorCodes.INVALID_PASSWORD]: { password: 'Password does not match.' },
};

type FormValues = {
  email: string;
  password: string;
  subscribe: boolean;
  terms: boolean;
};

// TODO consolidate error messaging with other forms
function LoginErrorComponent({ message }: { message: string }) {
  if (message.includes('INVALID_LAST_FIRST_NAME')) {
    return <ErrorMessage errorMessage="Error: Email and password not found." className="mx-auto mt-10 max-w-xl" />;
  } else if (message.includes('UNEXPECTED_ERROR')) {
    return (
      <ErrorMessage errorMessage="Something wrong happened, please try again." className="mx-auto mt-10 max-w-xl" />
    );
  } else if (message.includes('Reached maximum redemptions by account per campaign')) {
    return (
      <ErrorMessage
        className="mx-auto mt-10 max-w-xl"
        errorMessage="We're sorry, it looks like you already have a similar plan on your account that cannot be combined with this one"
      />
    );
  } else if (message.includes('payment.method.not.found')) {
    return (
      <p className="mx-auto mt-10 max-w-xl rounded-lg border border-red-400 bg-red-100 p-4 text-red-500">
        Looks like your account it not yet active.{' '}
        <a
          href={process.env.NEXT_PUBLIC_EVGO_LOGIN_URL}
          target="_blank"
          className="mx-auto text-red-500 underline hover:text-opacity-75"
          rel="noreferrer"
        >
          Activate your account
        </a>{' '}
        by logging in and adding a payment method.
      </p>
    );
  }
  return <ErrorMessage errorMessage={`Error: ${message}`} className="mx-auto mt-10 max-w-xl" />;
}

const LoginForm: FC = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const config = useConfig();
  const promoCodeDetails = usePromoCodeDetailsFromQuery();
  const actionPage = useActionPage();
  const [checkRedemptionStatus] = useLazyQuery(checkPromoCodeRedemptionByDisplayValue, { fetchPolicy: 'network-only' });

  const [applyPromotion] = useMutation(redeemPromotion, {
    onCompleted() {
      actionPage('/confirmation', 'login');
    },
    onError(error) {
      setError(error.message);
    },
  });

  const formik = useFormik({
    initialValues: { email: '', password: '', subscribe: true, terms: false },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .strict(true)
        .trim('Email cannot include leading and trailing spaces')
        .email('Please enter a valid email address')
        .required('Required'),
      password: Yup.string().required('Required'),
      subscribe: Yup.boolean(),
      terms: Yup.boolean().oneOf([true], 'Required'),
    }),
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    onSubmit: async (values: FormValues) => {
      setLoading(true);
      setError(null);
      const input = {
        address: null,
        city: null,
        firstName: null,
        lastName: null,
        make: null,
        model: null,
        phone: null,
        postalCode: null,
        state: null,
        year: null,
        email: values.email,
        password: values.password,
        subscribe: values.subscribe,
        terms: values.terms,
        promotionCode: promoCodeDetails?.promotionCode,
        promotionRef: config.promotion.ref,
        subscriptionCopy: 'subscription_copy',
        DISABLE_WESQL_LEGACY_AWS_LAMBDA_API: switchLambdaService(),
      };

      try {
        await verifyEmailAndPasswordWithoutPersistence(values.email, values.password);
        const { data: evgoRedemptionStatus } = await checkRedemptionStatus({
          variables: {
            input: {
              promotionRef: config.promotion.ref,
              groupName: config.promotion.campaign,
              displayValue: promoCodeDetails?.displayValue,
            },
          },
        });

        const { data: qmeritRedemtionStatus } = await checkRedemptionStatus({
          variables: {
            input: {
              promotionRef: config.promotion.ref,
              groupName: 'qmerit',
              displayValue: promoCodeDetails?.displayValue,
            },
          },
        });
        const isVinRedeemed =
          evgoRedemptionStatus.checkPromoCodeRedemptionByDisplayValue.hasBeenRedeemed ||
          qmeritRedemtionStatus.checkPromoCodeRedemptionByDisplayValue.hasBeenRedeemed;

        if (isVinRedeemed) {
          setError('This VIN has already been redeemed.');
        } else {
          await applyPromotion({
            variables: { input },
          });
        }
      } catch (e) {
        const code = (e as FirebaseError)?.code;
        const isKnownError = Object.keys(firebaseErrors).includes(code);

        if (isKnownError) {
          console.log(firebaseErrors[code]);
          const errorMessage = firebaseErrors[code].email || firebaseErrors[code].password || 'Unknown error';
          setError(errorMessage);
        } else {
          setError('Something went wrong, please try again.');
        }
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit} className="mx-auto mt-8 max-w-xl">
        <div className="grid grid-cols-1 gap-6">
          <TwInput
            className="md:col-span-6"
            type="email"
            handleChange={formik.handleChange}
            value={formik.values.email}
            name="email"
            required={true}
            label="Email"
            error={formik.errors.email}
            handleBlur={formik.handleBlur}
            touched={formik.touched.email}
            {...generateQaAttr('email')}
          />
          <TwInput
            className="md:col-span-6"
            type="password"
            handleChange={formik.handleChange}
            value={formik.values.password}
            name="password"
            required={true}
            label="Password"
            error={formik.errors.password}
            handleBlur={formik.handleBlur}
            touched={formik.touched.password}
            {...generateQaAttr('password')}
          />
        </div>
        <div className="mt-14 space-y-8">
          <TwCheckbox
            handleChange={formik.handleChange}
            checked={formik.values.subscribe}
            name="subscribe"
            required={false}
            error={formik.errors.subscribe}
            handleBlur={formik.handleBlur}
            {...generateQaAttr('subscribe')}
          >
            By checking the box, you consent to receiving promotions from, or on behalf of EVgo via email or automated
            text message. Your consent is not a condition of purchase and message and data rates may apply.
          </TwCheckbox>
          <TwCheckbox
            handleChange={formik.handleChange}
            checked={formik.values.terms}
            name="terms"
            required
            error={formik.errors.terms}
            handleBlur={formik.handleBlur}
            {...generateQaAttr('terms')}
          >
            By checking this box, you acknowledge you have read and agree to be bound by our{' '}
            <a target="_blank" rel="noreferrer" href="https://www.evgo.com/terms-service/" className="underline">
              Terms of Service
            </a>{' '}
            and our{' '}
            <a target="_blank" rel="noreferrer" href="https://www.evgo.com/privacy-policy/" className="underline">
              Privacy Policy
            </a>
            . By submitting this form, you acknowledge you are sharing your personal information with EVgo and consent
            to EVgo’s Privacy Notice for California Residents. More detail regarding personal information we collect,
            how we use that information, how we share that information, and your rights and choices can be found in our
            Privacy Policy.
          </TwCheckbox>
        </div>
        <button
          disabled={!formik.isValid || !formik.dirty || loading}
          type="submit"
          className={classNames(
            'mx-auto mt-10 flex h-12 w-44 items-center justify-center rounded-full bg-[#20609F] px-4 font-bold text-white hover:bg-opacity-80',
            { 'cursor-not-allowed opacity-50': !formik.isValid || !formik.dirty },
          )}
        >
          {formik.isSubmitting ? <Spinner /> : 'Login'}
        </button>
      </form>
      {error && <LoginErrorComponent message={error} />}
    </>
  );
};

export default LoginForm;

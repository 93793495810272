import styled from 'styled-components';

interface HeroImageProps {
  fullWidth: boolean;
}

export const Section = styled.section`
  justify-content: center;
  @media (min-width: 1000px) {
    padding: 1.5em;
  }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 24px;
  @media (min-width: 1000px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(150px, auto);
    padding: 84px 0 40px;
    max-width: 1120px;
    margin: auto;
    flex-direction: row;
  }
`;

export const Title = styled.h2`
  color: #002b3a;
  font-size: 26px;
  font-weight: 700;
  text-align: center;
  padding: 10px 0px;
  margin: 56px 0 4px;
  letter-spacing: 0.03em;
  @media (min-width: 1000px) {
    text-align: left;
    font-size: 48px;
    margin: 0 0 4px;
  }
`;

export const Description = styled.span`
  display: block;
  color: #707070;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  @media (min-width: 1000px) {
    font-size: 20px;
    text-align: left;
  }
`;

export const HeroImage = styled.img`
  padding: ${({ fullWidth }: HeroImageProps) => (fullWidth ? '20px 0 0' : '20px 24px 40px')};
  width: ${({ fullWidth }: HeroImageProps) => (fullWidth ? 'calc(100% + 48px)' : '100%')};
  max-width: ${({ fullWidth }: HeroImageProps) => (fullWidth ? 'auto' : '340px')};
  @media (min-width: 1000px) {
    max-width: 400px;
    justify-self: flex-end;
    grid-row-start: 1;
    grid-row-end: 3;
    grid-column-start: 2;
    padding: 0;
  }
`;

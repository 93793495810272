import React from 'react';

type LayoutProps = {
  children: React.ReactNode;
  className?: string;
};

const Layout: React.FC<LayoutProps> = ({ children, className }) => {
  return <div className={className}>{children}</div>;
};

export default Layout;

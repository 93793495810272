import { gql } from '@apollo/client';

export default gql`
  mutation initiateOpenEnroll($input: InitiateOpenEnrollInput!) {
    initiateOpenEnroll(input: $input) {
      promotionCode
      promotionRef
      promotionType
    }
  }
`;

import React from 'react';
import SbEditable from 'storyblok-react';
import { BaseBlok, Link } from '../../../../lib/storyblok.interfaces';
import { SitemapItem, StyledSitemapLink } from './SitemapLink.styled';
import { generateQaAttrFromBlok } from '../../../../src/helpers/qa-attribute';

export interface SitemapLink extends BaseBlok {
  link: Link;
  name: string;
}

const SitemapLink = (props: { blok: SitemapLink }) => {
  return (
    <SbEditable content={props.blok}>
      <SitemapItem className="sitemap-item" {...generateQaAttrFromBlok(props.blok)}>
        <StyledSitemapLink className="sitemap-link" href={props.blok.link.url || ''} target="_blank" rel="noreferrer">
          {props.blok.name}
        </StyledSitemapLink>
      </SitemapItem>
    </SbEditable>
  );
};

export default SitemapLink;

import styled from 'styled-components';
import { brandColor, colors } from '../../../config';

export const StyledFooter = styled.footer`
  box-sizing: border-box;
  color: white;
  width: 100%;
  background-color: ${brandColor};

  hr {
    margin: 0 24px;
    border: none;
    border-top: 1px solid ${colors.accentYellow};
  }

  section {
    display: flex;
    align-items: start;
    flex-direction: column;
    box-sizing: border-box;
    width: 100%;
    padding: 64px 24px 48px;
    margin-bottom: 0;

    a {
      color: white;
      font-weight: 200;
      text-decoration: none;
      font-style: normal;

      &:hover {
        color: #ffc425;
        transition: all 150ms linear;
      }
    }

    > div {
      flex: 2;
      display: flex;
      flex-direction: column;
      margin: 32px 0 0;

      span {
        font-weight: 300;
        line-height: 1.6;
      }

      a {
        margin: 4px 0;
      }

      form {
        display: flex;
        margin: 32px 0 64px;

        .mc_embed_signup_scroll {
          flex: 1;
          display: flex;
          align-items: center;
          align-content: center;
          border-bottom: 1px solid #32657e;

          button {
            border: 0;
            padding: 0;
            margin-bottom: 12px;
            background-color: transparent;
            color: #32657e;
          }

          label {
            flex: 1;
            display: flex;
            margin-bottom: 12px;

            input {
              flex: 1;
              background-color: transparent;
              color: #32657e;
              border: 0;
            }

            input::placeholder {
              color: #32657e;
              font-family: 'Mulish';
              font-size: 1rem;
            }

            input:last-child {
              display: none;
            }
          }
        }
      }

      h2 {
        font-size: 1.125rem;
        padding: 0;
      }

      .footer-logo {
        width: 120px;
      }

      &.social {
        display: flex;
        flex: 0 1 auto;
        flex-direction: row;

        a {
          display: flex;
          align-items: center;
          align-content: center;
          margin: 0 6px;
          border-radius: 12px;
          justify-content: center;
          text-align: center;

          svg {
            fill: white;
          }

          &:hover {
            svg {
              fill: #ffc424;
              transition: all 150ms linear;
            }
          }

          &.footer-to-header {
            margin-right: 0;
            background-color: ${brandColor};
            width: auto;
            color: white;
          }
        }
      }

      &:first-child:not(.social) {
        flex: 3;
        margin-top: 0;
        margin-left: 0;

        img {
          max-width: 50%;
          margin: 0 0 24px;
        }
      }

      &:last-child:not(.social) {
        flex: 0 1 auto;
        margin-right: 0;

        a {
          margin: 0;
        }

        img {
          margin: 8px 0;
          height: 48px;
        }
      }
    }

    > span {
      display: flex;
      margin: 0;
      text-align: center;

      &:first-of-type {
        margin-left: 0;
      }

      &:last-of-type {
        margin-top: 16px;
        margin-right: 0;
      }
    }

    &:last-child {
      align-items: center;
      align-content: center;
      padding: 32px 24px;
    }
  }

  @media only screen and (min-width: 1024px) {
    hr {
      margin: 0 5em;
    }

    section {
      flex-direction: row;
      padding: 6.625em 5em 2em 5em;
      justify-content: space-between;

      > div {
        margin: 0 32px;

        &.social {
          display: flex;
          margin: 0;
        }
      }

      > span {
        display: flex;
        margin: 0;
        text-align: left;

        &:last-of-type {
          margin-top: 0;
        }
      }

      &:last-child {
        align-items: center;
        align-content: flex-start;
        padding: 32px 5em;
      }
    }
  }

  @media only screen and (max-width: 1024px) {
    .div-spacer {
      display: none;
    }

    .legal-section.legal-section {
      flex-direction: column-reverse;

      .footer-copyright {
        margin-top: 16px;
      }
    }
  }
`;

import { useRouter } from 'next/router';
import React from 'react';
import SbEditable from 'storyblok-react';
import { Asset, BaseBlok } from '../../lib/storyblok.interfaces';
import { Section, ErrorSection } from './Error.styled';
import { generateQaAttrFromBlok } from '../../src/helpers/qa-attribute';
import Button from '../Controls/Button';

interface ErrorProps {
  blok: BaseBlok & {
    title: string;
    subtitle: string;
    full_width: boolean;
    text_color: string;
    button_square: boolean;
    background_image: Asset;
  };
}

/**
 * Error component
 * @param {Object} props - React props
 * @returns {Object}
 */
export default function Error(props: ErrorProps) {
  const textColor = props.blok.text_color || '';
  const withBackground = !!(props.blok.background_image && props.blok.background_image.filename);
  let title = 'Something went wrong';
  let errorCode = '';
  const router = useRouter();
  const { error: _error, message: _message } = router.query as { error: string; message: string };

  let message = (
    <>
      Try going back and trying again. If the problem persists, contact&nbsp;
      <a href="mailto:support@evgo.com">support@evgo.com</a>
    </>
  );

  // TO DO: Verify that we will no longer need this block and parse only
  // errors we are getting from portals-server
  if (_error) {
    errorCode = _error.includes(':') ? _error : `Error: ${_error}`;
    switch (_error) {
      case 'promotion_code_not_found':
        errorCode = 'Invalid code. Please enter a valid code.';
        break;
      case 'promotion_code_redeemed':
        errorCode = 'Code has already been redeemed. Please enter a code that has not yet been redeemed.';
        break;
      case 'request_body':
        errorCode = `Error: ${_message}`;
        break;
      case 'INVALID_LAST_FIRST_NAME':
        errorCode = 'Error: Email and password not found.';
        break;
      case 'PHONE_NUMBER_IS_ALREADY_IN_USE':
        errorCode = 'The phone number you entered has already been used.';
        break;
      case 'not-found':
        title = 'Not Found';
        message = <>The page you are looking for does not exist. Please go back and try again.</>;
        errorCode = '';
        break;
      default:
        break;
    }
  }

  if (_message) {
    if (_message.includes('combination is not found')) {
      errorCode = 'Invalid code. Please enter a valid code.';
    }
    if (_message.includes('campaign has been expired')) {
      errorCode = 'Campaign has expired. Please use a code from a non-expired campaign.';
    }
    if (_message.includes('code has expired')) {
      errorCode = 'Code has expired. Please enter a code that has not yet expired.';
    }
    if (_message.includes('combination was already redeemed')) {
      errorCode = 'Code has already been redeemed. Please enter a code that has not yet been redeemed.';
    }
    if (_message.includes('has been redeemed to capacity')) {
      errorCode =
        'We’re sorry. This promotion has reached its limit. Please notify your administrator for further instructions.';
    }
    if (_message === 'UNEXPECTED_ERROR') {
      errorCode = 'Unexpected error. Please check your input and try again.';
    } else {
      errorCode = _message;
    }
  }

  return (
    <SbEditable content={props.blok}>
      <Section
        textColor={textColor}
        withBackground={withBackground}
        fullWidth={props.blok.full_width}
        image={props.blok.background_image.filename}
        {...generateQaAttrFromBlok(props.blok)}
      >
        <header>
          <h1>{props.blok.title || title}</h1>
          <p>{props.blok.subtitle || message}</p>
          <Button blok={props.blok} onClick={() => router.back()}>
            Continue
          </Button>
        </header>

        <ErrorSection>
          <span>{errorCode}</span>
        </ErrorSection>
      </Section>
    </SbEditable>
  );
}

import styled from 'styled-components';

export const StyledCheckbox = styled.div`
  position: relative;
  flex-basis: 100%;
  margin: 0 10px;

  label {
    display: flex;
    align-content: center;
    margin-top: 10px;
    color: #888888;
    cursor: pointer;
  }

  input {
    position: absolute;
    top: 10px;
    left: 0;
    opacity: 0;
  }

  svg {
    &.error {
      color: #cc0000;
    }
  }

  span {
    margin-left: 10px;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0.012em;
    color: #3f647c;
    &.error {
      margin-top: 10px;
      margin-left: 34px;
      color: #cc0000;
      font-weight: 400;
    }
  }
`;

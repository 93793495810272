import styled from 'styled-components';

interface FAQProps {
  backgroundColor: string;
  fontWeight: string;
  dividerColor: string;
  caretIconColor: string;
}

const value = (value: string, defaultValue: string) => (value ? value : defaultValue);

export const Section = styled.section`
  background: ${({ backgroundColor }: FAQProps) => value(backgroundColor, '')};
  padding: 64px 32px;

  .faq-extend-container {
    flex-direction: column;
    margin: auto;
    max-width: 1120px;
  }

  h2 {
    text-align: center;
    padding: 0;
    margin: 0 0 30px;
    font-size: 26px;
    font-weight: ${({ fontWeight }: FAQProps) => value(fontWeight, 'auto')};
    font-weight: 700;
    letter-spacing: 0.03em;
    color: #212121;
  }

  [data-reach-accordion-item] {
    border-bottom: 1px solid ${({ dividerColor }: FAQProps) => value(dividerColor, '#A5CEE3')};
    padding-top: 20px;
    padding-bottom: 20px;

    div.faq-extend-accordion-header {
      display: flex;
      justify-content: space-between;
      align-items: start;
      font-size: 16pt;

      h3 {
        padding: 0;
        margin: 0;
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.012em;
        width: 100%;
        cursor: pointer;
        color: #212121;

        @media only screen and (min-width: 980px) {
          font-size: 24px;
        }
      }

      button {
        border: none;
        background-color: transparent;
        cursor: pointer;
        color: inherit;

        svg {
          color: ${({ caretIconColor }: FAQProps) => value(caretIconColor, 'inherit')};
        }
      }
    }
  }

  [data-reach-accordion-panel] {
    p {
      font-size: 16px;
      font-weight: 400;
      letter-spacing: 0.012em;
      color: #707070;

      @media only screen and (min-width: 980px) {
        font-size: 20px;
      }
    }
  }

  @media only screen and (min-width: 980px) {
    padding-left: 0;
    padding-right: 0;

    h2 {
      font-size: 36px;
    }
  }
`;
